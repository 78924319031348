<div class="small-box" [ngClass]="boxClass">
  <div class="inner">
    <h3>{{ totalCount | number }}</h3>

    <p>{{ description | translate }}</p>
  </div>
  <div class="small-box-footer">
    <a *ngIf="urlRedirect" [routerLink]="[urlRedirect]"
      >{{ 'Ver más' | translate }} <i class="fa fa-arrow-circle-right"></i
    ></a>
  </div>
</div>
