import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import moment from 'moment';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from '@angular/forms';
import { IBooking, IBookSku, BOOKING_MODE } from '@kanzi-apes/kanzi-models';

/**
 * @author Hugo Andres Escobar Ciceri
 * @version 2.1.0
 *
 * Alert Component to create a Sku Booking.
 */
@Component({
  selector: 'app-alert-booking',
  templateUrl: './alert-booking.component.html',
  styleUrls: ['./alert-booking.component.scss'],
})
export class AlertBookingComponent implements OnInit {
  @Input()
  get open(): boolean {
    return this.openWindow;
  }

  set open(action: boolean) {
    this.openWindow = action;
    if (this.openWindow) {
      this.isVisible = 'block';
    } else {
      this.isVisible = 'none';
      this.bookingForm.controls['amount'].setValue(0);
    }
  }

  @Input()
  get booking(): IBooking | null {
    return this._booking;
  }

  set booking(book: IBooking | null) {
    this._booking = book;
    if (this._booking) {
      this.bookingForm.controls['amount'].setValidators([
        Validators.required,
        Validators.max(this._booking.amount),
      ]);
      if (this._booking.mode === BOOKING_MODE.ADD) {
        this.bookingForm.controls['dateBook'].setValidators([
          Validators.required,
        ]);
      } else {
        this.bookingForm.controls['dateBook'].setValidators([]);
      }
    }
  }

  @Output() close = new EventEmitter<boolean>();
  @Output() addBooking = new EventEmitter<IBookSku>();
  @Output() deleteBooking = new EventEmitter<IBookSku>();

  isVisible = 'none';
  title: string;
  openWindow: boolean = false;
  _mode = BOOKING_MODE;
  _booking: IBooking | null = null;
  _initialAmount = 0;
  _minDate: Date = new Date();
  _maxDate: Date = new Date();

  bookingForm: UntypedFormGroup = this.fb.group({
    amount: [this._initialAmount],
    dateBook: [''],
  });

  /**
   *
   * @param fb {FormBuilder}
   */
  constructor(private fb: UntypedFormBuilder) {
    this.title = 'Generar Reservación';
  }

  ngOnInit() {
    this._minDate = new Date();
    this._maxDate = new Date();
    this._maxDate.setDate(this._minDate.getDate() + 30);
  }

  /**
   * Function to submit data throw the emitter.
   */
  onSubmit() {
    if (this.booking) {
      const bookSku: IBookSku = {
        reserved: true,
        sku_id: this.booking.sku.id ? this.booking.sku.id : 0,
        location_id: this.booking.location.id ? this.booking.location.id : 0,
        value: this.bookingForm.value.amount,
      };

      if (this.booking.mode === BOOKING_MODE.ADD) {
        bookSku.reserved = true;
        bookSku.cut_date = moment(this.bookingForm.value.dateBook).format(
          'YYYY-MM-DD'
        );
        this.addBooking.emit(bookSku);
      } else {
        bookSku.reserved = false;
        this.deleteBooking.emit(bookSku);
      }
    }
  }

  /**
   * Function to close the alert window.
   */
  closeWindow() {
    this.close.emit(false);
  }
}
