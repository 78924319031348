import { CustomerModel } from '../customer';
import { DataModel, DataListModel } from '../data';
import { FiltersModel } from '../filters';
import { LocationModel } from '../location';
import { MaintenanceTypeModel } from '../maintenance-configs';
import { SkuGroupModel, SkuModel } from '../products';
import { UserModel, UserCreate } from '../user';
import { EventPlannerModel } from './event-planner';
import { MaintenanceWorkOrderModel } from './work-orders';

export * from './task-models';
export * from './event-planner';
export * from './subtask-models';
export * from './work-orders';
export * from './budgets';

export interface RevisionImagesFilterModel extends FiltersModel {
  revision_id?: number;
  revision_line_id?: number;
  customer_id?: number;
}

export interface WorkPLannerFilterModel extends FiltersModel {
  initial_date?: string;
  finished_date?: string;
  initial_date_min?: string;
  finished_date_max?: string;
  sku_group_id?: number;
  sku__group_id?: number;
  type?: string;
  customer_id?: number;
  calendar_date_max?: string;
  group_name?: string;
  location_id?: number;
  calendar_date_min?: string;
  current_date?: string;
  sku__customer_group__name?: string;
}

export interface WorkPlannerGroupListModel {
  results: WorkPlannerGroupModel[];
}

export interface WorkPlannerGroupModel {
  start_date: Date;
  total_pending_amounts: number;
  total_amounts: number;
  results_list: WorkPlannerModel[];
}

export interface WorkPlannerCreateModel {
  sku_group_id: number;
  initial_date: string;
  finished_date: string;
  task_ids: string[];
  type?: string;
  properties?: PropertiesWorkPlannerCreateModel;
}

export interface PropertiesWorkPlannerCreateModel {
  periodicity_month: string;
}

export interface EventTaskModel extends DataModel {
  external_id?: string;
  name: string;
  type: MaintenanceTypeModel;
  type_name?: string;
  id?: number;
  active?: boolean;
  select?: any;
  event_planner?: EventPlannerModel;
}

export interface EventTaskListModel extends DataListModel {
  results: EventTaskModel[];
}

export interface WorkPlannerListModel extends DataListModel {
  results: WorkPlannerModel[];
}
export interface WorkPlannerModel extends DataModel {
  external_id?: string;
  status: string;
  description?: string;
  initial_date: Date;
  finished_date: Date;
  destination: LocationModel;
  expired: boolean;
  sku_group: SkuGroupModel;
  task: EventTaskModel[];
  total_amount?: number;
  amount_pending?: number;
  type?: string;
}
export interface WorkPlanerSelectModel {
  id: string;
  date: Date;
}

export interface RevisionModel extends DataModel {
  external_id?: string;
  type: string;
  status: string;
  description?: string;
  completed_by: UserModel;
  completed_name?: string;
  source: LocationModel;
  source_name?: string;
  event_planner: WorkPlannerModel;
  product_group_name?: string;
  created_by: UserModel;
  finish_date?: string;
  created_name?: string;
  duration_seconds?: number;
  duration_hours?: string;
  source_id?: number;
  start_date?: string;
  properties?: any;
  sku?: SkuModel;
}

export interface RevisionListModel extends DataListModel {
  results: RevisionModel[];
}

export interface RevisionLineModel extends DataModel {
  revision: RevisionModel;
  sku: SkuModel;
  description?: string;
  status: string;
  sku_display_name?: string;
  sku_id?: number | string;
  sku_reference?: string;
  sku_external_id?: string;
  product_group_name?: string;
  revision_type?: string;
  detail?: any;
  detail_text?: string;
  source_name?: string;
  created_name?: string;
  duration_seconds?: number;
  duration_hours?: string;
  cost?: number | string;
  external_id?: string;
  assign_lines?: RevisionAssignedLineModel[];
  start_date?: string;
  finish_date?: string;
  event_task?: any;
  properties?: any;
}

export interface RevisionAssignedLineModel {
  id?: number;
  assigned_to?: UserModel;
  finish_date?: string;
  start_date?: string;
  is_principal?: boolean;
  start_date_timestamp?: number;
  finish_date_timestamp?: number;
}

export interface RevisionLineListModel extends DataListModel {
  results: RevisionLineModel[];
}

export interface ImageDetailModel extends DataModel {
  name: string;
  revision_detail: RevisionModel;
  photo: string;
}

export interface RevisionFilterModel extends FiltersModel {
  destination_id?: number;
  event_planner_id?: number;
  customer_id?: number | null;
  type?: string;
  revision__type?: string;
  revision_status__exclude?:string;
  calendar_date_max?:string;
  calendar_date_min?:string;
  type__external_id?:string;
  type__external_id__exclude?:string;
  statuses?:string[];
  requester_name?:string;
  entrance_name?:string;
}

export interface RevisionViewCalendarModel {
    status?:string;
    status_display_name?:string;
    amount?:number;
    start_date?:Date | string;
    revisions:MaintenanceWorkOrderModel[];
}

export interface RevisionLineFilterModel extends FiltersModel {
  destination_id?: number;
  revision_id?: number | string;
  customer_id?: number | null;
  sku_id?: number;
  sku__group_id?: number;
  revision__source_id?: number;
  revision__type?: string;
  location_id?: number;
  pending?: boolean;
  group_name?: string;
  type?: string;
  current_date?: string;
  sku__customer_group__name?: string;
}

export interface RevisionImageModel extends DataModel {
  name: string;
  photo: string;
  type?: any;
  revision: RevisionModel;
}

export interface RevisionImageTypeModel extends DataModel {
  name: string;
  external_id?: string;
  active?: boolean;
  customer_id?: number;
}

export interface RevisionImageListModel extends DataListModel {
  results: RevisionImageModel[];
}

export interface RevisionLineTraceFilterModel extends FiltersModel {
  checklist_sheet_line_id?: number;
  revision_line_id?: number;
}

export interface ChecklistSheetLineFilterModel extends FiltersModel {
  checklist_sheet_id?: number;
  checklist_sheet__sku_group_id?: number;
  checklist_sheet__locations?: string;
  checklist_sheet__event_task_id?: number;
  checklist_sheet__type?: string;
  customer_id?: number;
}

export interface RevisionLineTraceModel extends DataModel {
  checklist_sheet_line?: ChecklistSheetLineModel;
  revision_line?: RevisionLineModel;
  service_state?: RevisionServiceStateModel;
  comments?: string;
  observations?: string;
  check_results?: RevisionLineCheckRusultsModel[];
  timestamp?: number;
}

export interface RevisionLineCheckRusultsModel {
  sub_step: string;
  results: { name: string; result: string }[];
}

export interface RevisionLineTraceListModel extends DataListModel {
  results: RevisionLineTraceModel[];
}

export interface ChecklistSheetModel extends DataModel {
  sku_group?: SkuGroupModel;
  event_task?: EventTaskModel;
  customer_id?: number;
  service_state_id?: number;
}

export interface ChecklistSheetLineModel extends DataModel {
  step_number?: number;
  checklist_sheet: ChecklistSheetModel;
  description?: string;
  type: string;
  options?: any[];
  image?: boolean;
  params?: ParamsCheckListModel;
  image_type?: RevisionImageTypeModel;
  active?: boolean;
  customer?: CustomerModel;
  timestamp?: number;
  service_state?: RevisionServiceStateModel;
}

export interface ParamsCheckListModel {
  options: OptionCheckListModel[];
  sub_steps: string[];
}

export interface OptionCheckListModel {
  name: string;
  choices: string[];
}

export interface ChecklistSheetLineListModel extends DataListModel {
  results: ChecklistSheetLineModel[];
}

export interface RevisionServiceStateModel extends DataModel {
  external_id?: string;
  name: string;
  active?: boolean;
  customer?: CustomerModel;
}

export interface RevisionInventorySummaryModel extends DataModel {
  sku: SkuModel;
  current_location: LocationModel;
  period_min_date: string;
  period_max_date: string;
  pending: boolean;
}

export interface RevisionInventorySummaryListModel extends DataListModel {
  results: RevisionInventorySummaryModel[];
}

export interface RevisionRefillModel extends DataModel {
  sku: SkuModel;
  revision_line: RevisionLineModel;
  unit_price?: number;
  amount: number;
  description?: string;
}

export interface RevisionRefillListModel extends DataListModel {
  results: RevisionRefillModel[];
}

export interface RevisionRefillCreateListModel {
  refillsCreate: SqarepartModel[];
}

export interface SqarepartModel {
  id?: number;
  sku_id: number;
  revision_line_id: number;
  unit_price?: number;
  amount: number;
  description?: string;
}

export interface RevisionLineAssingsModel extends DataModel {
  revision_line: RevisionLineModel;
  assigned_to: UserModel;
  is_principal: boolean;
  start_date?: string;
  finish_date?: string;
  start_date_timestamp?: string;
  finish_date_timestamp?: string;
}

export interface AssingsToListModel extends DataListModel {
  results: RevisionLineAssingsModel[];
}

export interface RevisionAssingsCreateModel {
  id?: number;
  revision_line_id?: number;
  assigned_to_id: number;
  is_principal?: boolean;
  start_date?: string;
  finish_date?: string;
}

export interface RevisionLineCreateModel {
  id?: number;
  sku_id?: number;
  revision_id?: number;
  event_task_id?: number;
  status?: string;
  description?: string;
  duration_seconds?: number;
  start_date?: string;
  finish_date?: string;
  cost?: number;
  properties?: any;
  assigns?: any[];
  allData?: revisonLinePreviuModel;
}

export interface revisonLinePreviuModel {
  assings_data: assingsListModel;
  sku_data: SkuModel;
}

export interface assingsListModel {
  auxAssigs: UserModel[];
  principalAssings: UserModel[];
}

export interface RevisionCreateModel {
  id?: number;
  completed_by_id?: number;
  source_id: number;
  event_planner_id?: number;
  customer_id: number;
  lines?: RevisionLineCreateModel;
  external_id?: string;
  type: string;
  status?: string;
  description?: string;
  start_date?: string;
  start_timestamp?: number;
  duration_seconds?: number;
  finish_date?: string;
  finish_timestamp?: number;
}

export interface RevisionLineRefillModel extends FiltersModel {
  sku_id?: number;
  revision_line_id?: number;
  created_by_id?: number;
  modified_by_id?: number;
}

export interface RevisionLinePartCreateModel {
  sku: SkuModel;
  sku_external_id?: string;
  sku_id?: number;
  sku_display_name?: string;
  unit_price: number;
  amount?: number;
  quantity?:number;
  measure_of_unit?: string;
  unit_measure?: string;
}

export interface RevisionLinePartModel extends DataModel {
  sku: SkuModel;
  revision_line: RevisionLineModel;
  unit_price: number;
  amount?: number;
  quantity?:number;
  measure_of_unit?: string;
  unit_measure?: string;
  description: string;
}

export interface RevisionRefillEditModel {
  id: number;
  sku_id: number;
  revision_line_id: number;
  unit_price: number;
  amount: number;
}

export interface RevisionLinePartListModel extends DataListModel {
  results: (RevisionLinePartModel | RevisionLinePartCreateModel)[];
}

export interface RevisionLinePartsFilterModel extends FiltersModel {
  sku_id?: number;
  revision_line_id?: number;
}

export interface RevisionLineSummaryFilterModel extends FiltersModel {
  type__name?: string;
  group_id?: number;
}

export interface AssingsToLineFilterModel extends FiltersModel {
  revision_line_id?: number;
}

export interface AmountByDateListModel extends DataListModel {
  results: AmountByDateModel[];
  amount_closed?: number;
  total_amount?: number;
  amount_completed?: number;
  amount_issued?: number;
}

export interface AmountByDateModel {
  date: string;
  total_amount: number;
  amount_issued: number;
  amount_incomplete: number;
  amount_completed: number;
  amount_closed: number;
}

export interface InventorySummaryListModel extends DataListModel {
  results: InventorySummaryModel[];
}

export interface InventorySummaryModel {
  sku: SkuModel;
  last_activity: string;
  period_min_date: string;
  period_max_date: string;
  pending: boolean;
  current_location: LocationModel;
  last_revision_status: string;
  revision_type: string;
  location_display_name?: string;
}

export interface RevisionCreateGroupModel {
  customer_id: number;
  lines?: RevisionLineCreateModel[];
  type: string;
}

export interface ResponseRevisionCreateModel {
  results: RevisionGroupLines[];
}

export interface RevisionGroupLines {
  id: number;
  revision: Revision;
  sku: SkuModel;
  status: string;
  description: null;
  event_task: null;
  detail: Detail;
  cost: number;
  start_date: string;
  finish_date: null;
  duration_seconds: null;
  start_date_timestamp: number;
  finish_date_timestamp: null;
}

export interface Detail {}

export interface Revision {
  id: number;
  type: string;
  status: string;
  completed_by_id: null;
  source_id: number;
  event_planner_id: number;
}

export interface AuditModelEvent extends DataModel {
  cut_date: string;
  location: any;
  sub_location?: any;
  prefix: string;
  external_id: string;
  status: string;
  adjusted?: boolean;
  audit_items_file?: string;
  expected_items_file?: string;
  audit_summary_file?: string;
  found_summary_file?: string;
  missing_summary_file?: string;
  extra_summary_file?: string;
  adjust_items_file?: string;
  audit_assets_file?: string;
  expected_assets_file?: string;
  expected_summary_file?: string;
  adjust_assets_file?: string;
  extra_items_file?: string;
  found_items_file?: string;
  missing_items_file?: string;
  summary_results_file: string;
  total_expected_items?: number;
  total_audit_items?: number;
  total_found_items?: number;
  total_missing_items?: number;
  total_extra_items?: number;
  total_expected_assets?: number;
  total_audit_assets?: number;
  total_found_assets?: number;
  total_missing_assets?: number;
  total_extra_assets?: number;
  cut_date_timestamp: number;
  finish_date?: string;
  finish_date_timestamp?: number;
  allows_adjustment?: boolean;
  is_partial_count?: boolean;
  properties?: object;
}
export interface CalendarEventModel {
  id: number;
  title: string;
  start: string;
  end: string;
  color: string;
  extendedProps: AuditModelEvent;
}
