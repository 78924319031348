export const HOUR_SERVICES = [
  'SERVICIO MANO DE OBRA',
  'MANO DE OBRA RC',
  'SERVICIO MANO DE OBRA - EXTRAS Y DOMINICALES',
  'SERVICIO MANO DE OBRA REPARACIONES ESTRUCTURALES',
];

export const TYPE_SERVICES = [
  'SERVICIO TECNICO INHOUSE',
  'SERVICIO MANO DE OBRA',
  'MANO DE OBRA RC',
  'SERVICIO MANO DE OBRA - EXTRAS Y DOMINICALES',
  'SERVICIO MANO DE OBRA REPARACIONES ESTRUCTURALES',
];
