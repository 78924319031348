import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MenuItem } from '@kanzi-apes/kanzi-prime-ui';

/**
 * @author Hugo Andrés Escobar Ciceri
 * @version 4.2.0
 *
 * Component to display the App Main Menu.
 */
@Component({
  selector: 'kanzi-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit, OnChanges {
  @Input() itemsMenu: MenuItem[];

  constructor() {
    this.itemsMenu = [];
  }
  ngOnInit() {}

  ngOnChanges() {}
}
