export class Queue {
  _oldestIndex: number = 1;
  _newestIndex: number = 1;
  _storage: any = {};
  constructor() {}

  size() {
    return this._newestIndex - this._oldestIndex;
  }

  enqueue(data: any) {
    this._storage[this._newestIndex] = data;
    this._newestIndex++;
  }

  dequeue() {
    const oldestIndex = this._oldestIndex,
      newestIndex = this._newestIndex;
    let deletedData;

    if (oldestIndex !== newestIndex) {
      deletedData = this._storage[oldestIndex];
      delete this._storage[oldestIndex];
      this._oldestIndex++;

      return deletedData;
    }
  }
}
