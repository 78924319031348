<p-table
  #dt
  *ngIf="configs"
  [columns]="colSource"
  [lazy]="configs.lazy ? configs.lazy : false"
  [value]="dataListTable"
  [dataKey]="configs.key ? configs.key : ''"
  [paginator]="configs.paginator ? configs.paginator : false"
  [rows]="configs.rows ? configs.rows : 0"
  [sortMode]="configs.sortMode ? configs.sortMode : ''"
  [responsive]="true"
  [totalRecords]="dataSource ? (dataSource.count ? dataSource.count : 0) : 0"
  [lazyLoadOnInit]="false"
  [loading]="pending"
  [resizableColumns]="
    configs ? (configs.resize ? configs.resize : false) : false
  "
  columnResizeMode="expand"
  [exportFilename]="configs ? (configs.fileName ? configs.fileName : '') : ''"
  [(selection)]="itemsSelected"
  [styleClass]="'kanzi-table'"
  (onLazyLoad)="loadLazy($event)"
  (onRowExpand)="onRowExpand($event)"
  (onFilter)="onFilter($event)"
  (onRowSelect)="onRowSelected($event)"
>
  <ng-template pTemplate="caption" *ngIf="!configs?.isHidenCaption">
    <div class="flex flex-column md:flex-row md:justify-content-between">
      <span class="mb-2"
        >{{ 'Total Registros' | translate }}:
        {{
          (dataSource?.count ? dataSource?.count : dataListTable.length)
            | number
        }}</span
      >
      <span class="mb-2"
        >{{ 'Total Filtrados' | translate }}:
        {{ filteredTotals | number }}</span
      >
      <span *ngIf="configs?.search" class="p-input-icon-left mb-2">
        <i class="pi pi-search"></i>
        <input
          type="text"
          class="form-control"
          pInputText
          placeholder="{{ 'Buscar' | translate }}..."
          (input)="dt.filterGlobal($any($event.target).value, 'contains')"
        />
      </span>
      <p-calendar
        *ngIf="configs && configs.filters && configs.filters.date"
        #rangeDate
        selectionMode="range"
        dateFormat="dd/mm/yy"
        dataType="date"
        placeholder="{{ 'Rango de Fechas' | translate }}"
        [readonlyInput]="true"
        [showButtonBar]="true"
        [showIcon]="true"
        [touchUI]="false"
        [(ngModel)]="rangeDateCalendar"
        (onClearClick)="clearFilters(true, dt)"
        (onSelect)="onFilterDateTable(rangeDate.value)"
      ></p-calendar>
      <div class="p-col" *ngIf="!configs?.downloadFile">
        <button
          type="button"
          pButton
          alt="Cargar datos"
          pTooltip="Cargar datos"
          tooltipPosition="bottom"
          icon="fa fa-database"
          iconPos="left"
          (click)="loadAllDataset()"
        ></button>
      </div>
      <div class="p-col mt-4 sm:mt-0" *ngIf="configs?.downloadFile && !configs?.simple">

        <!-- Menu opcions -->
        <button
          #btn
          alt="Opciones"
          pTooltip="Opciones"
          tooltipPosition="bottom"
          type="button"
          pButton
          icon="pi pi-bars"
          class="mr-2 p-button-help p-button-outlined p-button-sm"
          (click)="menu.toggle($event)"
        ></button>
        <p-tieredMenu #menu [model]="items" [popup]="true" appendTo="body"></p-tieredMenu>
        <!-- End Menu opcions -->

        <!-- Filter opcion -->
        <button
          *ngIf="configs?.filters"
          type="button"
          pButton
          alt="Filtrar"
          pTooltip="Filtrar"
          tooltipPosition="bottom"
          icon="pi pi-filter"
          class="mr-2 p-button-info p-button-outlined p-button-sm"
          (click)="showFilters()"
        ></button>
        <!-- Filter opcions -->

        <button
          *ngIf="configs?.filters"
          type="button"
          alt="Refrescar"
          pTooltip="Refrescar"
          tooltipPosition="bottom"
          pButton
          icon="pi pi-refresh"
          class="p-button-outlined p-button-sm"
          iconPos="left"
          (click)="clearFilters(true, dt)"
        ></button>
      </div>
      <div
        class="mt-4"
        *ngIf="
          configs?.downloadFile &&
          !configs?.simple &&
          configs?.filters &&
          configs?.clear_filters
        "
      >
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
          <div
            class="lg:col-12 md:col-12 sm:col-12"
            style="display: flex; flex-direction: row-reverse"
          >
            <span>
              <a class="text-primary ml-2" (click)="clearAllFilters(dt)">
                <i class="pi pi-trash"></i> Limpiar Filtros
              </a>
            </span>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="!configs?.options" class="mt-4">
        <span *ngIf="configs?.downloadFile">
          <a class="text-primary" (click)="showFilters()">
            <i class="pi pi-filter"></i>
          </a>
        </span>
      </div> -->
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th style="width: 3em" *ngIf="configs?.multiSelect">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th style="width: 3em" *ngIf="configs?.onlySelect">
      </th>
      <th style="width: 3em" *ngIf="configs?.expandRow"></th>
      <th
        *ngFor="let col of columns"
        [pSortableColumn]="col.field"
        [pSortableColumnDisabled]="!col.sortable"
        pResizableColumn
        [ngStyle]="{ width: col.colwidth ? col.width : null }"
      >
        {{ col.header | translate }}
        <p-sortIcon
          [hidden]="!col.sortable"
          [field]="col.field"
          ariaLabel="Activate to sort"
          ariaLabelDesc="Activate to sort in descending order"
          ariaLabelAsc="Activate to sort in ascending order"
        ></p-sortIcon>
      </th>
      <th *ngIf="configs?.options">
        Acciones
      </th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-rowData
    let-columns="columns"
    let-expanded="expanded"
  >
    <tr class="p-selectable-row">
      <td *ngIf="configs?.multiSelect" style="text-overflow: initial">
        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
      </td>
      <td *ngIf="configs?.onlySelect" style="text-overflow: initial">
        <p-tableRadioButton [value]="rowData"></p-tableRadioButton>
      </td>
      <td *ngIf="configs?.expandRow">
        <a href="#" [pRowToggler]="rowData">
          <i
            [ngClass]="expanded ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"
          ></i>
        </a>
      </td>

      <td
        *ngFor="let col of columns"
        [ngSwitch]="col.pipe"
        class="p-resizable-column"
        [ngStyle]="{ width: col.colWidht ? col.widht : null }"
        [style]="{ overflow: 'hidden', 'text-overflow': 'ellipsis' }"
      >
        <span class="p-column-title">{{ col.header | translate }} </span>
        <div
          *ngIf="col.subfield; then nested_object_content; else normal_content"
        ></div>
        <ng-template #nested_object_content>
          <div *ngIf="rowData[col.field]">
            {{ rowData[col.field][col.subfield] }}
          </div>
        </ng-template>
        <ng-template #normal_content>
          <div *ngSwitchCase="true">
            <span [appStatusStyle]="rowData[col.field]">{{
              rowData[col.field] | kanziAppPipes | booleans
            }}</span>
          </div>
          <div *ngSwitchDefault>
            <span [appStatusStyle]="rowData[col.field]">{{
              rowData[col.field]
            }}</span>
          </div>
        </ng-template>
      </td>
      <td *ngIf="configs && configs.options">
        <div class="flex">
          <button
            *ngIf="configs && configs.options && configs.options.view"
            pButton
            pRipple
            icon="pi pi-eye"
            class="p-button-rounded p-button-success mr-2 p-button-sm"
            (click)="onRowSelect(rowData)"
          ></button>
          <button
            *ngIf="configs && configs.options && configs.options.edit"
            pButton
            pRipple
            icon="pi pi-pencil"
            class="p-button-rounded p-button-warning mr-2 p-button-sm"
            (click)="onRowEdit(rowData)"
          ></button>
          <button
            *ngIf="configs && configs.options && configs.options.delete"
            pButton
            pRipple
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger p-button-sm"
            (click)="onRowDelete(rowData)"
          ></button>
          <span *ngIf="configs && configs.options && configs.options.external">
            <a class="text-red">
              <i class="fa fa-link"></i>
            </a>
          </span>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="configs?.options ? columns.length+1:columns.length">
        {{ emptyMsg | translate }}
      </td>
    </tr>
  </ng-template>
  <ng-template
    *ngIf="configs?.expandRow"
    pTemplate="rowexpansion"
    let-rowData
    let-columns="columns"
  >
    <tr>
      <td
        [attr.colspan]="
          configs?.options ? columns.length + 3 : columns.length + 1
        "
      >
        <div class="row">
          <div class="col-lg-12">
            <kanzi-dynamic-component
              [dynamicComponent]="dynamicComponent"
              [data]="rowData"
            ></kanzi-dynamic-component>
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-sidebar position="right" [baseZIndex]="10000" [autoZIndex]="true" [(visible)]="showFilter" [style]="{width:'25em'}">
  <h3>Filtros</h3>
  <form [formGroup]="formFilters">
    <div class="fluid" *ngFor="let col of colSource">
        <label for="multiselect mb-0">{{ col.header  | titlecase }}</label>
        <p-multiSelect
          inputId="multiselect"
          [options]="col.filterOptions | kanziNameOrder"
          [defaultLabel]="col.labelFilter"
          [style]="{
            width: '100%',
            'margin-bottom': '30px',
            'margin-top': '10px'
          }"
          [formControlName]="col.field"
          [panelStyle]="{ Width: '100%' }"
          (onClick)="selectFilter(null, col, null)"
          appendTo="body"
        >
          <ng-template let-value pTemplate="selectedItems">
            <div *ngFor="let val of value">
              <span>{{ val | kanziAppPipes }}</span>
            </div>
            <span *ngIf="!value || value.length === 0">{{
              col.labelFilter
            }}</span>
          </ng-template>
          <ng-template let-types pTemplate="item">
            <div
              style="
                font-size: 14px;
                margin-top: 4px;
                text-align: left;
                display: inline-block;
              "
            >
              {{ types.value | kanziAppPipes }}
            </div>
          </ng-template>
        </p-multiSelect>
    </div>
    <hr/>
    <p-footer>
      <div class="flex">
        <button
          (click)="showFilters()"
          pButton
          type="button"
          icon="pi pi-filter"
          label="Aplicar"
          class="p-button-sm p-button-text"
        ></button>
        <button
          label="Limpiar todo"
          class="ml-2 p-button-sm p-button-text"
          type="button"
          pButton
          icon="pi pi-refresh"
          iconPos="left"
          (click)="clearFilters(true, dt)"
        ></button>
      </div>
    </p-footer>
  </form>
</p-sidebar>
