import { ContactModel } from '../contacts';
import { FiltersModel } from '../filters';
import { DataModel, DataListModel } from '../data';
import { PackageModel } from '../package';
import { LocationModel } from '../location';
import { UserModel } from '../user';
import { CustomerModel } from '../customer/customer.models';
import { CreatedModel } from '../data';

export interface SkuPackModel {
  packing: PackageModel;
  amount: number;
}

export interface ProductCreateModel {
  id?: number;
  name: string;
  display_name: string;
  external_id?: string;
  type_id: number;
  group_id: number;
  customer_id?: number;
  properties?: any;
  product_code?: string;
  properties_transform?: any;
  photo?: any;
}
export interface SkuModel extends DataModel {
  id?: number;
  name: string;
  display_name: string;
  company_prefix?: string;
  reference?: string;
  sku_code?: string | number;
  product_code?: string;
  customer?: CustomerModel;
  ean?: number;
  upc?: string;
  mpn?: string;
  isbn?: string;
  photo_url?: string;
  photo?: string;
  properties: any;
  type: SkuTypeModel;
  group: SkuGroupModel;
  group_name?: string;
  external_id: any;
  filter: number;
  gtin_data_structure?: number;
  gtin_code?: string;
  condition?: any;
  state?: any;
  location_name?: string;
  type__name?: string;
  type__id?: number;
  type_id?: number;
  customer_id?: number;
  group_id?: number;
  customer_groups?: any;
  customer_group_id?: number;
  properties_transform?: any;
  category_name?: string;
  unit_price?: string;
  sku_display_name?: string;
  sku_group_name?: string;
  sku_external_id?: string;
  sku_periodicity_month?: string;
  location_display_name?: string;
  status?: string;
  search_data?: string;
  amount?: number;
  selected?: boolean;
}

export interface AssetModel extends SkuModel {
  current_location_id?: number;
  current_location?: LocationModel;
  current_location_name?: string;
  trailer_state?: any;
  trailer_state_name?: string;
  modified_days?: number;
  trailer_type?: {
    id: number;
    external_id: string;
    description: string;
    action: string;
  };
  trailer_type_name?: string;
  trailer_type_id?: number;
  trailer_state_id?: number;
  is_active?: boolean;
  group_id?: number;
}

export interface SkuGroupModel {
  id: number;
  created_by: CreatedModel;
  modified_by: CreatedModel;
  parent: any[];
  customer: CustomerModel;
  type: {
    id: number;
    external_id: string;
    name: string;
    coding_type: string;
  };
  created: string;
  modified: string;
  external_id: string;
  name: string;
  photo: null;
  serial_count: number;
  filter: number;
  properties: any;
  created_timestamp: number;
  modified_timestamp: number;
}

export interface SkuGroupListModel {
  count: number;
  next: string;
  previous: string;
  results: SkuGroupModel[];
}

export interface SkuTypeModel {
  id?: number;
  code?: string;
  description?: string;
  name?: string;
  photo?: string;
  external_id?: string;
}

export interface SkuTypeListModel {
  count: number;
  next: string;
  previous: string;
  results: SkuTypeModel[];
}

export interface SkuListModel extends DataListModel {
  results: SkuModel[];
}

export interface AssetListModel extends DataListModel {
  results: AssetModel[];
}

export interface ProductFilterModel extends FiltersModel {
  type_id?: number;
  group_id?: number;
  type__name?: string;
  show_actives?: boolean;
  customer_group_id?: number;
  class_product?: 'INSUMOS VARIABLES' | 'INSUMOS NO VARIABLES';
  key?: string;
}

export interface valuePropertiesListModel extends DataListModel {
  results: valuePropertiesModel[];
}

export interface valuePropertiesModel {
  key: string;
  value: string;
}

export interface AssetFilterModel extends FiltersModel {
  type_id?: number;
  trailer_state__description?: string;
}

export interface PropertiesModel {
  referencia: string;
  sub_grupo: string;
  color: string;
  fabricante: string;
  sexo: string;
  tipo_producto: string;
  talla: string;
  colleccion: string;
  descripcion: string;
  codigo_color: string;
  grupo: string;
}

export interface AssetActivityListModel extends DataListModel {
  results: AssetActivityModel[];
}

export interface AssetActivityModel extends DataModel {
  id: number;
  trailer_state?: any;
  contact?: ContactModel;
  location: LocationModel;
  reporter: UserModel;
  action: string;
  description: string;
  reporter_source: string;
  asset: string;
  modified_days?: number;
}

export interface InactiveItemsModel {
  items: string[];
  action: string;
  description?: string;
  customer_id?: number;
  source_id?: number;
}

export interface KeyPropertiesFilterModel {
  page?: string;
  page_size?: string;
  key?: string;
  group_id: number;
  search?: string;
}

export interface KeyPropertiesListModel extends DataListModel {
  results: { key: string }[];
}

export interface ValueListModel extends DataListModel {
  results: { key: string; value: string }[];
}

export interface ValuePropertiesListModel {
  id?: number;
  key: string;
  opcionsValue: { value: string }[];
}

export interface LoadMasterFileModel {
  master_file_sku: string;
  sku_type_id: number;
  sku_group_id: number;
  customer_id: number;
}

export interface ConsumptionListModel extends DataListModel {
  results: ConsumptionModel[];
}

export interface ConsumptionModel {
  id: number;
  location: LocationModel;
  customer: CustomerModel;
  sku: SkuModel;
  created: string;
  modified: string;
  external_id: null;
  status: string;
  total_present: number;
  total_available: number;
  total_transaction_amount: number;
  total_dispatch: number;
  created_by: number;
  modified_by: number;
}
