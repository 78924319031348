import { FiltersModel } from '../filters';
import { DataModel, DataListModel } from '../data';
import { CountryModel } from './country.model';
export interface StateCountryModel extends DataModel {
  external_id?: string;
  name: string;
  country?: CountryModel;
}

export interface StateCountryListModel extends DataListModel {
  results: StateCountryModel[];
}

export interface StateCountryFilterModel extends FiltersModel {
  name?: string;
  country_id?: number;
}
