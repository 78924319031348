import { DataListModel, DataModel } from '../data';
import { FiltersModel } from '../filters';
export interface CountryModel extends DataModel {
  external_id?: string;
  name: string;
  iso2: string;
  iso3?: string;
  phonecode?: string;
  capital: string;
  currency?: string;
}

export interface CountryListModel extends DataListModel {
  results: CountryModel[];
}

export interface CountryFilterModel extends FiltersModel {
  name?: string;
  iso2?: string;
  iso3?: string;
  phonecode?: string;
  capital?: string;
  currency?: string;
}
