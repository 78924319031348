export type MenuMode =
  | 'static'
  | 'overlay'
  | 'horizontal'
  | 'slim'
  | 'compact'
  | 'reveal'
  | 'drawer';

export type ColorScheme = 'light' | 'dark' | 'dim';
