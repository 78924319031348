import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromAuth from '../../reducers';
import { UserModel } from '@kanzi-apes/kanzi-models';

@Component({
  selector: 'app-profile-user',
  templateUrl: './profile-user.component.html',
  styleUrls: ['./profile-user.component.css'],
})
export class ProfileUserComponent implements OnInit {
  user$ = this.storeAuth.pipe(select(fromAuth.getUser));
  user: UserModel | null = null;

  constructor(private storeAuth: Store<fromAuth.State>) {}

  ngOnInit() {
    this.user$.subscribe((user) => {
      this.user = user;
    });
  }

  profileUser() {}
}
