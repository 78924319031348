import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'kanzi-totals-comparative-widget',
  templateUrl: './kanzi-totals-comparative-widget.component.html',
  styleUrls: ['./kanzi-totals-comparative-widget.component.scss'],
})
export class KanziTotalsComparativeWidgetComponent implements OnInit {
  @Input() title: string;
  @Input() total: number;
  @Input() totalProgress: number;
  constructor() {
    this.title = '';
    this.total = 0;
    this.totalProgress = 0;
  }

  ngOnInit(): void {}
}
