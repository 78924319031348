import { inject, Injectable } from '@angular/core';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';
import { KanziRestClientService } from '@kanzi-apes/kanzi-rest-api';
import { map, Observable } from 'rxjs';
import {
  LocationsWMSFilterModel,
  WarehouseListModel,
} from '@kanzi-apes/kanzi-models';

@Injectable({
  providedIn: 'root',
})
export class KanziLocationsService {
  private httpClient = inject(KanziRestClientService);
  private envService = inject(EnvironmentService);
  private locationsWMSUrl = `${this.envService.wmsEnvironment?.apiRestModules.kongWMSAPI?.locations.apiURL}`;
  private locationsWMSPath = `${this.envService.wmsEnvironment?.apiRestModules.kongWMSAPI?.locations.apiPath}`;

  public getWarehouseList(
    filters: LocationsWMSFilterModel
  ): Observable<WarehouseListModel> {
    const url = `${this.locationsWMSUrl}${this.locationsWMSPath}`;
    return this.httpClient.get(
      url,
      filters,
      'Error al listar las bodegas',
      {},
      { tokenName: 'KanziWMSUserToken', tokenPrefix: 'COGNITO' }
    );
  }
}
