import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './effects/auth.effects';
import { reducers } from './reducers';
import { TranslateModule } from '@ngx-translate/core';
import { KanziAlertsModule } from '@kanzi-apes/kanzi-alerts';
import { LoginComponent, ProfileUserComponent } from './pages';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { KanziPrimeUiModule } from '@kanzi-apes/kanzi-prime-ui';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    KanziAlertsModule,
    RouterModule.forChild([{ path: 'login', component: LoginComponent }]),
    StoreModule.forFeature('auth', reducers),
    EffectsModule.forFeature([AuthEffects]),
    KanziPrimeUiModule,
  ],
  declarations: [LoginComponent, ProfileUserComponent, LoginFormComponent],
})
export class KanziAuthModule {}
