interface EpcConfigsModel {
  header: number;
  filter: number;
  partition: number;
  companyPrefix: number;
}

export function epcColtanGenerator(
  assetID: string,
  assetType: number,
  configs: EpcConfigsModel
): string | null {
  const binHeader = completeString(configs.header.toString(2), '0', 8);
  const binFilter = completeString(configs.filter.toString(2), '0', 3);
  const binPartition = completeString(configs.partition.toString(2), '0', 3);
  const binCompanyPrefix = completeString(
    configs.companyPrefix.toString(2),
    '0',
    24
  );
  const binAssetType = completeString(assetType.toString(2), '0', 20);

  const assetChar = assetID.slice(0, 1);
  const assetNumber = assetID.slice(1);
  const assetCharCode = assetChar.charCodeAt(0);
  const assetCharCodeBin = completeString(assetCharCode.toString(2), '0', 18);
  const assetNumberCharts = assetNumber.split('');
  const assetNumberBin = assetNumberCharts
    .map((value) => {
      return completeString(parseInt(value).toString(2), '0', 4);
    })
    .join('');

  const serial = assetCharCodeBin.concat(assetNumberBin);
  const epcBin = binHeader.concat(
    binFilter,
    binPartition,
    binCompanyPrefix,
    binAssetType,
    serial
  );

  const epcFramesAmount = epcBin.length / 8;
  const framesBin = [];
  for (let i = 0, j = 0; i < epcFramesAmount; i++, j = j + 8) {
    framesBin.push(epcBin.slice(j, j + 8));
  }

  let epc = null;
  if (framesBin.length > 0) {
    epc = framesBin
      .map((value) => {
        return completeString(parseInt(value, 2).toString(16), '0', 2);
      })
      .join('')
      .toUpperCase();
  }

  return epc;
}

function completeString(text = '', comodin = '', maxLength = 0) {
  if (text.length < maxLength) {
    const newString = comodin.repeat(maxLength - text.length);
    return newString.concat(text);
  } else {
    return text;
  }
}
