import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'kanzi-detail-data',
  templateUrl: './kanzi-detail-data.component.html',
  styleUrls: ['./kanzi-detail-data.component.scss'],
})
export class KanziDetailDataComponent implements OnInit, OnChanges {
  @Input() attributes: any[];
  @Input() data: any;

  constructor() {
    this.attributes = [];
  }

  ngOnInit() {}

  ngOnChanges() {}
}
