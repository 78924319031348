import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class customValidations {
  static valdationMatch(
    nameControl1: string,
    nameControl2: string
  ): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const controlValue = formGroup.get(nameControl1)?.value;
      const controlMatchValue = formGroup.get(nameControl2)?.value;
      if (controlValue?.id == controlMatchValue?.id) {
        formGroup.get(nameControl2)?.setErrors({ isMatch: true });
        return {
          isMatch: true,
        };
      }

      formGroup.get(nameControl2)?.setErrors(null);

      return null;
    };
  }
}
