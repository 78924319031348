<div class="box box-primary">
  <div class="info-box">
    <div class="box-header with-border">
      <h3 class="box-title">{{ title | translate }}</h3>
      <!-- /.box-tools -->
    </div>
    <div
      class="p-grid p-align-center vertical-container info-box-content"
      style="margin-left: 0; text-align: center;"
    >
      <h2 style="font-size: 100px;" class="p-col">
        {{ totalProgress | number }} /
        {{ total | number }}
      </h2>
    </div>
  </div>
</div>
