import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ElementRef,
  inject,
  input,
} from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import {
  UserCognitoModel,
  UserConfigsWMSModel,
  UserModel,
} from '@kanzi-apes/kanzi-models';
import { LayoutService } from '../../services/layout/layout.service';

@Component({
  selector: 'kanzi-ui-menu-user-profile',
  templateUrl: './kanzi-ui-menu-user-profile.component.html',
  styleUrls: ['./kanzi-ui-menu-user-profile.component.scss'],
  animations: [
    trigger('menu', [
      transition('void => inline', [
        style({ height: 0 }),
        animate(
          '400ms cubic-bezier(0.86, 0, 0.07, 1)',
          style({ opacity: 1, height: '*' })
        ),
      ]),
      transition('inline => void', [
        animate(
          '400ms cubic-bezier(0.86, 0, 0.07, 1)',
          style({ opacity: 0, height: '0' })
        ),
      ]),
      transition('void => overlay', [
        style({ opacity: 0, transform: 'scaleY(0.8)' }),
        animate('.12s cubic-bezier(0, 0, 0.2, 1)'),
      ]),
      transition('overlay => void', [
        animate('.1s linear', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class KanziUiMenuUserProfileComponent {
  layoutService: LayoutService = inject(LayoutService);
  userProfile = input.required<UserCognitoModel | null>();
  userConfigsWMS = input<UserConfigsWMSModel | null>();
  @Output() onLogout = new EventEmitter();
  constructor(public el: ElementRef) {}

  toggleMenu() {
    this.layoutService.onMenuProfileToggle();
  }

  logout() {
    this.onLogout.emit();
  }

  get isHorizontal() {
    return this.layoutService.isHorizontal() && this.layoutService.isDesktop();
  }

  get menuProfileActive(): boolean {
    return this.layoutService.state.menuProfileActive;
  }

  get menuProfilePosition(): string {
    return this.layoutService.config().menuProfilePosition;
  }

  get isTooltipDisabled(): boolean {
    return !this.layoutService.isSlim();
  }
}
