import { TreeNode } from 'primeng/api';
import { Queue } from './queue.class';
export class InventoryTree implements TreeNode {
  children?: any[];
  collapsedIcon?: any;
  data?: any;
  draggable?: boolean;
  droppable?: boolean;
  expanded?: boolean;
  expandedIcon?: any;
  icon?: any;
  key?: any;
  label?: string;
  leaf?: boolean;
  parent?: TreeNode;
  partialSelected?: boolean;
  selectable?: boolean;
  styleClass?: string;
  type?: string;

  constructor(data: any) {
    this.data = data;
    this.children = [];
    this.parent = {};
  }

  traverseDF(callback: any) {
    (function recurse(currentNode: TreeNode) {
      if (currentNode && currentNode.children) {
        for (let i = 0, length = currentNode.children.length; i < length; i++) {
          recurse(currentNode.children[i]);
        }
      }
      callback(currentNode);
    })(this.data);
  }

  traverseBF(callback: any) {
    const queue = new Queue();
    queue.enqueue(this);
    let currentTree = queue.dequeue();
    while (currentTree) {
      for (let i = 0, length = currentTree.children.length; i < length; i++) {
        queue.enqueue(currentTree.children[i]);
      }
      callback(currentTree);
      currentTree = queue.dequeue();
    }
  }

  contains(callback: any, traversal: any) {
    traversal.call(this, callback);
  }

  add(data: any, toData: any, traversal: any) {
    const child = new InventoryTree(data);
    let parent: any = null;
    const callback = function (node: any) {
      if (node.data.location_id === toData) {
        parent = node;
      }
    };

    this.contains(callback, traversal);

    if (parent) {
      parent.children.push(Object.assign({}, child));
      child.parent = parent;
    }
  }
}
