/* eslint-disable @angular-eslint/component-selector */
import { Component, OnInit, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kanzi-header-section',
  templateUrl: './kanzi-header-section.component.html',
  styleUrls: ['./kanzi-header-section.component.scss'],
})
export class KanziHeaderSectionComponent implements OnInit {
  @Input() items: MenuItem[];
  constructor(public translate: TranslateService) {
    this.items = [];
  }

  ngOnInit() {
    this.changeLanguajeEvent();
    this.translate.onLangChange.subscribe(() => this.changeLanguajeEvent());
  }

  changeLanguajeEvent() {
    this.translate
      .get(Array.from(this.items, (item) => (item.label ? item.label : '')))
      .subscribe((res) =>
        this.items.forEach((element) => {
          if (element && element.label) {
            element.label = res[element.label];
          }
        })
      );
  }
}
