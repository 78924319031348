export enum BOOKING_MODE {
  ADD = 'ADD',
  DELETE = 'DELETE',
}

export enum itemState {
  ISSUED = 0,
  PRESENT = 1,
  IN_TRANSFER = 2,
  INACTIVE = 3,
  LOST = 4,
  SOLD = 5,
  NOT_PRESENT = 6,
  PACKED = 7,
}
