<!-- Update 1/04/22 -->
<div class="card text-center" [ngClass]="boxClass">
  <div class="inner mb-5">
    <h3 class="font-bold text-4xl">{{ totalCount | number }}</h3>
    <p class="text-sm">{{ description | translate }}</p>
  </div>
  <div class="small-box-footer">
    <a *ngIf="urlRedirect" [routerLink]="[urlRedirect]"
      >{{ 'Ver más' | translate }} <i class="fa fa-arrow-circle-right icon"></i
    ></a>
  </div>
</div>
