import { inject } from '@angular/core';
import {CanActivateFn, CanMatchFn, Router, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromAuth from '../reducers';
import { map, tap } from 'rxjs/operators';
import * as Auth from '../actions/auth';

const checkAuthStatus = ():
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const store = inject(Store);
  return store.select(fromAuth.getLoggedIn).pipe(
    map((auth: boolean) => auth),
    tap((auth: boolean) => {
      if (!auth) {
        store.dispatch(new Auth.LoginRedirect());
      }
    })
  );
};

export const canActivateFn: CanActivateFn = () => {
  return checkAuthStatus();
};

export const canMatchFn: CanMatchFn = () => {
  return checkAuthStatus();
};
