export enum KanziActions {
  SALE_OUT = 'SALE_OUT',
  SIMPLE_ENTRY = 'SIMPLE_ENTRY',
  SIMPLE_OUTPUT = 'SIMPLE_OUTPUT',
  TRANSFER = 'TRANSFER',
  TRANSFER_OUT = 'TRANSFER_OUT',
  TRANSFER_IN = 'TRANSFER_IN',
  PRINT = 'PRINT',
  AUDIT_ENTRY = 'AUDIT_ENTRY',
  TAKEOUT = 'TAKEOUT',
  SALE = 'SALE',
  PACK = 'PACK',
  UNPACK = 'UNPACK',
  READING = 'READING',
  VERIFIED = 'VERIFIED',
  RETURN = 'RETURN',
  OTHER = 'OTHER',
}
export enum KanziColorSchema{
  dark="dark",
  light="light",
  dim='dim'
}