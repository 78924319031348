<div *ngIf="form" [formGroup]="form" class="field grid">
  <label
    class="col-12 mb-2 md:col-3 md:mb-0 font-semibold text-800"
    [attr.for]="question?.key"
    >{{ question?.label }}:
  </label>
  <div [ngSwitch]="question?.controlType" class="col-12 md:col-9">
    <input
      pInputText
      *ngSwitchCase="'textbox'"
      class="inputfield w-full"
      [type]="question?.type"
      [id]="question?.key"
      [name]="question?.key"
      [formControlName]="question ? (question.key ? question.key : '') : ''"
      [readonly]="question?.readonly"
      (change)="changes(question)"
      [value]="question?.value"
    />
    <p-dropdown
      *ngSwitchCase="'dropdown'"
      class="inputfield w-full"
      [id]="question?.key"
      [options]="question?.options!"
      [formControlName]="question ? (question.key ? question.key : '') : ''"
      [filter]="true"
      filterBy="value"
      optionLabel="value"
      optionValue="key"
      (onChange)="changes(question)"
    ></p-dropdown>

    <!-- <select
      *ngSwitchCase="'dropdown'"
      class="inputfield w-full"
      [id]="question?.key"
      [formControlName]="question ? (question.key ? question.key : '') : ''"
      (change)="changes(question)"
    >
      <option *ngFor="let opt of question?.options" [value]="opt.key">
        {{ opt.value }}
      </option>
    </select> -->
    <div *ngSwitchCase="'grouptext'">
      <div
        *ngFor="let item of question?.items"
        style="float: left; margin-right: 20px"
      >
        <label
          [attr.for]="item.key"
          class="control-label"
          [ngStyle]="{
            width: item.size / 2 + 'px',
            float: item.labelPosition === 'left' ? 'left' : 'right',
            'margin-right': item.labelPosition === 'left' ? '5px' : '0px',
            'margin-left': item.labelPosition === 'left' ? '0px' : '5px'
          }"
          >{{ item.label }}</label
        >
        <input
          class="inputfield w-full"
          [type]="item.type"
          [id]="item.key"
          [name]="item.key"
          [formControlName]="item.key"
          [readonly]="item.readonly"
          [ngStyle]="{ width: item.size + 'px' }"
          (change)="changes(item)"
        />
      </div>
    </div>
  </div>
</div>
