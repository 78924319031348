export interface ControlPointTotalsModel {
  total_day: number;
  total_month: number;
  total_year: number;
}

export interface OrderControlModel {
  id?: number;
  type: number;
  status: string;
  amount: number;
  count: number;
  count_cedi: number;
  created: number;
  order_id: number;
  order: number;
  provider_id?: number;
  external_id?: string;
  provider_name?: string;
}
