import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import {
  CustomerModel,
  KanziStatus,
  LocationListModel,
  LocationModel,
  SkuListModel,
  SkuModel,
  UserModel,
  UserRoles,
} from '@kanzi-apes/kanzi-models';
import { ProductLineService } from './product-list.service';
import { MessageService } from '@kanzi-apes/kanzi-prime-ui';
import { LocationListService } from '@kanzi-apes/kanzi-locations';
import { forkJoin, take } from 'rxjs';

@Component({
  selector: 'kanzi-sku-line-create',
  templateUrl: './kanzi-sku-line-create.component.html',
  styleUrls: ['./kanzi-sku-line-create.component.scss'],
})
export class KanziSkuLineCreateComponent implements OnInit, OnChanges {
  @Input() set linesList(data: any[]) {
    console.log("🚀 ~ @Input ~ data", data)
    if (data.length > 0) {
      let auxList: any[] = [];
      auxList = auxList.concat(data);
      if (auxList.length > 1) {
        auxList.pop();
      }
      if (auxList.filter((line) => line.amount === 0).length === 0) {
        if (this.byExternalId) {
          const skuList = data.map((i) =>
            this.productListService.getSkusList({
              page: 1,
              page_size: 1,
              external_id: i.sku_external_id,
              customer_id: this.customer ? this.customer.id : 0,
            })
          );

          forkJoin(skuList).subscribe((skus) => {
            this._linesList = data
              .map((line) => {
                const sku = skus.find(
                  (i) => i.results[0]?.external_id == line.sku_external_id
                );
                return {
                  display_name: sku?.results[0].name,
                  sku: sku?.results[0],
                  sku_id: sku?.results[0].id,
                  sku_display_name: sku?.results[0].display_name,
                  sku_external_id: sku?.results[0].external_id,
                  amount: line.amount,
                  id: line.id ? line.id : null,
                };
              })
              .filter((i) => i.sku_external_id);
            this.validForm.emit(true);
            this.skuLinesSelected.emit(this._linesList);
          });
        } else {
          const dataFilter = data.filter((i) => i.sku_id);
          const skuList = dataFilter.map((i) =>
            this.productListService.getSkusId(i.sku_id)
          );
          forkJoin(skuList).subscribe((skus) => {
            this._linesList = data.map((line) => {
              const sku = skus.find((i) => i.id == line.sku_id);
              return {
                display_name: sku?.name,
                sku: sku,
                sku_id: sku?.id,
                sku_display_name: sku?.display_name,
                sku_external_id: sku?.external_id,
                amount: line.amount,
                id: line.id ? line.id : null,
              };
            });
            this.validForm.emit(true);
           // this.skuLinesSelected.emit(this._linesList);
          });
        }
      } else {
        this.validForm.emit(false);
      }
    } else if (data.length === 0) {
      this._linesList = [];
      this.addSkuList();
    }
  }
  @Input() positioning: boolean;
  @Input() locationParentId: number;
  @Input() user: UserModel | null;
  @Input() customer: CustomerModel | null;
  @Input() byExternalId: boolean = false;
  @Input() canAddLines:boolean = true;
  @Output() skuLinesSelected = new EventEmitter<any[]>();
  @Output() validForm = new EventEmitter<boolean>();
  @Output() selectAllLines = new EventEmitter<number>();
  @Output() modalSkus = new EventEmitter<boolean>();

  linesSelected: any[] = [];
  _validAmount = false;
  _activeButtons = false;
  _linesList: any[] = [];

  skusList: SkuModel[] | null = null;
  skusListFiltered: SkuModel[] = [];
  cloneLines: { [id: number]: any } = {};
  locationsSuggested: LocationModel[] = [];
  locationsList: LocationListModel | null = null;
  displayDialogScan: boolean = false;

  constructor(
    private productListService: ProductLineService,
    private messageService: MessageService,
    private locationService: LocationListService
  ) {
    this.positioning = false;
    this.locationParentId = 0;
    this.user = null;
    this.customer = null;
  }

  ngOnInit() {
    this.addSkuList();
  }

  ngOnChanges(changes:SimpleChanges) {
    if (
      this.locationParentId &&
      this.user &&
      this.user.configs &&
      this.user.configs.role &&
      this.user.configs.role.name === UserRoles.MANAGER
    ) {
      this.locationService
        .getlocationList({
          page: 1,
          page_size: 1000,
          parent_id: this.locationParentId,
        })
        .subscribe((locations: LocationListModel) => {
          this.locationsList = locations;
        });
    }
  }

  addSkuList() {
    this._linesList.push({
      display_name: '',
      sku: null,
      sku_display_name: '',
      sku_external_id: '',
      amount: 1,
      balance: 0,
    });
  }

  filterSkus(event: any) {
    const query = event.query;
    this.productListService
      .getSkusList({
        page: 1,
        search: query,
        page_size: 100,
        customer_id: this.customer ? this.customer.id : 0,
      })
      .subscribe((skus: SkuListModel) => {
        this.skusListFiltered = skus.results;
      });
  }

  onRowDelete(index: number) {
    if (this._linesList.length > 1) {
      this._linesList.splice(index, 1);
    } else {
      this.messageService.add({
        severity: KanziStatus.ERROR,
        summary: 'La orden debe tener al menos una linea.',
      });
    }
  }

  onSelectSkuItem(event: any, i: number) {
    this._linesList[i].sku_id = event.id;
    this._linesList[i].sku_display_name = event.display_name;
    this._linesList[i].sku_external_id = event.external_id;
    this._linesList[i].amount = 1;
    this._linesList[i].sku = event;
    // this.addSkuList();
    this.groupSkuLines(this._linesList);
  }

  onSelectSkuLocation(event: any, i: number) {
    const lc: LocationModel = event.value;
    if (lc) {
      this._linesList[i].sub_location_id = lc.id;
    } else {
      this._linesList[i].sub_location_id = 0;
      this._linesList[i].locationOption = null;
    }
  }

  groupSkuLines(lines: any[]) {
    const linesFiltered: any[] = [];
    lines.forEach((line) => {
      const lineFound = linesFiltered.find(
        (lineFiltered) => lineFiltered.sku_external_id === line.sku_external_id
      );
      if (!lineFound) {
        linesFiltered.push(line);
      } else {
        lineFound.amount += line.amount;
      }
    });

    this._linesList = [];
    this._linesList = this._linesList.concat(linesFiltered);
    this.skuLinesSelected.emit(this._linesList);
    this.validForm.emit(true);
  }

  showDialogToAdd() {
    this.modalSkus.emit(true);
  }

  showDialogToScanCode() {
    this.displayDialogScan = true;
  }

  onRowEditInit(skuLine: any) {
    this.cloneLines[skuLine.sku_id] = { ...skuLine };
  }

  onRowEditSave(skuLine: any) {
    if (skuLine.amount > 0) {
      delete this.cloneLines[skuLine.sku_id];
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Linea Actualizada.',
      });
      this.skuLinesSelected.emit(this._linesList);
      this.validForm.emit(true);
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'La cantidad debe ser mayor a 0.',
      });
    }
  }

  onRowEditCancel(skuLine: any, index: number) {
    this._linesList[index] = this.cloneLines[skuLine.sku_id];
    delete this.cloneLines[skuLine.sku_id];
  }

  onSkuBarcodeReading(code: string) {
    const barcode = code.trim();
    let skuSelected: SkuModel;
    if (barcode) {
      this.productListService
        .getSkusList({ page: 1, search: barcode, page_size: 1 })
        .subscribe((skus: SkuListModel) => {
          if (skus.count > 0) {
            skuSelected = skus.results[0];
            this._linesList[this._linesList.length - 1].sku_id = skuSelected.id;
            this._linesList[this._linesList.length - 1].sku_display_name =
              skuSelected.display_name;
            this._linesList[this._linesList.length - 1].sku_external_id =
              skuSelected.external_id;
            this._linesList[this._linesList.length - 1].amount = 1;
            this._linesList[this._linesList.length - 1].sku = skuSelected;
            if (this.positioning && skuSelected.id) {
              this.productListService
                .getSkuLocationSuggested(skuSelected.id, this.locationParentId)
                .subscribe((data: any) => {
                  if (
                    this.user &&
                    this.user.configs?.role.name === UserRoles.MANAGER
                  ) {
                    data.locations = data.locations.concat(
                      this.locationsList?.results
                    );
                  }
                  this._linesList[
                    this._linesList.length - 1
                  ].locationsSuggested = data.locations;
                  if (
                    this._linesList[this._linesList.length - 1]
                      .locationsSuggested.length === 1
                  ) {
                    this._linesList[this._linesList.length - 1].locationOption =
                      this._linesList[
                        this._linesList.length - 1
                      ].locationsSuggested[0];
                    this._linesList[
                      this._linesList.length - 1
                    ].sub_location_id =
                      this._linesList[
                        this._linesList.length - 1
                      ].locationsSuggested[0].id;
                  } else if (
                    this._linesList[this._linesList.length - 1]
                      .locationsSuggested.length > 1
                  ) {
                    this._linesList[
                      this._linesList.length - 1
                    ].locationsSuggested = [
                      {
                        display_name: 'Seleccionar Locación',
                        sub_location_id: 0,
                      },
                    ].concat(data.locations);
                    this._linesList[this._linesList.length - 1].locationOption =
                      {
                        display_name: 'Seleccionar Locación',
                        sub_location_id: 0,
                      };
                  }
                  this.addSkuList();
                  this.groupSkuLines(this._linesList);
                });
            } else {
              this.addSkuList();
              this.groupSkuLines(this._linesList);
            }
          }
        });
    }
  }
}
