<a
  style="cursor: pointer"
  class="layout-config-button"
  (click)="onConfigButtonClick()"
  joyrideStep="configStep"
  title="Configuración"
  text="Opciones de configuración para la aplicación."
>
  <i class="pi pi-cog"></i>
</a>
<p-sidebar
  [(visible)]="visible"
  position="right"
  [transitionOptions]="'.3s cubic-bezier(0, 0, 0.2, 1)'"
  styleClass="w-full sm:w-18rem"
>
  <div class="px-2">
    <kanzi-menu-rigth
      *ngIf="modulesApp"
      [modules]="modulesApp"
      [rolAccess]="rolUserApp"
    ></kanzi-menu-rigth>
    <ng-container *ngIf="!minimal">
      <h5>{{ 'Tema' | translate }}</h5>
      <div class="w-full">
        <span class="p-buttonset w-full">
          <button
            pButton
            pTooltip="{{ 'Tema Claro' | translate }}"
            tooltipPosition="top"
            type="button"
            icon="pi pi-sun"
            (click)="changeColorScheme('light')"
            [ngClass]="
              colorScheme !== 'light' ? 'w-4 p-button-outlined' : 'w-4'
            "
          ></button>
          <button
            pButton
            pTooltip="{{ 'Tema Oscuro' | translate }}"
            tooltipPosition="top"
            type="button"
            icon="pi pi-moon"
            (click)="changeColorScheme('dark')"
            [ngClass]="colorScheme !== 'dark' ? 'w-4 p-button-outlined' : 'w-4'"
          ></button>
          <button
            pButton
            pTooltip="{{ 'Tema Dim' | translate }}"
            tooltipPosition="top"
            type="button"
            icon="pi pi-cloud"
            (click)="changeColorScheme('dim')"
            [ngClass]="colorScheme !== 'dim' ? 'w-4 p-button-outlined' : 'w-4'"
          ></button>
        </span>
      </div>
      <h5>{{ 'Zoom' | translate }}</h5>
      <div class="flex align-items-center">
        <button
          icon="pi pi-minus"
          type="button"
          pButton
          (click)="decrementScale()"
          class="p-button-text p-button-rounded w-2rem h-2rem mr-2"
          [disabled]="scale === scales[0]"
        ></button>
        <div class="flex gap-3 align-items-center">
          <i
            class="pi pi-circle-fill text-300"
            *ngFor="let s of scales"
            [ngClass]="{ 'text-primary-500': s === scale }"
          ></i>
        </div>
        <button
          icon="pi pi-plus"
          type="button"
          pButton
          (click)="incrementScale()"
          class="p-button-text p-button-rounded w-2rem h-2rem ml-2"
          [disabled]="scale === scales[scales.length - 1]"
        ></button>
      </div>
    </ng-container>
  </div>
</p-sidebar>
