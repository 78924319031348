import { Injectable, Optional } from '@angular/core';
import {
  Environment,
  KanziEnvironmentModel,
  KanziWMSEnvironmentModel,
} from '@kanzi-apes/kanzi-models';

export class EnvironmentData extends Environment {}

export class EnvironmentService {
  private _environment: KanziEnvironmentModel | null = null;
  private _wmsEnvironment: KanziWMSEnvironmentModel | null = null;
  constructor(
    private env?: KanziEnvironmentModel,
    private wmsEnv?: KanziWMSEnvironmentModel
  ) {
    if (env) {
      this._environment = env;
    }
    if (wmsEnv) {
      this._wmsEnvironment = wmsEnv;
    }
  }

  get environment() {
    return this._environment;
  }

  get wmsEnvironment() {
    return this._wmsEnvironment;
  }
}
