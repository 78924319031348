import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as ngAnimation from '@angular/animations';

@Component({
  selector: 'kanzi-kanzi-lang-top-select',
  templateUrl: './kanzi-lang-top-select.component.html',
  styleUrls: ['./kanzi-lang-top-select.component.scss'],
  animations: [
    ngAnimation.trigger('collapse', [
      ngAnimation.state(
        'open',
        ngAnimation.style({
          opacity: '1',
          display: 'block',
          transform: 'translate3d(0,0,0)',
        })
      ),
      ngAnimation.state(
        'closed',
        ngAnimation.style({
          opacity: '0',
          display: 'none',
          transform: 'translate3d(0,-100%,0)',
        })
      ),
      ngAnimation.transition(
        'closed => open',
        ngAnimation.animate('200ms ease-in')
      ),
      ngAnimation.transition(
        'open => closed',
        ngAnimation.animate('100ms ease-out')
      ),
    ]),
  ],
})
export class KanziLangTopSelectComponent implements OnInit {
  languajes = ['en', 'es', 'pr'];
  collLenguaje = 'closed';
  constructor(public translateService: TranslateService) {}

  ngOnInit(): void {}

  collapseTop(section: string) {
    switch (section) {
      case 'lenguaje':
        this.collLenguaje = this.collLenguaje === 'open' ? 'closed' : 'open';
        break;
      default:
        break;
    }
  }

  changeLanguaje(lang: string) {
    this.translateService
      .use(lang)
      .toPromise()
      .then(() => this.collapseTop('lenguaje'));
  }
}
