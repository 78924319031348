import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@kanzi-apes/kanzi-auth';
import { LayoutZenderboxComponent } from './pages/layout/layout.component';

const ZENDERBOX_CORE_ROUTES: Routes = [
  {
    path: 'main',
    component: LayoutZenderboxComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/main/dashboards', pathMatch: 'full' },
      {
        path: 'dashboards',
        loadChildren: () =>
          import('../dashboards/dashboards.module').then(
            (m) => m.DashboardsZenderboxModule
          ),
        canLoad: [AuthGuard],
      },

      {
        path: 'contacts',
        loadChildren: () =>
          import('@kanzi-apes/kanzi-contacts').then(
            (m) => m.KanziContactsModule
          ),
        canLoad: [AuthGuard],
      },

      {
        path: 'products',
        loadChildren: () =>
          import('@kanzi-apes/kanzi-products').then(
            (m) => m.KanziProductsModule
          ),
        canLoad: [AuthGuard],
      },

      {
        path: 'moves',
        loadChildren: () =>
          import('@kanzi-apes/kanzi-moves').then((m) => m.KanziMovesModule),
        canLoad: [AuthGuard],
      },
      {
        path: 'inventories',
        loadChildren: () =>
          import('@kanzi-apes/kanzi-stock-summary').then(
            (m) => m.KanziStockSummaryModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'inventories-classic',
        loadChildren: () =>
          import('@kanzi-apes/kanzi-stock-summary-classic').then(
            (m) => m.KanziStockSummaryClassicModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'production-orders',
        loadChildren: () =>
          import('@kanzi-apes/kanzi-production-order').then(
            (m) => m.KanziProductionOrderModule
          ),
        canLoad: [AuthGuard],
      },

      {
        path: 'purchase-orders',
        loadChildren: () =>
          import('@kanzi-apes/kanzi-purchase-order').then(
            (m) => m.KanziPurchaseOrderModule
          ),
        canLoad: [AuthGuard],
      },

      {
        path: 'print-orders',
        loadChildren: () =>
          import('@kanzi-apes/kanzi-print-order').then(
            (m) => m.KanziPrintOrderModule
          ),
        canLoad: [AuthGuard],
      },

      {
        path: 'operations',
        loadChildren: () =>
          import('@kanzi-apes/kanzi-operations').then(
            (m) => m.KanziOperationsModule
          ),
        canLoad: [AuthGuard],
      },

      {
        path: 'audits-classic',
        loadChildren: () =>
          import('@kanzi-apes/kanzi-audits-classic').then(
            (m) => m.KanziAuditsClassicModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'audits',
        loadChildren: () =>
          import('@kanzi-apes/kanzi-audits').then((m) => m.KanziAuditsModule),
        canLoad: [AuthGuard],
      },
      {
        path: 'analytics',
        loadChildren: () =>
          import('@kanzi-apes/kanzi-analytics-reports').then(
            (m) => m.KanziAnalyticsReportsModule
          ),
        canLoad: [AuthGuard],
      },
      {
        path: 'logistics',
        loadChildren: () =>
          import('@kanzi-apes/kanzi-logistics').then(
            (m) => m.KanziLogisticsModule
          ),
        canLoad: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(ZENDERBOX_CORE_ROUTES)],
  exports: [RouterModule],
})
export class CoreZenderboxRoutingModule {}
