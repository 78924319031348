<p-treeTable
  [value]="dataList"
  [autoLayout]="true"
  [columns]="cols"
  [paginator]="
    configs ? (configs.paginator ? configs.paginator : false) : false
  "
  [rows]="50"
  [lazy]="lazyMode"
  [totalRecords]="totalRecords"
  [loading]="pending"
  (onLazyLoad)="loadNodes($event)"
  (onNodeExpand)="nodeExpand($event)"
>
  <ng-template pTemplate="caption">
    <div class="p-grid p-justify-end">
      <!-- <div class="p-lg-6 p-col">
        <h3>Seleccionar Rango de Fechas</h3>
        <p-calendar
          #rangeDate
          [(ngModel)]="rangeDates"
          selectionMode="range"
          dateFormat="dd/mm/yy"
          dataType="date"
          placeholder="{{ 'Rango de Fechas' | translate }}"
          [readonlyInput]="true"
          [showIcon]="true"
          (onClearClick)="clearFilters()"
          (onSelect)="selectDateRange(rangeDate.value)"
        ></p-calendar>
      </div> -->
      <div class="p-col-2">
        <button
          type="button"
          pButton
          icon="pi pi-file-excel"
          iconPos="left"
          label=""
          alt="Excel"
          (click)="exportExcel()"
          style="margin-right: 0.5em"
          class="ui-button-success"
        ></button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns">
        {{ col.header | translate }}
      </th>
      <!-- <th *ngIf="configs?.options"></th> -->
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-rowNode
    let-rowData="rowData"
    let-columns="columns"
  >
    <tr>
      <td
        *ngFor="let col of columns; let i = index"
        ttEditableColumn
        [ngClass]="{ 'p-toggler-column': i === 0 }"
      >
        <p-treeTableToggler
          [rowNode]="rowNode"
          *ngIf="i == 0"
        ></p-treeTableToggler>

        <ng-container *ngIf="col.edit && configs?.options?.edit; else elseTemplate">
          <p-treeTableCellEditor>
            <ng-template pTemplate="input">
              <form [formGroup]="formComents" (ngSubmit)="onComents(rowData)">
                <input
                  [style]="{ width: '90%' }"
                  formControlName="comments"
                  pInputText
                  type="text"
                  [ngModel]="rowData[col.field]"
                />
                <button
                  [disabled]="formComents.invalid"
                  class="p-ml-2"
                  pButton
                  type="submit"
                  icon="pi pi-check"
                ></button>
              </form>
            </ng-template>
            <ng-template pTemplate="output">{{
              rowData[col.field]
            }}</ng-template>
          </p-treeTableCellEditor>
        </ng-container>
        <ng-template #elseTemplate>
          {{ rowData[col.field] }}
        </ng-template>
      </td>
      <!-- <td *ngIf="configs?.options">
        <span *ngIf="configs?.options.view">
          <a class="text-green" (click)="onRowSelect(rowData)">
            <i class="pi pi-eye"></i>
          </a>
        </span>
      </td> -->
    </tr>
  </ng-template>
</p-treeTable>
<!-- <div class="overlay" *ngIf="pending">
  <i class="fa fa-refresh fa-spin"></i>
</div> -->
