import {
  ActionReducerMap,
  createSelector,
  createFeatureSelector,
  ActionReducer,
  MetaReducer,
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import { RouterStateUrl } from '@kanzi-apes/kanzi-utils';
import * as fromRouter from '@ngrx/router-store';
import * as fromLayout from '../core/reducers/layout';
import * as fromMonitoring from '../core/reducers/monitoring/monitoring';

export interface State {
  layout: fromLayout.State;
  router: fromRouter.RouterReducerState<RouterStateUrl>;
  monitoring: fromMonitoring.State;
}

export const reducers: ActionReducerMap<State> = {
  layout: fromLayout.reducer,
  router: fromRouter.routerReducer,
  monitoring: fromMonitoring.reducer,
};

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action): any {
    console.log('state', state);
    console.log('action', action);

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [logger]
  : [];

export const getLayoutState = createFeatureSelector<fromLayout.State>('layout');
export const selectMonitoringState =
  createFeatureSelector<fromMonitoring.State>('monitoring');

// ###Monitoring Selectors###
export const getMonitoringStatus = createSelector(
  selectMonitoringState,
  fromMonitoring.getStatus
);

export const getMonitoringStatusMsg = createSelector(
  selectMonitoringState,
  fromMonitoring.getStatusMsg
);

// ###Layout Selectors###

export const getShowMenuMain = createSelector(
  getLayoutState,
  fromLayout.getShowMenuMain
);

export const getShowRightMenu = createSelector(
  getLayoutState,
  fromLayout.getShowRightMenu
);

export const getShowProgressBar = createSelector(
  getLayoutState,
  fromLayout.getShowProgressBar
);

export const getCustomerParent = createSelector(
  getLayoutState,
  fromLayout.getCustomerParent
);
export const getColorScheme = createSelector(
  getLayoutState,
  fromLayout.getColorScheme
);
