<div class="flex h-screen">
  <div
    class="w-full lg:w-4 h-full text-center px-6 py-6 flex flex-column justify-content-between align-items-center"
  >
    <img
      [src]="logoPath"
      alt="kanzi-app-logo"
      class="mt-4"
      style="width: fit-content; max-height: 100px"
    />
    <div class="flex flex-column align-items-center gap-4">
      <app-login-form
        (submitted)="onSubmit($event)"
        [errorMessage]="(error$ | async) || ''"
        [pending]="(pending$ | async) || false"
      >
      </app-login-form>
    </div>
    <p>
      <strong
        ><a target="_BLANK" href="http://www.technoapes.co">Technoapes</a>
        &copy; {{ year }}.</strong
      >
      <br />
      v{{ appVersion }}
    </p>
  </div>
  <div
    class="w-8 hidden lg:flex flex-column justify-content-between align-items-center px-6 py-6 bg-cover bg-norepeat"
    [ngStyle]="{ 'background-image': 'url(' + backgroundPath + ')' }"
  >
    <div class="mt-auto mb-auto">
      <h1 class="block text-white text-7xl font-semibold">
        A<small>ccesible</small>
      </h1>
      <h1 class="block text-white text-7xl font-semibold">
        P<small>opular</small>
      </h1>
      <h1 class="block text-white text-7xl font-semibold">
        E<small>asy</small>
      </h1>
      <h1 class="block text-white text-7xl font-semibold">
        S<small>oftware</small>
      </h1>
    </div>
    <div class="flex align-items-center gap-5">
      <p class="text-white font-semibold">
        Lovingly made in Bogotá <i class="fa fa-heart text-3xl p-1"></i>
      </p>
    </div>
  </div>
</div>
<app-alert></app-alert>
