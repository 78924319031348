import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { LocationsWMSFilterModel } from '@kanzi-apes/kanzi-models';
import {
  injectQuery,
  injectQueryClient,
  keepPreviousData,
} from '@tanstack/angular-query-experimental';
import { lastValueFrom } from 'rxjs';
import { WarehouseQueryKeys } from './query-key.factory';
import { KanziLocationsService } from '../services/kanzi-locations.service';

@Injectable({
  providedIn: 'root',
})
export class LocationsQueries {
  private kanziLocationsService = inject(KanziLocationsService);
  private queryClient = injectQueryClient();
  private page = signal(1);
  private locationTypeName = signal<string>('warehouse');
  private warehouseRootListFilters = signal<LocationsWMSFilterModel>({
    type__name__eq: this.locationTypeName(),
  });

  public warehouseRootListQuery = injectQuery(() => ({
    queryKey: WarehouseQueryKeys.all(this.warehouseRootListFilters()),
    queryFn: () =>
      lastValueFrom(
        this.kanziLocationsService.getWarehouseList(
          this.warehouseRootListFilters()
        )
      ),
    retry: 2,
    staleTime: 50000,
    placeholderData: keepPreviousData,
  }));

  constructor() {
    effect(() => {
      if (
        !this.warehouseRootListQuery.isPlaceholderData() &&
        this.warehouseRootListQuery.data()?.last_evaluated_key
      ) {
        this.queryClient.prefetchQuery({
          queryKey: WarehouseQueryKeys.all({
            ...this.warehouseRootListFilters(),
            last_evaluated_key_id:
              this.warehouseRootListQuery.data()?.last_evaluated_key,
          }),
          queryFn: () =>
            lastValueFrom(
              this.kanziLocationsService.getWarehouseList({
                ...this.warehouseRootListFilters(),
                last_evaluated_key_id:
                  this.warehouseRootListQuery.data()?.last_evaluated_key,
              })
            ),
        });
      }
    });
  }
}
