import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'kanzi-text-content-widget',
  templateUrl: './kanzi-text-content-widget.component.html',
  styleUrls: ['./kanzi-text-content-widget.component.scss'],
})
export class KanziTextContentWidgetComponent implements OnInit {
  @Input() title: string;
  @Input() type: string;
  @Input() textData: string;
  constructor() {
    this.title = '';
    this.type = '';
    this.textData = '';
  }

  ngOnInit(): void {}
}
