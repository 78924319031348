import { ContactModel } from '../contacts';
import { SkuModel } from '../products';
import { LocationModel } from '../location';
import { FiltersModel } from '../filters';
import { DataListModel, DataModel } from '../data';
import { ItemTypesModel } from '../items';

export interface PurchaseOrderFilterModel extends FiltersModel {
  external_id?: string;
}

export interface PurchaseOrderListModel extends DataListModel {
  results: PurchaseOrderModel[];
}

export interface PurchaseOrderLineListModel extends DataListModel {
  results: PurchaseOrderLineModel[];
}

export interface PurchaseOrderModel extends DataModel {
  status: string;
  prefix: string;
  external_id: string;
  end_date: Date;
  provider: ContactModel;
  provider_id: number;
  provider_name?: string;
  entry_location: LocationModel;
  entry_location_id: number;
  entry_location_display_name?: string;
  entry_name: string;
  lines: PurchaseOrderLineModel[];
  created_by_username?: string;
  used_external_id?: boolean;
  customer_id?: number;
  total_amount?: number;
  properties?: any;
  item_type?: ItemTypesModel;
  item_type_name?: string;
}

export interface PurchaseOrderLineModel {
  display_name?: string;
  sku?: SkuModel;
  sku_id?: string;
  sku_external_id?: string;
  sourceInventory?: number;
  amount?: number;
  status?: string;
  purchase_order?: number;
  sub_location_id?: number;
  id?:number;
  purchase_order_id?:number;
}

export interface PurchaseOrderLineCreateModel {
  sku: SkuModel | null;
  sku_id: string | null;
  sku_external_id: string | null;
  amount?: number;
  sku_display_name: string | null;
  sub_location_id?: number | null;
}

export interface PurchaseOrderLineFilterModel extends FiltersModel {
  purchase_order_id?: number;
  sku_id?: string;
}

export interface PurchaseOrderFormModel {
  provider_id: number;
  lines: PurchaseOrderLineModel[];
  entry_location_id: number;
  customer_id: number;
  external_id?: string;
  properties?: any;
  used_external_id?: boolean;
  item_type_id?:number;
  automatic_printing?:boolean;
  id?:number;
}

export interface PurchaseOrderPropertyItemModel {
  code?: string;
  key_name: string;
  model: string;
  model_field: string;
}

export interface PurchaseOrderPropertiesListModel extends DataListModel {
  results: PurchaseOrderPropertyItemModel[];
}

export interface PurchaseOrderPropertyTableModel {
  property: PurchaseOrderPropertyItemModel;
  value: any;
  edit: boolean;
  properties: [];
  values: [];
}

export interface PurchaseOrderPropertiesFilterModel extends FiltersModel {
  model?: string;
  model_field?: string;
}

export interface ExecuteActionsOrderModel {
  action:      string;
  model:       string;
  order_id:    number;
  sku_id:      number;
  amount:      number;
  customer_id: number;
}
