export class AuditReport {
  id             !: number;
  audit_report   !: number;
  user           !: number;
  user_name      !: string;
  user_email     !: string;
  state          !: number;
  cut_date       !: Date;
  created        !: Date;
  modified       !: Date;
  location       !: number;
  location_name  !: string;
  location_code  !: string;
  counted_total  !: number;
  expected_total !: number;
  found_total    !: number;
  not_found_total!: number;
  extras_total   !: number;

  constructor() {}
}
