<p-dialog
  [(visible)]="showDialog"
  [style]="{ width: '30vw', height: '40vh' }"
  [breakpoints]="{ '960px': '75vw', '640px': '40vh' }"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [maximizable]="false"
  [header]="'Tiempo estimado'"
  (onHide)="closeDialog()"
>
  <div class="grid p-fluid">
    <div class="field col-12 md:col-3">
      <label class="font-bold text-500">Días</label>
      <p-inputNumber
        buttonLayout="vertical"
        spinnerMode="vertical"
        inputId="vertical"
        decrementButtonClass="p-button-help p-button-text p-button-sm"
        incrementButtonClass="p-button-help p-button-text p-button-sm"
        incrementButtonIcon="pi pi-chevron-up"
        decrementButtonIcon="pi pi-chevron-down"
        [(ngModel)]="estimatedTime.days"
        [showButtons]="true"
        [min]="0"
        [max]="365"
      >
      </p-inputNumber>
    </div>
    <div class="field col-12 md:col-3">
      <label class="font-bold text-500">Horas</label>
      <p-inputNumber
        buttonLayout="vertical"
        spinnerMode="vertical"
        inputId="vertical"
        decrementButtonClass="p-button-help p-button-text p-button-sm"
        incrementButtonClass="p-button-help p-button-text p-button-sm"
        incrementButtonIcon="pi pi-chevron-up"
        decrementButtonIcon="pi pi-chevron-down"
        [(ngModel)]="estimatedTime.hours"
        [showButtons]="true"
        [min]="0"
        [max]="24"
      >
      </p-inputNumber>
    </div>
    <div class="field col-12 md:col-3">
      <label class="font-bold text-500">Minutos</label>
      <p-inputNumber
        buttonLayout="vertical"
        spinnerMode="vertical"
        inputId="vertical"
        decrementButtonClass="p-button-help p-button-text p-button-sm"
        incrementButtonClass="p-button-help p-button-text p-button-sm"
        incrementButtonIcon="pi pi-chevron-up"
        decrementButtonIcon="pi pi-chevron-down"
        [(ngModel)]="estimatedTime.minutes"
        [showButtons]="true"
        [min]="0"
        [max]="60"
      >
      </p-inputNumber>
    </div>
    <div class="field col-12 md:col-3">
      <label class="font-bold text-500">Segundos</label>
      <p-inputNumber
        buttonLayout="vertical"
        spinnerMode="vertical"
        inputId="vertical"
        decrementButtonClass="p-button-help p-button-text p-button-sm"
        incrementButtonClass="p-button-help p-button-text p-button-sm"
        incrementButtonIcon="pi pi-chevron-up"
        decrementButtonIcon="pi pi-chevron-down"
        [(ngModel)]="estimatedTime.seconds"
        [showButtons]="true"
        [min]="0"
        [max]="60"
      >
      </p-inputNumber>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex justify-content-end flex-wrap">
      <button
        pButton
        pRipple
        type="button"
        label="Ok"
        iconPos="left"
        icon="fa-solid fa-check"
        class="p-button-secondary p-button-text"
        (click)="onSetTime()"
      ></button>
    </div>
  </ng-template>
</p-dialog>
