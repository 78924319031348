import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { MAINTENANCE_WORK_ORDER_STATUS } from '@kanzi-apes/kanzi-models';

@Directive({
  selector: '[kanziMWKOrderStatusStyle]',
})
export class MWKOrderStatusStyleDirective implements OnInit {
  @Input('kanziMWKOrderStatusStyle') status: string | boolean;
  @HostBinding('class')
  elemClass = '';
  constructor(private el: ElementRef) {
    this.status = '';
  }

  ngOnInit() {
    switch (this.status) {
      case MAINTENANCE_WORK_ORDER_STATUS.BUDGET_PENDING: {
        this.elemClass = 'order-badge status-budget-pending';
        break;
      }
      case MAINTENANCE_WORK_ORDER_STATUS.BY_ASSIGN: {
        this.elemClass = 'order-badge status-byassign';
        break;
      }
      case MAINTENANCE_WORK_ORDER_STATUS.IN_PROGRESS: {
        this.elemClass = 'order-badge status-inprocess';
        break;
      }
      case MAINTENANCE_WORK_ORDER_STATUS.IN_REVIEW: {
        this.elemClass = 'order-badge status-inrevision';
        break;
      }
      case MAINTENANCE_WORK_ORDER_STATUS.CLOSED: {
        this.elemClass = 'order-badge status-closed';
        break;
      }
      case MAINTENANCE_WORK_ORDER_STATUS.MAINTENANCE_PENDING: {
        this.elemClass = 'order-badge status-maintenance-pending';
        break;
      }
      case MAINTENANCE_WORK_ORDER_STATUS.COMPLETED: {
        this.elemClass = 'order-badge status-closed';
        break;
      }
      default:
        break;
    }
  }
}
