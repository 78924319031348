import {
  NgModule,
  ModuleWithProviders,
  SkipSelf,
  Optional,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  KanziEnvironmentModel,
  Environment,
  KanziWMSEnvironmentModel,
} from '@kanzi-apes/kanzi-models';
import { EnvironmentService } from '../lib/services/env/environment.service';

@NgModule({
  imports: [CommonModule],
})
export class KanziUtilsModule {
  static forRoot(envConfig: {
    env?: KanziEnvironmentModel;
    wmsEnv?: KanziWMSEnvironmentModel;
  }): ModuleWithProviders<any> {
    return {
      ngModule: KanziUtilsModule,
      providers: [
        {
          provide: EnvironmentService,
          useValue: new EnvironmentService(envConfig.env, envConfig.wmsEnv),
        },
      ],
    };
  }
  constructor(@Optional() @SkipSelf() parentModule?: KanziUtilsModule) {
    if (parentModule) {
      throw new Error(
        `UtilModule is already loaded. Import it in the AppModule only`
      );
    }
  }
}
