import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  CustomerListModel,
  UserModel,
  CustomerModel,
  UserRoles,
  AddComponent,
  KanziEnvironmentModel,
  LocationModel,
} from '@kanzi-apes/kanzi-models';
import { DiamondLayoutService } from '@kanzi-apes/kanzi-diamond-ui';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { KanziMainSidebarComponent } from '../kanzi-main-sidebar/kanzi-main-sidebar.component';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';

@Component({
  selector: 'kanzi-kanzi-top-bar',
  templateUrl: './kanzi-top-bar.component.html',
  styleUrls: ['./kanzi-top-bar.component.scss'],
})
export class KanziTopBarComponent implements OnInit, OnChanges {
  @Input()
  user: UserModel | null;
  @Input() isParent: boolean | null;
  @Input() customers: CustomerListModel | null;
  @Input() customerSelected: CustomerModel | null;
  @Input() environment: KanziEnvironmentModel | null;
  @Input() adComponent: AddComponent | null;
  @Input() status: string;
  @Input() modulesApp: any;
  @Input() menuItems: MenuItem[];
  @Output() logout = new EventEmitter();
  @Output() changeCustomer = new EventEmitter();

  viewRightMenu: boolean = false;
  logoPath: string = '';
  imageUserPath: string = '';
  languajes = ['en', 'es'];
  userRoles = UserRoles;
  selectedLocation:LocationModel | null = null;

  @ViewChild('menubutton') menuButton!: ElementRef;
  @ViewChild(KanziMainSidebarComponent) appSidebar!: KanziMainSidebarComponent;

  constructor(
    private layoutService: DiamondLayoutService,
    public translateService: TranslateService,
    public envService:EnvironmentService
  ) {
    this.user = null;
    this.isParent = false;
    this.customers = null;
    this.customerSelected = null;
    this.environment = null;
    this.adComponent = null;
    this.status = '';
    this.menuItems = [];
  }

  ngOnInit(): void {}

  ngOnChanges() {
    if (this.environment) {
      this.viewRightMenu = this.environment.viewRigthMenu
        ? this.environment.viewRigthMenu
        : false;

      this.logoPath =
        this.environment.assets.rootClient +
        this.environment.assets.paths.logos +
        this.environment.assets.logo;
      this.imageUserPath = 'assets/images/backgrounds/usuario.png';
    }
  }

  selectCustomer(customer: CustomerModel) {
    this.changeCustomer.emit(customer);
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onProfileButtonClick() {
    this.layoutService.showRightMenu();
  }

  onSearchClick() {
    this.layoutService.toggleSearchBar();
  }

  onRightMenuClick() {
    this.layoutService.showRightMenu();
  }

  get logo() {
    const logo =
      this.layoutService.config.menuTheme === 'white' ||
      this.layoutService.config.menuTheme === 'orange'
        ? 'dark'
        : 'white';
    return logo;
  }

  changeLanguaje(lang: string) {
    this.translateService.use(lang);
  }
}
