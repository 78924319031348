import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KanziRestClientService } from '@kanzi-apes/kanzi-rest-api';
import {
  CustomerListModel,
  CustomerFilterModel,
  CustomerGroupFilterModel,
  CustomerGroupListModel,
} from '@kanzi-apes/kanzi-models';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private customersUrl = '';
  private customersGroupsUrl = '';

  constructor(
    private restClient: KanziRestClientService,
    private envService: EnvironmentService
  ) {
    if (this.envService.environment) {
      this.customersUrl =
        this.envService.environment.kongApi +
        this.envService.environment.apiModules.customers +
        'customers/';
      this.customersGroupsUrl =
        this.envService.environment.kongApi +
        this.envService.environment.apiModules.customers +
        'customer-groups/';
    }
  }

  listCustomers(filters: CustomerFilterModel): Observable<CustomerListModel> {
    const url = `${this.customersUrl}`;
    return this.restClient.get<CustomerListModel>(
      url,
      filters,
      `Error al obtener los clientes`
    );
  }

  listCustomersGroups(
    filters: CustomerGroupFilterModel
  ): Observable<CustomerGroupListModel> {
    const url = `${this.customersGroupsUrl}`;
    return this.restClient.get<CustomerGroupListModel>(
      url,
      filters,
      `Error al obtener los clientes`
    );
  }
}
