import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { KanziPrimeUiModule } from '@kanzi-apes/kanzi-prime-ui';
import { AlertComponent } from './alert';
import { AlertConfirmComponent } from './alert-confirm';
import { AlertBookingComponent } from './alert-booking/alert-booking.component';
import { AlertVoiceComponent } from './alert-voice';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    KanziPrimeUiModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule,
    ReactiveFormsModule,
  ],
  declarations: [
    AlertComponent,
    AlertBookingComponent,
    AlertConfirmComponent,
    AlertVoiceComponent,
  ],
  exports: [
    AlertComponent,
    AlertBookingComponent,
    AlertConfirmComponent,
    AlertVoiceComponent,
  ],
})
export class KanziAlertsModule {}
