import { LocationModel, TypeLocationModel } from '../interfaces/location';

export class Location implements LocationModel {
  id!: number;
  name!: string;
  code!: string;
  display_name!: string;
  description!: string;
  address!: string;
  latitude!: number;
  longitude!: number;
  enabled!: boolean;
  created!: Date;
  modified!: Date;
  type!: TypeLocationModel;
  parent!: number;
  external_id!: number;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
}
