import { Action } from '@ngrx/store';
import { StatusApiMsgModel } from '@kanzi-apes/kanzi-models';

export enum MonitoringActionTypes {
  StatusOnline = '[Monitoring] Status Online',
  StatusOffline = '[Monitoring] Status Offline',
}

export class StatusOnline implements Action {
  readonly type = MonitoringActionTypes.StatusOnline;
  constructor(public payload: StatusApiMsgModel) {}
}

export class StatusOffline implements Action {
  readonly type = MonitoringActionTypes.StatusOffline;
  constructor(public payload: StatusApiMsgModel) {}
}

export type MonitoringActions = StatusOnline | StatusOffline;
