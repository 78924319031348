import {CustomerModel, UserModel} from "@kanzi-apes/kanzi-models";

export interface SkuGroupsListModel {
  count:    number;
  next:     null;
  previous: null;
  results:  skuGruopsModel[];
}

export interface skuGruopsModel {
  id:                 number;
  created_by:         UserModel;
  modified_by:        UserModel;
  parent:             any[];
  customer:           CustomerModel;
  type:               TypeModel;
  created:            string;
  modified:           string;
  external_id:        string;
  name:               string;
  photo:              null;
  serial_count:       number;
  filter:             number;
  properties:         Object;
  created_timestamp:  number;
  modified_timestamp: number;
}


export interface TypeModel {
  id:          number;
  external_id: string;
  name:        string;
}
