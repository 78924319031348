<div class="customer-dropdown" #actualTarget>
  <a
    (click)="cop.toggle($event, actualTarget)"
    class="dropdown-toggle"
    data-toggle="dropdown"
    aria-expanded="true"
    
  >
    <span
      *ngIf="customerSelected && customerSelected.name"
      class="customer-name"
      >{{ customerSelected.name | kanziNameAbrv }}</span
    >
  </a>
  <p-overlayPanel #cop>
    <ng-template pTemplate>
      <ul class="kanzi-customer-dropdown-menu">
        <li class="header">Total Clientes: {{ customers?.count }}</li>
        <li class="body">
          <ul class="menu">
            <li
              *ngFor="let customer of customers?.results"
              [class]="
                customer.id === customerSelected?.id ? 'menu-selected' : ''
              "
            >
              <a (click)="onSelectCustomer(customer)" style="cursor: pointer;">
                <i class="fa fa-briefcase text-aqua"></i> {{ customer.name }}
              </a>
            </li>
          </ul>
        </li>
        <!--<li class="footer"><a href="#">Ver todo</a></li>-->
      </ul>
    </ng-template>
  </p-overlayPanel>
</div>
