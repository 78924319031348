<p-table
  [value]="properties"
  [paginator]="true"
  [rows]="5"
  [totalRecords]="properties.length"
>
  <ng-template pTemplate="header">
    <tr>
      <th>{{ "Nombre" | translate }}</th>
      <th>{{ "Valor" | translate }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-ppt>
    <tr>
      <td>{{ ppt.name | kanziAppPipes }}</td>
      <td>{{ ppt.value | kanziAppPipes }}</td>
    </tr>
  </ng-template>
</p-table>
