<form [formGroup]="skuLineTextForm" (ngSubmit)="saveLinesText()">
<p-dialog
  header="Ingresar la lista de Sku y Cantidad"
  [(visible)]="display"
  [responsive]="true"
  showEffect="fade"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '30vw' }"
  [modal]="true"
  [draggable]="false"
>
    <div class="field">
      <label for="skulist">{{
        'Ingresa en el formato por linea: sku,cantidad' | translate
      }}</label>
      <textarea
        pInputTextarea
        class="w-full"
        [rows]="10"
        placeholder="{{ 'Sku, Cantidad' | translate }}"
        formControlName="skuListText"
        name="skuListText"
      ></textarea>
    </div>
    
      <ng-template pTemplate="footer">
        <button
          type="reset"
          pButton
          icon="pi pi-times"
          class="p-button-secondary"
          style="margin: 0 10px; width: 100px"
          label="{{ 'Borrar' | translate }}"
        ></button>
        <button
          type="submit"
          pButton
          icon="pi pi-check"
          class="p-button-primary"
          style="margin: 0 10px; width: 100px"
          label="{{ 'Guardar' | translate }}"
          [disabled]="!skuLineTextForm.valid"
        ></button>
      </ng-template>
    </p-dialog>
  </form>
