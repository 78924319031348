export enum TRANSFER_STATUS {
  COMPLETED = 'COMPLETED',
  INCOMPLETE = 'INCOMPLETE',
  PROCESSING = 'PROCESSING',
  DRAFT = 'DRAFT',
  INVALID = 'INVALID',
}

export enum PRINT_ORDER_STATES {
  ISSUED = 'ISSUED',
  IN_PROCESS = 'IN_PROCESS',
  PRINTED = 'PRINTED',
  ABORTED = 'ABORTED',
}

export enum TYPE {
  TRANSFER = 'TRANSFER',
  SIMPLE_ENTRY = 'SIMPLE_ENTRY',
  SIMPLE_OUTPUT = 'SIMPLE_OUTPUT',
  TAKEOUT = 'TAKEOUT',
  SALE_OUT = 'SALE_OUT',
  RETURN = 'RETURN',
}
