import { LocationModel } from '../location';
import { DataModel } from '../data';
import { MoveLineModel } from './move-line';

export interface MoveModel extends DataModel {
  id?: number;
  source: LocationModel;
  source_id: number;
  source_name?: string;
  destination: LocationModel;
  destination_id: number;
  destination_name?: string;
  type: string;
  status: string;
  prefix: string;
  external_id: number;
  total_amount: number;
  total_expected_items: number;
  total_received_items: number;
  lines?: MoveLineModel[];
  packing_list_file?: string;
  params:any;
}
