<div class="jumbotron">
  <div class="container" style="text-align: center" *ngIf="content">
    <h2>{{ content.title }}</h2>
    <p>{{ content.contentText }}</p>
    <p>
      <a
        class="btn btn-primary btn-lg"
        [routerLink]="[content.link]"
        role="button"
        >{{ content.buttonText }}</a
      >
    </p>
  </div>
</div>
