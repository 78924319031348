import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';

/**
 * @author Hugo Andres Escobar Ciceri
 * @version 2.1.0
 *
 * Alert Service to send and receive confirm messages.
 */
@Injectable({
  providedIn: 'root',
})
export class AlertConfirmService {
  private subject = new Subject();
  private keepAfterNavigationChange = false;

  /**
   *
   * @param router {Router}
   */
  constructor(private router: Router) {
    router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next(null);
        }
      }
    });
  }

  /**
   * @returns Observable {any}.
   *
   * Function to read the message from the Subject.
   */
  getMessageConfirm(): Observable<any> {
    return this.subject.asObservable();
  }

  /**
   *
   * @param message {string} Message to show on the alert window.
   * @param keepAfterNavigationChange {boolean} Only keep for a single location change.
   *
   * Function to add a message on the Subject
   */
  confirm(message: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ text: message });
  }
}
