import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnDestroy,
  inject,
  input,
} from '@angular/core';
import {
  LayoutModel,
  UserCognitoModel,
  UserConfigsWMSModel,
  UserModel,
} from '@kanzi-apes/kanzi-models';
import { MenuItem } from '@kanzi-apes/kanzi-prime-ui';
import { DiamondLayoutService } from '@kanzi-apes/kanzi-diamond-ui';
import { KanziUiMenuUserProfileComponent } from '../kanzi-ui-menu-user-profile/kanzi-ui-menu-user-profile.component';
import { LayoutService } from '../../services/layout/layout.service';

@Component({
  selector: 'kanzi-ui-main-sidebar',
  templateUrl: './kanzi-ui-main-sidebar.component.html',
  styleUrls: ['./kanzi-ui-main-sidebar.component.scss'],
})
export class KanziUiMainSidebarComponent implements OnDestroy {
  timeout: any = null;
  @ViewChild(KanziUiMenuUserProfileComponent)
  menuProfile!: KanziUiMenuUserProfileComponent;
  @ViewChild('menuContainer') menuContainer!: ElementRef;
  @Output() onLogout = new EventEmitter();
  layoutService: LayoutService = inject(LayoutService);
  mainMenuItems = input.required<MenuItem[]>();
  userProfile = input.required<UserCognitoModel | null>();
  userConfigsWMS = input<UserConfigsWMSModel | null>();
  constructor(public el: ElementRef) {}

  resetOverlay() {
    if (this.layoutService.state.overlayMenuActive) {
      this.layoutService.state.overlayMenuActive = false;
    }
  }

  get logo() {
    const logo =
      this.layoutService._config.menuTheme === 'light' ||
      this.layoutService._config.menuTheme === 'dark'
        ? 'dark'
        : 'white';
    return logo;
  }

  get menuProfilePosition(): string {
    return this.layoutService.config().menuProfilePosition;
  }

  onMouseEnter() {
    if (!this.layoutService.state.anchored) {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.layoutService.state.sidebarActive = true;
    }
  }

  onMouseLeave() {
    if (!this.layoutService.state.anchored) {
      if (!this.timeout) {
        this.timeout = setTimeout(
          () => (this.layoutService.state.sidebarActive = false),
          300
        );
      }
    }
  }

  anchor() {
    this.layoutService.state.anchored = !this.layoutService.state.anchored;
  }

  logout() {
    this.onLogout.emit();
  }

  ngOnDestroy() {
    this.resetOverlay();
  }
}
