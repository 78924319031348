import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { tap, map, exhaustMap, catchError } from 'rxjs/operators';
import { CustomerService } from '../../services/customer/customer.service';
import { AlertService } from '@kanzi-apes/kanzi-alerts';
import {
  CustomerFilterModel,
  CustomerGroupFilterModel,
} from '@kanzi-apes/kanzi-models';

import {
  CustomersActionTypes,
  LoadGroup,
  LoadGroupComplete,
  LoadList,
  LoadListComplete,
  LoadListError,
} from '../../actions/customers';

@Injectable()
export class CustomerListEffects {
  loadList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomersActionTypes.LoadList),
      map((action: LoadList) => action.payload),
      exhaustMap((filter: CustomerFilterModel) =>
        this.listService.listCustomers(filter).pipe(
          map((response) => new LoadListComplete(response)),
          catchError((error) => of(new LoadListError(error)))
        )
      )
    )
  );

  loadListGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomersActionTypes.LoadGroup),
      map((action: LoadGroup) => action.payload),
      exhaustMap((filter: CustomerGroupFilterModel) =>
        this.listService.listCustomersGroups(filter).pipe(
          map((response) => new LoadGroupComplete(response)),
          catchError((error) => of(new LoadListError(error)))
        )
      )
    )
  );

  loadListError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomersActionTypes.LoadListError),
        tap((action: LoadListError) => {
          // this.alertService.error(action.payload);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private listService: CustomerService,
    private alertService: AlertService
  ) {}
}
