<div class="card">
  <div
    class="flex justify-content-between flex-column-reverse md:flex-row align-items-center"
  >
    <div>
      <div
        class="text-xl text-900 mb-4 mt-4 md:mt-0 text-center md:text-left font-semibold md:pr-4"
      >
        {{ title() }}
      </div>
      @if (description()) {
      <div
        class="flex flex-wrap justify-content-center md:justify-content-start gap-3"
      >
        <p>{{ description() }}</p>
      </div>
      }
    </div>
    <div
      class="flex flex-column align-items-center justify-content-center"
      *ngIf="rightTopContent || rightTopContentTemplate"
    >
      <ng-container *ngTemplateOutlet="rightTopContentTemplate"></ng-container>
    </div>
  </div>
  <br />
  <ng-content></ng-content>
</div>
