import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import * as Auth from '../actions/auth';
import * as fromAuth from '../reducers';
import {ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Store} from '@ngrx/store';

/**
 * @author Hugo Andrés Escobar Ciceri
 * @version 2.1.0
 *
 * Guard Service to protect the components access.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private store: Store<fromAuth.State>) {}

  /**
   * @returns Observale {boolean} True if is authorized.
   *
   * Function to check if the user is logged and can access a component.
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(fromAuth.getLoggedIn).pipe(
      map((auth: boolean) => auth),
      tap((auth: boolean) => {
        if (!auth) {
          this.store.dispatch(new Auth.LoginRedirect());
        }
      })
    );
  }

  canLoad(
    route: Route
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(fromAuth.getLoggedIn).pipe(
      map((auth: boolean) => auth),
      tap((auth: boolean) => {
        if (!auth) {
          this.store.dispatch(new Auth.LoginRedirect());
        }
      })
    );
  }
}
