import { Injectable } from '@angular/core';
import { KanziRestClientService } from '@kanzi-apes/kanzi-rest-api';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';
import { Observable } from 'rxjs';
import { PropertiesModels } from './models';

@Injectable({
  providedIn: 'root',
})
export class TablePropertiesService {
  private urlBase = this.envService.environment
    ? this.envService.environment.kongApi +
      this.envService.environment?.apiModules.inventory
    : '';

  private path = this.envService.environment
    ? this.envService.environment.kongApi +
      this.envService.environment?.apiModules.inventory +
      'properties/'
    : '';

  constructor(
    private restClient: KanziRestClientService,
    private envService: EnvironmentService
  ) {}

  public getProperties(
    filters: PropertiesModels.Filters | null
  ): Observable<PropertiesModels.List> {
    return this.restClient.get(
      this.path,
      filters,
      'Error al listar las propiedades.'
    );
  }

  public createProperties(
    body: PropertiesModels.Create
  ): Observable<PropertiesModels.Properties> {
    return this.restClient.post(
      this.path,
      body,
      'Error al crear la propiedad.'
    );
  }

  public getValueProps(key: string): Observable<PropertiesModels.PropsValue> {
    const url = `${this.urlBase}summary-unique-key-value-properties/?key=${key}`;
    return this.restClient.get(
      url,
      null,
      'Error al obtener el valor de las propiedades.'
    );
  }
}
