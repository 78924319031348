import { SkuModel } from '../products';
import { FiltersModel } from '../filters';
import { DataListModel } from '../data';

export interface SorterItemsFilterModel extends FiltersModel {
  customer_id?: number;
  criteria?: string;
  items: string[];
}

export interface SorterItemsResultsModel extends DataListModel {
  results: SorterItemResultModel[];
}

export interface SorterItemResultModel {
  sku: SkuModel;
  sku_id?: number;
  sku_display_name?: string;
  sku_external_id?: string;
  items: string[];
  amount?: number;
}

export interface ItemReadingModel {
  readings: ReadingModel[];
  action?: string;
  node_id?: string;
  timestamp?: number;
}

export interface ReadingModel {
  antenna?: number;
  epc: string;
  user_memory?: string;
  tid?: string;
  timestamp?: number;
}

export interface ItemsFilterModel extends FiltersModel {
  id?: string;
  sku_id?: string;
  state?: string;
}

export interface ItemTypesModel {
  readonly id: number;
  readonly external_id: string;
  readonly name: string;
  readonly description: string;
  readonly display_name: string;
}

export interface ItemTypesListModel extends DataListModel {
  readonly results: ItemTypesModel[];
}
