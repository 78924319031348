import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { AlertConfirmService } from './alert-confirm.service';

/**
 * @author Hugo Andres Escobar Ciceri
 * @version 2.1.0
 *
 * Alert Component to confirm an action.
 */
@Component({
  selector: 'app-alert-confirm',
  templateUrl: './alert-confirm.component.html',
  styleUrls: ['./alert-confirm.component.scss'],
  providers: [ConfirmationService],
})
export class AlertConfirmComponent implements OnInit {
  @Output() confirmation = new EventEmitter<boolean>();
  message: any;
  // isVisible: string;
  // title: string;

  /**
   *
   * @param alertConfirmService {AlertConfirmService} Service to send and receive messages to confirm
   */
  constructor(
    private alertConfirmService: AlertConfirmService,
    private confirmationService: ConfirmationService
  ) {
    // this.isVisible = 'none';
    // this.title = 'Confirmación';
  }

  ngOnInit() {
    this.alertConfirmService.getMessageConfirm().subscribe((message: any) => {
      if (message && message.text) {
        this.message = message;
        this.confirmationService.confirm({
          message: message ? message.text : '',
          header: 'Confirmación',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.message = '';
            this.confirmation.emit(true);
          },
          reject: () => {
            this.message = '';
            this.confirmation.emit(false);
          },
        });
      }
    });
  }

  /**
   * Function to emit the negative option
   */
  // noOption() {
  //   this.message = '';
  //   this.isVisible = 'none';
  //   this.confirmation.emit(false);
  // }

  /**
   * Function to emit the affirmative option
   */
  // yesOption() {
  //   this.message = '';
  //   this.isVisible = 'none';
  //   this.confirmation.emit(true);
  // }
}
