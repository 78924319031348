/* eslint-disable @angular-eslint/component-selector */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  AddComponent,
  ConfigTableModel,
  CustomerModel,
  FilterTableModel,
} from '@kanzi-apes/kanzi-models';
import * as FileSaver from 'file-saver';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import { LazyLoadEvent } from 'primeng/api';
import { TableLazyLoadEvent } from 'primeng/table';

/**
 * @author Hugo Andrés Escobar Ciceri
 * @version 5.9.0
 *
 * Component to create a configurable table.
 */
@Component({
  selector: 'kanzi-dynamic-simple-table',
  templateUrl: './kanzi-dynamic-simple-table.component.html',
  styleUrls: ['./kanzi-dynamic-simple-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KanziDynamicSimpleTableComponent implements  OnChanges {
  @Input() customer: CustomerModel | null;
  @Input() dataSource: any[] | null;
  @Input() colSource: any[];
  @Input() configs: ConfigTableModel | null;
  @Input() pending: boolean | null;
  @Input() dynamicComponent: AddComponent | null;
  @Output() paginationEvent = new EventEmitter<FilterTableModel>();
  @Output() selectEvent = new EventEmitter<any>();
  @Output() editEvent = new EventEmitter<any>();
  @Output() deleteEvent = new EventEmitter<any>();
  @Output() filterEvent = new EventEmitter<FilterTableModel>();
  @Output() clearEvent = new EventEmitter<boolean>();
  @Output() expandRowEvent = new EventEmitter<any>();
  @Output() multiselectItemsEvent = new EventEmitter<any[]>();

  emptyMsg = 'Registros no encontrados.';
  filters: FilterTableModel | null = null;
  showFilter = false;
  filteredTotals = 0;
  selectedItems: any[] = [];
  exportColumns: any[] = [];
  exportExcelColumns: string[] = [];

  constructor() {
    this.customer = null;
    this.dataSource = [];
    this.colSource = [];
    this.configs = null;
    this.pending = false;
    this.dynamicComponent = null;
    this.exportColumns = this.colSource.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    this.exportExcelColumns = [];
    this.exportExcelColumns = this.colSource.map((col) => col.field);
  }


  /**
   * Lifecycle hook that is invoked when data-bound properties of a component change.
   * Handles changes to the dataSource property by converting it to a new array if it exists and has changed.
   * @param {SimpleChanges} changes - An object containing each changed property.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSource'] && this.dataSource) {
      // Convert dataSource to a new array to trigger change detection
      this.dataSource = ([] as any[]).concat(this.dataSource);
    }
  }

  /**
   * Getter method for retrieving selected items.
   * @returns {any[]} An array containing the selected items.
   */
  get itemsSelected(): any[] {
    return this.selectedItems;
  }

  /**
   * Setter method for updating selected items and emitting a multiselect items event.
   * @param {any[]} items - An array of items to set as selected.
   */
  set itemsSelected(items: any[]) {
    // Update selectedItems with the new items
    this.selectedItems = items;
    // Emit a multiselect items event with the updated selected items
    this.multiselectItemsEvent.emit(items);
  }

  /**
   * Loads data based on lazy loading event parameters.
   * Emits a pagination event with filtering and sorting parameters to fetch data asynchronously.
   * @param {LazyLoadEvent} event - The lazy load event containing pagination and filtering/sorting details.
   */
  loadLazy(event: LazyLoadEvent | TableLazyLoadEvent) {
    if (event && event.first && event.rows) {
      // Calculate current page based on lazy load event parameters
      const pag = event.first / event.rows + 1;
      // Construct filter object with pagination, search, and sorting parameter
      const filter: FilterTableModel = {
        page: pag,
        page_size: this.configs?.rows,
        search: event.globalFilter === null ? '' : event.globalFilter,
        sort_field: event.sortField as string,
        sort_order: event.sortOrder as number,
      };
      // Emit pagination event with constructed filter to load data asynchronously
      this.paginationEvent.emit(filter);
    }
  }

  /**
   *
   * @param el {any}
   *
   * Function to emit select item action with item data.
   */
  onRowSelect(el: any) {
    this.selectEvent.emit(el);
  }

  /**
   *
   * @param el {any}
   *
   * Function to emit delete action with the item data.
   */
  onRowEdit(el: any) {
    this.editEvent.emit(el);
  }

  /**
   *
   * @param el {any}
   *
   * Function to emit delete action with the item data.
   */
  onRowDelete(el: any) {
    this.deleteEvent.emit(el);
  }

  /**
   * Handles date filtering for a data table by setting date range filters and emitting a filter event.
   * If both start and end dates are provided, updates the filters accordingly and emits a filter event.
   * @param {string[]} dates - An array of two strings representing start and end dates in ISO format (YYYY-MM-DD).
   */
  onFilterDateTable(dates: string[]) {
    if (dates[1]) {
      // Format start date and end date to 'MM/DD/YYYY' format using moment.js
      const initDate = moment(dates[0]).format('MM/DD/YYYY');
      const endDate = moment(dates[1]).format('MM/DD/YYYY');

      // Update filters object with date range and other default parameters
      this.filters = {
        ...this.filters,
        page: 1,
        page_size: 200,
        created_min: initDate,
        created_max: endDate,
      };

      // Emit filter event with updated filters to trigger data filtering
      this.filterEvent.emit(this.filters);
    }
  }

  /**
   * Clears filters based on a given condition and emits a clear event if specified.
   * @param {boolean} check - A boolean value indicating whether to clear filters.
   * If `true`, hides the filter display and emits a clear event.
   */
  clearFilters(check: boolean) {
    if (check) {
      // Hide the filter display if check is true
      this.showFilter = false;
      // Emit clear event with the specified check value
      this.clearEvent.emit(check);
    }
  }

  /**
   * Toggles the visibility state of filters.
   * Updates the visibility state (`showFilter`) to toggle between true and false.
   */
  showFilters() {
    // Toggle the visibility state of filters
    this.showFilter = this.showFilter ? false : true;
  }

  /**
   * Updates filtered totals based on provided filters.
   * Sets the count of filtered items (`filteredTotals`) based on the length of the filtered value array.
   * @param {any} filters - The filters object containing filtered values.
   */
  onFilter(filters: any) {
    if (filters) {
      // Update filteredTotals with the length of the filteredValue array
      this.filteredTotals = filters.filteredValue.length;
    }
  }

  /**
   * Handles row expansion event by emitting the expanded row data.
   * Emits an expand row event (`expandRowEvent`) with the data of the expanded row.
   * @param {any} event - The event object containing data related to row expansion.
   */
  onRowExpand(event: any) {
    // Emit expand row event with the data of the expanded row
    this.expandRowEvent.emit(event.data);
  }

  /**
   * Export data to Excel format using the xlsx library.
   * Generates an Excel file based on the current dataSource and specified export columns.
   * @remarks This function asynchronously imports the xlsx library and processes data for export.
   */
  exportExcel() {
    import('xlsx').then((xlsx) => {
      // Map dataSource items to rows containing exportExcelColumns data
      const rows = this.dataSource?.map((item) => {
        const objAux = {};
        // Filter and assign columns to objAux for each item
        this.exportExcelColumns.forEach((column, i) => {
          if (item[column]) {
            Object.assign(objAux, { [column]: item[column] });
          }
        });
        return objAux;
      });
      // If rows are generated
      if (rows) {
        // Convert rows to a worksheet using xlsx library
        const worksheet = xlsx.utils.json_to_sheet(rows, {
          cellDates: true,
          header: this.exportExcelColumns,
        });
        // Create a workbook containing the generated worksheet
        const workbook = {
          Sheets: { data: worksheet },
          SheetNames: ['data'],
        };
        // Write the workbook to an Excel file buffer
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
        // Save the Excel file buffer as a downloadable file
        this.saveAsExcelFile(
          excelBuffer,
          this.configs
            ? this.configs.fileName
              ? this.configs.fileName
              : 'kanzi-report'
            : 'kanzi-report'
        );
      }
    });
  }

  /**
   * Saves a provided Excel buffer as a downloadable file with a specified filename.
   * @param {any} buffer - The Excel file buffer to be saved as a Blob.
   * @param {string} fileName - The desired filename for the saved Excel file (without extension).
   */
  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    // Create a Blob containing the Excel buffer
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    // Generate a unique filename for the Excel file based on current timestamp
    const timestamp = new Date().getTime();
    const excelFileName = `${fileName}_export_${timestamp}${EXCEL_EXTENSION}`;
    // Save the Blob as a downloadable file using FileSaver.js
    FileSaver.saveAs(data, excelFileName);
  }

  /**
   * Export data to PDF format using jsPDF and autoTable libraries.
   * Generates a PDF document with tabular data based on specified export columns and dataSource.
   * @remarks This function asynchronously imports jsPDF and autoTable libraries for PDF generation.
   */
  exportPdf() {
    import('jspdf').then((jsPDF) => {
      // Create a new jsPDF document in landscape orientation
      const doc = new jsPDF.default('landscape');
      // Generate a table using autoTable with specified columns and dataSource
      autoTable(doc, {
        columns: this.exportColumns,
        body: this.dataSource ? this.dataSource : [],
        tableWidth: 'auto',
        pageBreak: 'auto',
        margin: 10,
      });
      // Save the PDF document with the specified filename (or default filename)
      doc.save(this.configs ? this.configs.fileName : 'kanzi-report');
    });
  }
}
