import { Injectable } from '@angular/core';
import { KanziRestClientService } from '../rest-client/kanzi-rest-client.service';
import { Observable, of } from 'rxjs';
import { KanziStatus, StatusApiMsgModel } from '@kanzi-apes/kanzi-models';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class KanziMonitoringHandlerService {
  constructor(private restClient: KanziRestClientService) {}

  getApiStatus(apiUrl: string): Observable<StatusApiMsgModel | null> {
    return this.restClient.status(apiUrl).pipe(
      map((response: number) => this.msgStatus(response)),
      catchError((response) => of(this.msgStatus(response)))
    );
  }

  msgStatus(status: number): StatusApiMsgModel | null {
    if (status >= 200 && status < 400) {
      return {
        title: 'En Linea',
        type: KanziStatus.SUCCESS,
        msg: 'Servicio Activo',
      };
    } else {
      switch (status) {
        case 400:
          return {
            title: 'Problema de Comunicación',
            type: KanziStatus.WARN,
            msg: 'Petición Erronea',
          };
        case 401:
          return {
            title: 'Problema de Comunicación',
            type: KanziStatus.WARN,
            msg: 'Problemas de Autenticación',
          };
        case 403:
          return {
            title: 'Problema de Comunicación',
            type: KanziStatus.WARN,
            msg: 'Problemas de Permisos',
          };
        case 404:
          return {
            title: 'Problema de Comunicación',
            type: KanziStatus.WARN,
            msg: 'Problemas de Módulos',
          };
        case 500:
          return {
            title: 'Servicio Fuera de Linea',
            type: KanziStatus.ERROR,
            msg: 'API No Disponible',
          };
        case 502:
          return {
            title: 'Servicio Fuera de Linea',
            type: KanziStatus.ERROR,
            msg: 'API Presenta Errores',
          };
        case 503:
          return {
            title: 'Servicio Fuera de Linea',
            type: KanziStatus.ERROR,
            msg: 'API Saturado',
          };
        case 504:
          return {
            title: 'Servicio Fuera de Linea',
            type: KanziStatus.ERROR,
            msg: 'API Sin Respuesta ó Problemas de Red',
          };
        default:
          return {
            title: 'Servicio Fuera de Linea',
            type: KanziStatus.ERROR,
            msg: 'API Sin Respuesta ó Problemas de Red',
          };
      }
    }
  }
}
