import { Injectable } from '@angular/core';
import { KanziRestClientService } from '@kanzi-apes/kanzi-rest-api';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';
import { Observable } from 'rxjs';
import { ItemTypesListModel } from './models';
import { FiltersModel } from '@kanzi-apes/kanzi-models';

@Injectable({
  providedIn: 'root',
})
export class ItemTypesService {
  private path = this.envService.environment
    ? this.envService.environment.kongApi +
      this.envService.environment?.apiModules.inventory +
      'item-types/'
    : '';

  constructor(
    private restClient: KanziRestClientService,
    private envService: EnvironmentService
  ) {}

  public getItemTypes(filters: FiltersModel | null): Observable<ItemTypesListModel> {
    return this.restClient.get(
      this.path,
      filters,
      'Error al consultar los tipos de items.'
    );
  }
}
