export interface ColumnsModel {
  field: string;
  header: string;
}

export interface ConfigDownloadDataModel {
  path: string;
  count:number;
  filters: { [key: string]: any } | null; 
}
