import { Component, OnInit, Input } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: 'kanzi-menu-settings',
  templateUrl: './menu-settings.component.html',
  styleUrls: ['./menu-settings.component.css'],
  animations: [
    trigger('collapseSettings', [
      state(
        'open',
        style({
          opacity: '1',
          display: 'block',
          transform: 'translate3d(0, 0, 0)',
          right: 0,
        })
      ),
      state(
        'closed',
        style({
          opacity: '0',
          display: 'none',
          transform: 'translate3d(0, -100%, 0)',
          right: -230,
        })
      ),
      transition('closed => open', animate('200ms ease-in')),
      transition('open => closed', animate('100ms ease-out')),
    ]),
  ],
})
export class MenuSettingsComponent implements OnInit {
  @Input() event: string;

  constructor() {
    this.event = '';
  }

  ngOnInit() {}
}
