import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserListModel, UserFilterModel } from '@kanzi-apes/kanzi-models';
import { KanziRestClientService } from '@kanzi-apes/kanzi-rest-api';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';

@Injectable({
  providedIn: 'root',
})
export class UserListService {
  private userUrl: string = '';

  constructor(
    private restClient: KanziRestClientService,
    private envService: EnvironmentService
  ) {
    if (this.envService.environment) {
      this.userUrl =
        this.envService.environment.kongApi +
        this.envService.environment.apiModules.config +
        'users/';
    }
  }

  getUsersList(filter: UserFilterModel): Observable<UserListModel> {
    return this.restClient.get<UserListModel>(
      this.userUrl,
      filter,
      `Error al obtener los usuarios.`
    );
  }

  getUsersListSummary(filter: UserFilterModel): Observable<UserListModel> {
    const url = `${this.userUrl}summary/`;
    return this.restClient.get<UserListModel>(
      url,
      filter,
      `Error al obtener los usuarios.`
    );
  }
}
