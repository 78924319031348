import { createAction, props } from '@ngrx/store';
import {
  WarehouseModel,
  WarehouseListModel,
  LocationsWMSFilterModel,
} from '@kanzi-apes/kanzi-models';
export const initAuth = createAction('[Locations Page] Init');

export const loadWarehouseRootList = createAction(
  '[Locations/API] Load Warehouse Root List',
  props<{ filters: LocationsWMSFilterModel }>()
);

export const loadWarehouseRootListSuccess = createAction(
  '[Locations/API] Load Warehouse Root List Success',
  props<{ listItems: WarehouseListModel }>()
);

export const loadWarehouseRootListFailure = createAction(
  '[Locations/API] Load Warehouse Root List Failure',
  props<{ error: any }>()
);

export const selectWarehouseRoot = createAction(
  '[Locations Page] Select Warehouse Root',
  props<{ warehouseRoot: WarehouseModel | null }>()
);

export const warehouseRootIdSelected = createAction(
  '[Locations Page] Warehouse Root Id Selected',
  props<{ warehouseRootId: string }>()
);
