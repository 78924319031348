import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuestionBase } from '@kanzi-apes/kanzi-models';

@Component({
  selector: 'kanzi-dynamic-question',
  templateUrl: './kanzi-dynamic-question.component.html',
  styleUrls: ['./kanzi-dynamic-question.component.css'],
})
export class KanziDynamicQuestionComponent implements OnChanges {
  @Input() question: QuestionBase<any> | null;
  @Input() form: UntypedFormGroup | null;
  @Output() change = new EventEmitter<any>();
  constructor() {
    this.question = null;
    this.form = null;
  }

  get isValid() {
    if (this.question) {
      return this.form?.controls[this.question?.key].valid;
    } else {
      return false;
    }
  }

  ngOnChanges(): void {}

  changes(question: QuestionBase<any> | null) {
    if (question) {
      this.change.emit({
        control: this.form?.controls[question.key],
        question: question,
      });
    }
  }
}
