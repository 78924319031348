type WordsOptions = {
  [key: string]: string;
};

type WordsStatus = {
  [key: string]:
    | 'success'
    | 'secondary'
    | 'info'
    | 'warning'
    | 'danger'
    | 'contrast'
    | 'primary';
};

export const words: WordsOptions = {
  ABORTED: 'Abortado',
  ADJUSTED: 'Ajustada',
  CALCULATED: 'Calculado',
  CANCELED: 'Cancelada',
  CC: 'Cédula Ciudadanía',
  CELL_PROVIDER: 'Celda Productiva',
  CHECK: 'Chequeo',
  CLIENT: 'Cliente',
  CLOSED: 'Cerrado',
  COMPLETE: 'Completo',
  COMPLETED: 'Completado',
  CORRECTIVE: 'Correctivo',
  CREATED: 'Creada',
  DISPATCH: 'Despachado',
  DISPATCHED: 'Despachada',
  DISTRIBUTED: 'Distribuida',
  DRAFT: 'Borrador',
  ECOMMERCE: 'ECOMMERCE',
  ENLISTED: 'Alistado',
  EXPIRED: 'Expirado',
  FAILED: 'Error',
  false: '',
  FALSE: '',
  FINISHED: 'Finalizada',
  HIGH: 'Alta',
  ISSUED: 'Emitido',
  INCOMPLETE: 'Incompleto',
  INVALID: 'Inválido',
  IN_QUALITY: 'En Calidad',
  IN_PROCESS: 'En Proceso',
  IN_PROGRESS: 'En Proceso',
  IN_TRANSFER: 'En Transferencia',
  BUDGET_PENDING: 'cotización',
  NIT: 'NIT',
  ON_ENLISTMENT: 'En alistamiento',
  ON_DELIVERY_TASK: 'En tarea de entrega',
  OTHER: 'Otro',
  OVERLOADED: 'Sobrecargada',
  PASSPORT: 'Pasaporte',
  PRESENT: 'Presente',
  NOT_PRESENT: 'No Presente',
  PREVENTIVE: 'Preventivo',
  PRINTED: 'Impreso',
  priority: 'Prioridad',
  PROCESSING: 'Procesando',
  PROVIDER: 'Proveedor',
  READING: 'Leyendo',
  RETURN: 'Devolución',
  SALE_OUT: 'Ventas',
  SELLER: 'Vendedor',
  SIMPLE_ENTRY: 'Entrada Simple',
  SIMPLE_OUTPUT: 'Salida Simple',
  SOLVED: 'Resuelto',
  TAKEOUT: 'Descarga Inventario',
  TI: 'T.I',
  TRANSFER: 'Transferencia',
  true: '',
  TRUE: '',
  VERIFIED: 'Verificada',
  WAREHOUSE: 'WAREHOUSE',
  UNSOLVED: 'No Resuleto',
};

export const severityStatusName: WordsStatus = {
  ACTIVE: 'success',
  INACTIVE: 'danger',
  AVAILABLE: 'success',
  PENDING_CONFIGS: 'warning',
  UNAVAILABLE: 'danger',
  CONFIRMED: 'success',
  FORCE_CHANGE_PASSWORD: 'warning',
  COMPLETED: 'success',
  ABORTED: 'danger',
  ADJUSTED: 'info',
  CALCULATED: 'contrast',
  CANCELLED: 'danger',
  CLOSED: 'warning',
  CREATED: 'info',
  DISPATCHED: 'warning',
  DRAFT: 'secondary',
  DISTRIBUTED: 'info',
  ENLISTED: 'contrast',
  ERROR: 'danger',
  EXPIRED: 'danger',
  FAILED: 'danger',
  FINISHED: 'success',
  INCOMPLETE: 'warning',
  INFO: 'info',
  INVALID: 'warning',
  IN_PROCESS: 'warning',
  IN_PROGRESS: 'warning',
  BUDGET_PENDING: 'warning',
  ISSUED: 'warning',
  ONLINE: 'success',
  OFFLINE: 'danger',
  PACKED: 'secondary',
  PRINTED: 'info',
  PROCESSING: 'info',
  SALE: 'warning',
  SHEDULED: 'info',
  SUCCESS: 'success',
  VERIFIED: 'success',
  WARN: 'warning',
  UNSOLVED: 'danger',
  SOLVED: 'success',
  PRESENT: 'success',
  NOT_PRESENT: 'danger',
  DEMORADO: 'danger',
  'A TIEMPO': 'success',
  REJECTED: 'danger',
  APPROVED: 'success',
  BY_ASSIGN: 'info',
  MAINTENANCE_PENDING: 'contrast',
  WARNING: 'warning',
  BLOCKED: 'danger',
  ON_ENLISTMENT: 'warning',
  ON_DELIVERY_TASK: 'primary',
  COMPLETE: 'success',  
};
