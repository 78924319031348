<p-dialog
  [(visible)]="openDialogModal"
  [style]="{ width: '30vw' }"
  [header]="title"
  [modal]="true"
  [draggable]="false"
  class="p-fluid"
  (onHide)="oncloseModal()"
>
  <ng-template pTemplate="content">
    <form
      class="form-horizontal"
      [formGroup]="userAssignForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="field">
        <label htmlFor="userAssigned">{{ 'Responsable' | translate }}</label>
        <p-autoComplete
          formControlName="userAssigned"
          [suggestions]="filteredUser"
          (completeMethod)="filterUserList($event)"
          [minLength]="1"
          placeholder="{{ 'Buscar' | translate }}: "
          name="userAssigned"
          [dropdown]="true"
          field="username"
          dataKey="id"
          appendTo="body"
        >
        </p-autoComplete>
        <small
          class="ng-dirty ng-invalid"
          [hidden]="userAssigned?.valid || userAssigned?.pristine"
          >{{ 'Campo Requerido' | translate }}</small
        >
      </div>
      <div class="p-dialog-footer">
        <button
          pButton
          pRipple
          label="{{ 'Cancelar' | translate }}"
          icon="pi pi-times"
          class="p-button-text"
          type="reset"
        ></button>
        <button
          pButton
          pRipple
          label="{{ 'Guardar' | translate }}"
          icon="pi pi-check"
          class="p-button-text"
          [disabled]="!userAssignForm.valid"
          type="submit"
        ></button>
      </div>
    </form>
  </ng-template>
</p-dialog>
