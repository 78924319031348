<p-dialog
  [(visible)]="displayMessage"
  [style]="{ minWidth: '30vw' }"
  [baseZIndex]="10000"
  [modal]="true"
>
  <p-header>
    {{ title }}
  </p-header>
  <span [innerHTML]="message?.text | translate "></span>
  <p-footer>
    <button
      *ngIf="message && message.link"
      type="button"
      pButton
      icon="pi pi-check"
      (click)="goDetail()"
      class="ui-button-raised"
      label="{{ 'Ver Detalle' | translate }}"
    ></button>
    <button
      type="button"
      pButton
      icon="pi pi-times"
      (click)="closeModal()"
      [ngClass]="{
        'ui-button-raised ui-button-danger': message && message.type == 'error',
        'ui-button-raised ui-button-success':
          message && message.type == 'success',
        'ui-button-raised ui-button-warning':
          message && message.type == 'warning',
        'ui-button-raised': !message
      }"
      label="{{ 'Cerrar' | translate }}"
    ></button>
  </p-footer>
</p-dialog>
