import { Injectable } from '@angular/core';
import { KanziRestClientService } from '@kanzi-apes/kanzi-rest-api';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';

@Injectable({
  providedIn: 'root',
})
export class DynamicTableService {
  private urlApi = this.envService.environment?.kongApi;

  constructor(
    private apiClientService: KanziRestClientService,
    private envService: EnvironmentService
  ) {}

  getAllData(path: string,packet:string, filters: any, next: string | null = null) {
    let url = `${this.urlApi}${path}`;
    if(next){
      url = next;
      filters = null;
    }

    return this.apiClientService.downLoadData(
      url,
      packet,
      filters,
      'Error descargando los datos.'
    );
  }
}
