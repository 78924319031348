import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KanziAuthModule } from '@kanzi-apes/kanzi-auth';
import { KanziUiModule } from '@kanzi-apes/kanzi-ui';
import { KanziPrimeUiModule } from '@kanzi-apes/kanzi-prime-ui';
import { KanziAlertsModule } from '@kanzi-apes/kanzi-alerts';
import { KanziCustomerModule } from '@kanzi-apes/kanzi-customer';
import { TranslateModule } from '@ngx-translate/core';
import { CoreZenderboxRoutingModule } from './core-routing.module';
import { LayoutZenderboxComponent } from './pages/layout/layout.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { KanziDiamondUiModule } from '@kanzi-apes/kanzi-diamond-ui';
import { JoyrideModule } from 'ngx-joyride';

/**
 *
 * Module manage the main layout page for Zenderbox with the routes
 */
@NgModule({
  declarations: [LayoutZenderboxComponent],
  imports: [
    CommonModule,
    CoreZenderboxRoutingModule,
    ProgressSpinnerModule,
    TranslateModule.forChild(),
    KanziAlertsModule,
    KanziAuthModule,
    KanziUiModule,
    KanziPrimeUiModule,
    KanziCustomerModule,
    KanziDiamondUiModule,
    JoyrideModule.forRoot(),
  ],
})
export class CoreZenderboxModule {}
