import { HardwareModel, NodeActivityModel } from './hardware';
import { DataListModel } from '../data';

export interface HardwareListModel extends DataListModel {
  count: number;
  next: string;
  previous: string;
  results: HardwareModel[];
}

export interface NodeActivityListModel extends DataListModel {
  count: number;
  next: string;
  previous: string;
  results: NodeActivityModel[];
}
