import { SkuModel } from '../../interfaces';
export class BookingLine {
  sku!: SkuModel;
  sku_id!: number | string;
  total_present: number;
  total_reserved: number;
  total_difference: number;
  amount: number;

  constructor() {
    this.total_present = 0;
    this.total_reserved = 0;
    this.total_difference = 0;
    this.amount = 0;
  }
}
