import { CustomerModel } from '../customer';
import { DataModel, DataListModel } from '../data';
import { FiltersModel } from '../filters';
import { UserModel } from '../user';

export interface MaintenanceTypeListModel extends DataListModel {
  results: MaintenanceTypeModel[];
}

export interface MaintenanceTypeModel extends DataModel {
  id: number;
  customer?: CustomerModel;
  name: string;
  created_name?: string;
  model_id?: string;
  field_group?: string;
  type?: string;
  properties?: any;
  external_id?: string;
}

export interface MaintenanceTypeCreateModel {
  name: string;
  type: string;
  customer_id?: number;
  external_id?: string;
  model_id?: string;
  field_group?: string;
  properties?: any;
}

export interface MaintenanceTypeEditModel {
  id: number;
  data: MaintenanceTypeCreateModel;
}

export interface MaintenanceTypeFilterModel extends FiltersModel {
  name?: string;
  model_id?: string;
  field_group?: string;
  type?: string;
}

export interface MaintenanceClassificationListModel extends DataListModel {
  results: MaintenanceClassificationModel[];
}

export interface MaintenanceClassificationModel extends DataModel {
  id: number;
  customer?: CustomerModel;
  name: string;
  model_id?: string;
  field_group?: string;
  type?: string;
  properties?: any;
  external_id?: string;
  created_name?: string;
}

export interface MaintenanceClassificationCreateModel {
  name: string;
  type?: string;
  customer_id?: number;
  external_id?: string;
  model_id: string;
  field_group?: string;
  properties?: any;
}

export interface MaintenanceClassificationEditModel {
  id: number;
  data: MaintenanceClassificationCreateModel;
}

export interface MaintenanceClassificationFilterModel extends FiltersModel {
  name?: string;
  model_id?: string;
  field_group?: string;
  type?: string;
}

export interface MaintenanceGroupListModel extends DataListModel {
  results: MaintenanceGroupModel[];
}

export interface MaintenanceGroupModel extends DataModel {
  id: number;
  customer?: CustomerModel;
  name: string;
  created_name?: string;
  model_id?: string;
  field_group?: string;
  type?: string;
  properties?: any;
  external_id?: string;
}

export interface MaintenanceGroupCreateModel {
  name: string;
  type?: string;
  customer_id?: number;
  external_id?: string;
  model_id: string;
  field_group?: string;
  properties?: any;
}

export interface MaintenanceGroupEditModel {
  id: number;
  data: MaintenanceGroupCreateModel;
}

export interface MaintenanceGroupFilterModel extends FiltersModel {
  name?: string;
  model_id?: string;
  field_group?: string;
  type?: string;
}
