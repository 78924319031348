export class FilterOptions {
  cols: any[];
  constructor(cols: any[]) {
    this.cols = cols;
  }
  createFilterOptions(data: any[], field: string[]) {
    field.forEach((elem) => {
      const groupType = this.dataGroupBy(data, elem);
      this.cols = this.cols.map((item) => {
        if (item.field === elem) {
          return (item = {
            ...item,
            filterOptions: this.filtersLabels(Object.keys(groupType)),
          });
        } else {
          return item;
        }
      });
    });
  }

  filtersLabels(data: any[]): any[] {
    return (data = data.map((item) => {
      return (item = {
        label: item,
        value: isNaN(item) ? item : +item,
      });
    }));
  }

  dataGroupBy(results: any[], key: string): any[] {
    return results.reduce(function (state, value) {
      (state[value[key]] = state[value[key]] || []).push(value);
      return state;
    }, {});
  }
}
