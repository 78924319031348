import { SkuModel } from '../products';
import { UserModel } from '../user';
import { DistributionModel } from '../distribution-order';
import { StoreOrderModel } from '../store-order';
import { LocationModel } from '../location';
import { FiltersModel } from '../filters';
import { DataModel, DataListModel } from '../data';
import { ItemTypesModel } from '../items';

export interface WorkOrderCloseMultipleModel {
  order_ids: number[] | string[];
}

export interface WorkOrderGroupModel {
  assigned_to_id: number;
  distribution_ids: number[];
  customer_id?: number;
  item_type_id?:number | string;
}

export interface WorkOrderGroupFormModel {
  assigned_to: UserModel;
  orders_ids: string[];
  fromListSelected: boolean;
  item_type_id?:number | string;
}
export interface WorkOrderFilterModel extends FiltersModel {
  distribution_id?: number;
  status?: string;
  search?: string;
  assigned_to_id?: number;
}

export interface WorkOrderLinesFilterModel extends FiltersModel {
  id?: number;
  page?: number;
  search?: string;
  distribution_id?: number;
  page_size?: number;
  work_order_id?: number;
  work_order__assigned_to_id?: number;
}

export interface WorkOrderCreateModel {
  assigned_to_id: number;
  lines?: WorkOrderLineModel[];
  destination_id?: number | string | null;
  source_id?: number;
  distribution_id?: number | string | null;
  customer_id?: number;
  external_id?: string;
  type?: string;
  item_type_id?: number;
}

export interface assingsToWork {
  id: number;
  assigned_to_id: number;
}
export interface WorkOrderModel extends DataModel {
  id: number;
  assigned_to_id: number;
  assigned_to_name?: string;
  assigned_to: UserModel;
  external_id: string;
  finished_date: Date;
  finished: boolean;
  lines: WorkOrderLineModel[];
  destination_id: number | string;
  destination_name?: string;
  source_id: number;
  source_name?: string;
  source: LocationModel;
  destination: LocationModel;
  distribution_id: number | string;
  distribution: DistributionModel;
  total_amount: number;
  packed_amount: number;
  status: string;
  finished_date_timestamp: number;
  modified_by_name?: string;
  store_order: StoreOrderModel;
  store_order_external_id?: string;
  total_quantities?: number;
  packed_quantities?: number;
  type?: string;
  customer?: any;
  item_type?: ItemTypesModel;
  item_type_name?: string;
}

export interface WorkOrderListModel extends DataListModel {
  results: WorkOrderModel[];
}

export interface WorkOrderLineFormCreateModel {
  sku_id: string | number;
  amount: number;
}

export interface WorkOrderLineCreateModel {
  sku: SkuModel | null;
  sku_id: number | null;
  sku_external_id: string | null;
  sku_display_name: string | null;
  amount: number;
  quantity?: number;
  in_work_order_amount: number;
  in_work_order_quantities?: number;
  packed_amount: number;
  packed_quantities?: number;
  balance: number;
  total_amount: number;
}
export interface WorkOrderLineModel {
  display_name?: string;
  sku?: SkuModel;
  sku_id?: number | string;
  sku_display_name?: string;
  sku_external_id?: string;
  amount?: number;
  packed_amount?: number;
  total_amount?: number;
  in_work_order_amount?: number;
  balance?: number;
  work_order_id?: number;
  work_order?: WorkOrderModel;
  status?: string;
}

export interface WorkOrderLineListModel extends DataListModel {
  results: WorkOrderLineModel[];
}
