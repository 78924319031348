<p-table
  [value]="_linesList"
  [rows]="10"
  [paginator]="true"
  [totalRecords]="_linesList.length"
  [responsive]="true"
  [styleClass]="'kanzi-ui'"
  [resizableColumns]="true"
  editMode="row"
  dataKey="sku_id"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th>{{ 'Buscar' | translate }}</th>
      <th>{{ 'Nombre' | translate }}</th>
      <th>{{ 'SKU' | translate }}</th>
      <th>{{ 'External ID' | translate }}</th>
      <th>{{ 'Cantidad' | translate }}</th>
      <th *ngIf="positioning">{{ 'Posicionamiento' | translate }}</th>
      <th style="width: 8em"></th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-rowData
    let-editing="editing"
    let-ri="rowIndex"
  >
    <tr [pEditableRow]="rowData" id="rowsDatas__tr_overflow" >
      <td pEditableColumn  id="rowsDatas__td_overflow">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-autoComplete
              [(ngModel)]="rowData.display_name"
              [suggestions]="skusListFiltered"
              (completeMethod)="filterSkus($event)"
              [minLength]="1"
              placeholder="{{ 'Buscar producto' | translate }} "
              class="autoCompleteList"
              [ngModelOptions]="{ standalone: true }"
              field="display_name"
              dataKey="display_name"
              appendTo="body"
              (onSelect)="onSelectSkuItem($event, ri)"
            >
              <ng-template let-sku pTemplate="item">
                <div
                  class="ui-helper-clearfix"
                  style="border-bottom: 1px solid #d5d5d5; padding: 0 5px"
                >
                  <div class="row">
                    <div class="col-12">
                      <span>{{ sku.display_name }}</span>
                    </div>
                    <div class="col-12">
                      <span>Sku: {{ sku.id }}</span>
                    </div>
                    <div class="col-12">
                      <span>Ext. ID: {{ sku.external_id }}</span>
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-autoComplete>
          </ng-template>
          <ng-template pTemplate="output">
            {{ rowData.sku_display_name }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td>
        {{ rowData.sku_display_name }}
      </td>
      <td>{{ rowData.sku_id }}</td>
      <td>{{ rowData.sku_external_id }}</td>
      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              type="number"
              pInputText
              [(ngModel)]="rowData.amount"
              placeholder="0.0"
              min="1"
              [ngModelOptions]="{ standalone: true }"
            />
          </ng-template>
          <ng-template pTemplate="output">
            {{ rowData.amount }}
          </ng-template>
        </p-cellEditor>
      </td>

      <!-- inicio Posicionamiento -->
      <td *ngIf="positioning">
        <!-- Modificar el p-dropdown -->
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-dropdown
              [options]="rowData.locationsSuggested"
              [(ngModel)]="rowData.locationOption"
              optionLabel="display_name"
              [filter]="true"
              [showClear]="true"
              dataKey="display_name"
              appendTo="body"
              (onChange)="onSelectSkuLocation($event, ri)"
            >
            </p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            {{ rowData.locationOption?.display_name }}
          </ng-template>
        </p-cellEditor>
      </td>
      <!-- fin Posicionamiento -->

      <td style="text-align: center">
        <button
          *ngIf="!editing"
          pButton
          type="button"
          pInitEditableRow
          icon="pi pi-pencil"
          class="p-button-warning mr-2"
          (click)="onRowEditInit(rowData)"
        ></button>
        <button
          *ngIf="!editing"
          pButton
          type="button"
          icon="pi pi-trash"
          class="p-button-danger mr-2"
          (click)="onRowDelete(ri)"
        ></button>
        <button
          *ngIf="editing"
          pButton
          type="button"
          pSaveEditableRow
          icon="pi pi-check"
          class="p-button-success mr-2"
          (click)="onRowEditSave(rowData)"
        ></button>
        <button
          *ngIf="editing"
          pButton
          type="button"
          pCancelEditableRow
          icon="pi pi-times"
          class="p-button-danger"
          (click)="onRowEditCancel(rowData, ri)"
        ></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary" *ngIf="canAddLines">
    <div class="lex justify-content-start">
      <button
        icon="pi pi-plus"
        type="button"
        class="p-button-help mr-3"
        pButton
        pRipple
        (click)="addSkuList()"
      ></button>
      <button
        type="button"
        icon="pi pi-file"
        class="mr-3"
        (click)="showDialogToAdd()"
        pButton
        pRipple
        label="{{ 'Subir lista de Skus' | translate }}"
      ></button>
      <button
        type="button"
        icon="fas fa-qrcode"
        class="p-button-secondary mr-3"
        (click)="showDialogToScanCode()"
        pButton
        pRipple
        label="{{ 'Escanear Código' | translate }}"
      ></button>
    </div>
  </ng-template>
</p-table>
<p-toast></p-toast>


<!-- Dialog to Scan Code -->
<p-dialog
  header="{{ 'Escanear Código' | translate }}"
  [modal]="true"
  [(visible)]="displayDialogScan"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
>
  <div class="p-grid p-justify-center">
    <div class="p-col">
      <span class="ui-float-label">
        <input
          #barcode
          id="float-input"
          type="text"
          size="80"
          pInputText
          (blur)="
            onSkuBarcodeReading(barcode.value);
            barcode.value = '';
            barcode.focus()
          "
        />
      </span>
    </div>
  </div>
  <p-footer>
    <button
      type="button"
      pButton
      icon="pi pi-times"
      (click)="displayDialogScan = false"
      label="{{ 'Cerrar' | translate }}"
      class="ui-button-secondary"
    ></button>
  </p-footer>
</p-dialog>
