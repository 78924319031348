import { MaintenanceTypeModel } from '../../maintenance-configs';
import { skuGruopsModel } from '../../inventory';
import { LocationModel } from '../../location';
import { DataListModel, DataModel } from '../../data';
import { SkuGroupModel } from '../../products';
import { CustomerModel } from '../../customer';

/** Event Planner Models */

export interface FormEventPlannerModel {
  name:string;
  types:any[];
  description: string;
  location: LocationModel[];
  sku_groups: skuGruopsModel[];
  eventPlannerType?: MaintenanceTypeModel;
  params: any;
}

export interface EventPlanerCreateModel {
  id?: number;
  params: any;
  description: string;
  external_id?: string;
  type?: string;
  sku_group_id?: number;
  status?: string;
  customer_id?: number;
  initial_date?: Date | string;
  properties?: Object;
  sources?: string[];
  source_ids?: string[];
  type_ids?:string[];
  group_ids?:string[];
  name?:string;
}

export interface EventPlanerListModel extends DataListModel {
  results: EventPlannerModel[];
}

export interface EventPlannerModel extends DataModel {
  id: number;
  name?:string;
  customer_id?:number;
  sku_group: SkuGroupModel;
  sku_group_name?: string;
  customer: CustomerModel;
  external_id: null | string;
  description: string;
  type: string;
  status: string;
  initial_date: null | string;
  finished_date: null;
  expired: boolean;
  properties: Object;
  sources: LocationModel[];
  initial_date_timestamp: number | null;
  finished_date_timestamp: null;
  location:any[];
  sku_groups:any[];
  types:any[];
}
