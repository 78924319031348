import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/';
import { KanziControlErrorsService } from '../control-errors/kanzi-control-errors.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DataXmlFormaterService {
  constructor(
    private http: HttpClient,
    private errorService: KanziControlErrorsService
  ) {}

  getDataFromXml(url: string, separator: string = ';'): Observable<any> {
    return this.http.get(url, { responseType: 'text' }).pipe(
      map((data) => this.extractData(data, separator)),
      map((results) => ({
        count: results ? results['length'] : 0,
        results: results,
      })),
      catchError(this.errorService.handleError('Error al Leer Archivo'))
    );
  }

  private extractData(text: string, separator: string = ';'): any[] {
    const csvData = text || '';
    const allTextLines: string[] = csvData.split(/\r\n|\n/);
    allTextLines.pop();
    const headers = allTextLines ? allTextLines[0].split(separator) : []; // ;
    allTextLines.shift();
    const lines = Array.from(allTextLines, (item) => {
      const data = item.split(separator);
      return headers.reduce(function (acc: any, actual, i) {
        acc[actual] = isNaN(Number(data[i]))
          ? data[i]
          : data[i].length > 20
          ? data[i]
          : Number(data[i]);
        return acc;
      }, {});
    });
    return lines;
  }
}
