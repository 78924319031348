import { KanziColorSchema } from '@kanzi-apes/kanzi-models';
import { Action } from '@ngrx/store';

/**
 * Definition of action types for Layout Redux
 */
export enum LayoutActionTypes {
  ActionMainMenu = '[Layout] Action MainMenu',
  ActionRightMenu = '[Layout] Action Right Menu',
  OpenMainMenu = '[Layout] Open MainMenu',
  CloseMainMenu = '[Layout] Close MainMenu',
  ShowProgressBar = '[Layout] Show Progress Bar',
  HideProgressBar = '[Layout] Hide Progress Bar',
  ActivateCustomerParent = '[Layout] Customer Activate Parent',
  DeactivateCustomerParent = '[Layout] Customer Deactivate Parent',
  ColorScheme = '[Layout] Color layout',
}

export class ActionMenuMain implements Action {
  readonly type = LayoutActionTypes.ActionMainMenu;
  constructor(public payload: string) {}
}

export class ActionRightMenu implements Action {
  readonly type = LayoutActionTypes.ActionRightMenu;
  constructor(public payload: string) {}
}

export class OpenMenuMain implements Action {
  readonly type = LayoutActionTypes.OpenMainMenu;
}

export class CloseMenuMain implements Action {
  readonly type = LayoutActionTypes.CloseMainMenu;
}

export class ShowProgressBar implements Action {
  readonly type = LayoutActionTypes.ShowProgressBar;
}

export class HideProgressBar implements Action {
  readonly type = LayoutActionTypes.HideProgressBar;
}

export class ActivateCustomerParent implements Action {
  readonly type = LayoutActionTypes.ActivateCustomerParent;
}

export class DeactivateCustomerParent implements Action {
  readonly type = LayoutActionTypes.DeactivateCustomerParent;
}

export class ColorScheme implements Action {
  readonly type = LayoutActionTypes.ColorScheme;
  constructor(public payload: KanziColorSchema) {}
}

/**
 * type Class actions for Layout Actions
 */
export type LayoutActions =
  | ActionRightMenu
  | ActionMenuMain
  | OpenMenuMain
  | CloseMenuMain
  | ShowProgressBar
  | HideProgressBar
  | ActivateCustomerParent
  | DeactivateCustomerParent
  | ColorScheme;
