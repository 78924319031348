import { ContactModel } from '../contacts';
import { SkuModel } from '../products';
import { LocationModel } from '../location';
import { FiltersModel } from '../filters';
import { DataListModel, DataModel } from '../data';

export interface StoreOrderLineListModel extends DataListModel {
  results: StoreOrderLineModel[];
}

export interface StoreOrderLinesFilterModel extends FiltersModel {
  id?: number;
  page?: number;
  search?: string;
  store_order_id?: number;
  sku_id?: string;
  page_size?: number;
}

export interface StoreOrderFilterModel extends FiltersModel {
  external_id?: string;
  status?: string;
  budget_id?: number;
}

export interface StoreOrderLineFormCreateModel {
  sku_id: string | number;
  amount?: number;
  quantity?: number;
}
export interface StoreOrderLineCreateModel {
  sku?: SkuModel | null;
  sku_id: number | null;
  sku_external_id: string | null;
  sku_display_name: string | null;
  amount?: number;
  total_amount?: number;
}
export interface StoreOrderLineModel extends DataModel {
  display_name?: string;
  sku?: SkuModel;
  sku_id?: string | number;
  sku_display_name?: string;
  sku_external_id?: string;
  ean?: string;
  sourceInventory?: number;
  destinyInventory?: number;
  amount?: number;
  in_work_order_amount?: number;
  in_work_order_quantities?:number;
  in_distribution_amount?: number;
  in_distribution_quantitie?:number;
  packed_amount?: number;
  packed_quantities?:number;
  store_order?: number | StoreOrderModel;
  special_price?:number;
  normal_price?:number;
  normal_price_disc?:number;
  special_price_disc?:number;
  type_disc?:string | null;
  quantity?:number;
  increase_rate?:number;
}

export interface StoreOrderListModel extends DataListModel {
  results: StoreOrderModel[];
}
export interface StoreOrderCreateModel {
  customer_id?: number;
  source_id: number;
  requester_id?: number;
  arrival_date?: string;
  external_id: string;
  expected_date: Date;
  destination_id: number;
  lines: StoreOrderLineFormCreateModel[];
  automatic_distribution: boolean;
  automatic_reserved?:boolean;
  properties?: any;
  budget_id?: number;
  required_budget?:boolean;
  params?:any;
  status?:string;
}
export interface StoreOrderModel extends DataModel {
  source_id: number;
  arrival_date_timestamp?:number;
  expected_date_timestamp?:number;
  source_name?: string;
  source: LocationModel;
  requester: ContactModel;
  requester_name?: string;
  requester_id: number;
  arrival_date: string;
  external_id: string;
  in_distribution_amount: number;
  in_work_order_amount: number;
  packed_amount: number;
  expected_date: Date;
  destination_id: number;
  destination_name?: string;
  destination: LocationModel;
  lines: StoreOrderLineModel[];
  total_amount: number;
  status: string;
  features?:{
    consumption_skus:{
      sku_id:number
    }[];
  };
  automatic_distribution: boolean;
  properties?: any;
  modified_by_name?: string;
  params?:any;
  required_budget?:boolean;
  customer?:any;
  total_quantities?:number;
  in_distribution_quantities?:number;
  in_work_order_quantities?:number;
  packed_quantities?:number;
}


export interface StoreOrderEditModel extends DataModel {
  source_id?: number;
  sku_ids_previews?:number[];
  source_name?: string;
  source?: LocationModel;
  requester?: ContactModel;
  requester_name?: string;
  requester_id?: number;
  arrival_date?: string;
  external_id?: string;
  in_distribution_amount?: number;
  in_work_order_amount?: number;
  packed_amount?: number;
  expected_date?: string;
  destination_id?: number;
  destination_name?: string;
  destination?: LocationModel;
  lines?: StoreOrderLineModel[];
  total_amount?: number;
  status?: string;
  automatic_distribution?: boolean;
  modified_by_name?: string;
  params?:any;
  required_budget?:boolean;
  customer_id?:number;
  source_external_id?:number;
  destination_external_id?:number;
  automatic_reserved?:boolean;
}

export interface LineSkuModel {
  sku_id?: number | string;
  amount?: number;
}
