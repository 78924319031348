import { CustomerModel } from '../../customer';
import { DataListModel, DataModel } from '../../data';
import { FiltersModel } from '../../filters';
import { SkuGroupModel } from '../../products';
import { EventTasksModel } from '../task-models';

export interface CheckListSheetFilterModel extends FiltersModel {
  event_task_id: number;
}

export interface CheckListSheetsCreateModel {
  sku_group_id: number;
  event_task_id?: number;
  customer_id?: number;
  service_state_id: number;
  costs?: object;
  active?: boolean;
  locations?: number[];
}

export interface CheckListSheetsModel extends DataModel {
  id: number;
  sku_group: SkuGroupModel;
  event_task: EventTasksModel;
  customer: CustomerModel;
  service_state: ServiceStateModel;
  costs: object;
  active: boolean;
  locations: number[];
}

export interface CheckListSheetsListModel extends DataListModel {
  results: CheckListSheetsModel[];
}

export interface ServiceStateModel {
  id: number;
  external_id: string;
  name: string;
  active: boolean;
  customer: CustomerModel;
}

/**
 * Check list sheet lines
 */
export interface CheckListSheetLinesFilterModel extends FiltersModel {
  checklist_sheet_id?: number;
  checklist_sheet__event_task_id?: number;
  event_task_id?: number;
}
/*
 * Create Model
 */
export interface CheckListSheetsLinesCreateModel {
  id?: number;
  event_task_id?: number;
  image_type_id?: number;
  customer_id?: number;
  step_number: number;
  description: string;
  type: string;
  params: Params;
  image: boolean;
  active: boolean;
  required: boolean;
  properties?: any;
}

export interface Params {
  category: Category;
  type_check: Category;
  header_options: HeaderOptions;
  response_header_options: HeaderOptions;
  inspection_steps: InspectionSteps;
  observations: string;
  is_preliminar: boolean;
}

export interface Category {
  value: string;
}

export interface HeaderOptions {
  choices: string[];
}

export interface InspectionSteps {
  value: string[];
}

/**
 * Response model
 */

export interface CheckListSheetsLinesModel extends DataModel {
  id: number;
  event_task: EventTasksModel;
  image_type: ImageType;
  customer: CustomerModel;
  service_state: null;
  step_number: number;
  description: string;
  type: string;
  group_name?: string;
  properties?: any;
  params: Params;
  image: boolean;
  active: boolean;
  required: boolean;
}

interface ChecklistSheet {
  id: number;
  sku_group: SkuGroupModel;
  event_task: EventTasksModel;
  customer_id: number;
  service_state_id: number;
}

interface ImageType {
  id: number;
  external_id: string;
  name: string;
  active: boolean;
  customer_id: number;
}

export interface CheckListSheetsLinesListModel extends DataListModel {
  checklistSheetId?: number;
  results: CheckListSheetsLinesModel[];
}
