import {
  WarehouseListModel,
  LocationsWMSFilterModel,
  WarehouseModel,
} from '@kanzi-apes/kanzi-models';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as LocationsActions from './locations.actions';
import { LocationsEntity } from './locations.models';

export const LOCATIONS_FEATURE_KEY = 'locations';

export interface LocationsState extends EntityState<LocationsEntity> {
  selectedId?: string | number; // which Locations record has been selected
  loading: boolean; // has the Locations list been loaded
  error?: string | null; // last known error (if any)
  warehouseRootList: WarehouseListModel | null;
  warehouseRootFilter: LocationsWMSFilterModel | null;
  warehouseRootSelected: WarehouseModel | null;
}

export interface LocationsPartialState {
  readonly [LOCATIONS_FEATURE_KEY]: LocationsState;
}

export const locationsAdapter: EntityAdapter<LocationsEntity> =
  createEntityAdapter<LocationsEntity>();

export const initialLocationsState: LocationsState =
  locationsAdapter.getInitialState({
    loading: false,
    warehouseRootList: null,
    warehouseRootSelected: null,
    warehouseRootFilter: null,
    error: null,
  });

const reducer = createReducer(
  initialLocationsState,
  on(LocationsActions.initAuth, (state) => ({
    ...state,
    loading: false,
    warehouseList: null,
    error: null,
  })),
  on(LocationsActions.loadWarehouseRootList, (state, { filters }) => ({
    ...state,
    warehouseRootFilter: filters,
    loading: true,
  })),
  on(LocationsActions.loadWarehouseRootListSuccess, (state, { listItems }) => ({
    ...state,
    loading: false,
    warehouseRootList: listItems,
  })),

  on(LocationsActions.loadWarehouseRootListFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
  on(LocationsActions.selectWarehouseRoot, (state, { warehouseRoot }) => ({
    ...state,
    warehouseRootSelected: warehouseRoot,
  })),
  on(
    LocationsActions.warehouseRootIdSelected,
    (state, { warehouseRootId }) => ({
      ...state,
      warehouseRootSelected:
        state.warehouseRootList?.results.find(
          (item) => item.id === warehouseRootId
        ) || null,
    })
  )
);

export function locationsReducer(
  state: LocationsState | undefined,
  action: Action
) {
  return reducer(state, action);
}
