import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

interface estimatedTimeModel {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
}

@Component({
  selector: 'kanzi-apes-time-picker',
  templateUrl: './kanzi-time-picker.component.html',
  styleUrls: ['./kanzi-time-picker.component.scss'],
})
export class KanziTimePickerComponent implements OnInit {
  @Input() showDialog: boolean = false;
  @Output() closeDialogEvent = new EventEmitter<boolean>();
  @Output() timeEvent = new EventEmitter<string>();

  estimatedTime: estimatedTimeModel = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  };
  constructor() {}

  ngOnInit(): void {}

  onSetTime() {
    const {days,hours,minutes,seconds} = this.estimatedTime
    const time = `${this.setLandingZero(days)} ${this.setLandingZero(hours)}:${this.setLandingZero(minutes)}:${this.setLandingZero(seconds)}.${this.estimatedTime.milliseconds}`;
    this.timeEvent.emit(time);
    this.closeDialog();
  }

  closeDialog() {
    this.closeDialogEvent.emit(false);
    this.estimatedTime = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    };
  }

  setLandingZero(number: number) {
    if (number > 9) {
      return number.toString();
    }
    return '0' + number.toString();
  }
}
