import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { QuestionBase } from '@kanzi-apes/kanzi-models';
@Injectable({
  providedIn: 'root',
})
export class QuestionControlService {
  constructor() {}

  toFormGroup(questions: QuestionBase<any>[]) {
    const group: any = {};

    questions.forEach((question) => {
      if (question) {
        group[question.key] = question.required
          ? new UntypedFormControl(question.value || '', Validators.required)
          : new UntypedFormControl(question.value || '');
        if (question && question.controlType === 'grouptext') {
          question['items']?.forEach((item) => {
            group[item.key] = item.required
              ? new UntypedFormControl(item.value || '', Validators.required)
              : new UntypedFormControl(item.value || '');
          });
        }
      }
    });
    return new UntypedFormGroup(group);
  }
}
