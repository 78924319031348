<div class="box" [ngClass]="'box-' + type">
  <div class="info-box">
    <div class="box-header with-border">
      <h3 class="box-title">{{ title | translate }}</h3>
      <!-- /.box-tools -->
    </div>
    <div class="info-box-content" style="margin-left: 0; text-align: center;">
      <h2 style="font-size: 50px;">{{ textData }}</h2>
    </div>
  </div>
</div>
