/**
 * It creates a link element, sets the href attribute to the base64 string, sets the download attribute
 * to the file name, and then clicks the link
 * @param {string} base64String - This is the base64 string that you get from the API.
 * @param {string} fileName - The name of the file you want to download.
 */
export function downloadPdf(base64String:string, fileName:string){
  const source = `data:application/pdf;base64,${base64String}`;
  const link = document.createElement("a");
  link.href = source;
  link.download = `${fileName}.pdf`
  link.click();
}