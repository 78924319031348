export interface DataListModel<T = any> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

export interface ListResults<T> {
  count: number;
  last_evaluated_key: string | null;
  warnings: string[];
  results: T[];
}
