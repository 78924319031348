import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  StoreRouterConnectingModule,
  RouterStateSerializer,
  FullRouterStateSerializer,
} from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { CoreZenderboxModule } from './core/core.module';
import { KanziAuthModule } from '@kanzi-apes/kanzi-auth';
import {
  CustomRouterStateSerializer,
  KanziUtilsModule,
} from '@kanzi-apes/kanzi-utils';
import { KanziUiModule } from '@kanzi-apes/kanzi-ui';
import { KanziPrimeUiModule } from '@kanzi-apes/kanzi-prime-ui';
import { ZENDERBOX_MAIN_ROUTES } from './app.routes';
import { reducers, metaReducers } from './reducers';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppZenderboxComponent } from './app.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

/**
 * Zenderbox application for .....
 */
@NgModule({
  declarations: [AppZenderboxComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(ZENDERBOX_MAIN_ROUTES, {
      useHash: false,
      enableTracing: !environment.production,
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
      stateKey: 'router',
    }),
    StoreDevtoolsModule.instrument({
      name: 'Kanzi DevTools',
      logOnly: environment.production,
      maxAge: 30,
      connectInZone: true,
    }),
    EffectsModule.forRoot([]),
    CoreZenderboxModule,
    KanziAuthModule,
    KanziUiModule,
    KanziPrimeUiModule,
    KanziUtilsModule.forRoot({ env: environment }),
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterStateSerializer,
    },
  ],
  bootstrap: [AppZenderboxComponent],
})
export class AppZenderboxModule {
  constructor(private translate: TranslateService) {
    // Gets Default language from browser if available, otherwise set English ad default
    this.translate.addLangs(['en', 'es', 'pr']);
    this.translate.setDefaultLang('en');
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(
      browserLang ? (browserLang.match(/en|es/) ? browserLang : 'en') : 'en'
    );
  }
}
