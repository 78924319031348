import { Route } from '@angular/router';

export const kanziLocationManagementRoutes: Route[] = [
  {
    path: '',
    redirectTo: '/administration/locations/warehouses',
    pathMatch: 'full',
  },
  {
    path: 'warehouses',
    loadComponent: () =>
      import('./pages/warehouse-wms-list').then(
        (m) => m.KanziWarehouseWmsListComponent
      ),
    data: {
      breadcrumb: 'Listado Bodegas',
    },
  },
  {
    path: 'positions',
    loadComponent: () =>
      import('./pages/warehouse-wms-location-list').then(
        (m) => m.KanziWarehouseWmsLocationListComponent
      ),
    data: {
      breadcrumb: 'Listado Ubicaciones',
    },
  },
];
