import {
  StoreOrderLineModel,
  SkuModel,
  DistributionOrderLineCreateModel,
} from '../../interfaces';

export class DistributionLine implements DistributionOrderLineCreateModel {
  sku: SkuModel | null = null;
  sku_id: number = 0;
  sku_external_id: string | null = null;
  sku_display_name: string | null = null;
  amount: number;
  in_work_order_amount: number;
  in_distribution_amount: number;
  packed_amount: number;
  balance: number;
  total_amount: number;

  constructor(line?: StoreOrderLineModel, isQuantity: boolean = false) {
    this.amount = 0;
    this.in_distribution_amount = 0;
    this.in_work_order_amount = 0;
    this.packed_amount = 0;
    this.balance = 0;
    this.total_amount = 0;

    if (line && line.sku) {
      this.sku_id = line.sku.id ? line.sku.id : 0;
      this.sku = line.sku;
      if (isQuantity) {
        this.packed_amount = line.packed_quantities
          ? line.packed_quantities
          : 0;
        this.in_work_order_amount = line.in_work_order_quantities
          ? line.in_work_order_quantities
          : 0;
        this.total_amount = line.quantity ? line.quantity : 0;
        this.in_distribution_amount = line.in_distribution_quantitie
          ? line.in_distribution_quantitie
          : 0;
      } else {
        this.packed_amount = line.packed_amount ? line.packed_amount : 0;
        this.in_work_order_amount = line.in_work_order_amount
          ? line.in_work_order_amount
          : 0;
        this.total_amount = line.amount ? line.amount : 0;
        this.in_distribution_amount = line.in_distribution_amount
          ? line.in_distribution_amount
          : 0;
      }

      this.balance =
        this.total_amount -
        (line.in_distribution_amount ? line.in_distribution_amount : 0);
      this.amount = this.balance;
    }
  }
}
