import { ColorScheme, MenuMode } from '../types';

export interface AppConfig {
  inputStyle: string;
  colorScheme: ColorScheme;
  theme: string;
  ripple: boolean;
  menuMode: MenuMode;
  scale: number;
  menuTheme: string;
}

export interface LayoutState {
  staticMenuDesktopInactive: boolean;
  overlayMenuActive: boolean;
  rightMenuVisible: boolean;
  configSidebarVisible: boolean;
  staticMenuMobileActive: boolean;
  menuHoverActive: boolean;
  searchBarActive: boolean;
  sidebarActive: boolean;
  anchored: boolean;
}

export interface Breadcrumb {
  label: string;
  url?: string;
}

export interface MenuChangeEvent {
  key: string;
  routeEvent?: boolean;
}
