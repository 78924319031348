import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppConfig, LayoutState } from '../models';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';

@Injectable({
  providedIn: 'root',
})
export class DiamondLayoutService {
  config: AppConfig = {
    ripple: true,
    inputStyle: 'outlined',
    menuMode: 'static',
    colorScheme: 'light',
    theme: 'blue',
    scale: 14,
    menuTheme: 'darkgray',
  };

  state: LayoutState = {
    staticMenuDesktopInactive: false,
    overlayMenuActive: false,
    rightMenuVisible: false,
    configSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false,
    searchBarActive: false,
    sidebarActive: false,
    anchored: false,
  };

  private configUpdate = new Subject<AppConfig>();

  private overlayOpen = new Subject<any>();

  configUpdate$ = this.configUpdate.asObservable();

  overlayOpen$ = this.overlayOpen.asObservable();

  get containerClass() {
    return {
      'layout-overlay': this.config.menuMode === 'overlay',
      'layout-static': this.config.menuMode === 'static',
      'layout-slim': this.config.menuMode === 'slim',
      'layout-horizontal': this.config.menuMode === 'horizontal',
      'layout-compact': this.config.menuMode === 'compact',
      'layout-reveal': this.config.menuMode === 'reveal',
      'layout-drawer': this.config.menuMode === 'drawer',
      'layout-sidebar-dim': this.config.colorScheme === 'dim',
      'layout-sidebar-dark': this.config.colorScheme === 'dark',
      'layout-overlay-active':
        this.state.overlayMenuActive || this.state.staticMenuMobileActive,
      'layout-mobile-active': this.state.staticMenuMobileActive,
      'layout-static-inactive':
        this.state.staticMenuDesktopInactive &&
        this.config.menuMode === 'static',
      'p-ripple-disabled': !this.config.ripple,
      'p-input-filled': this.config.inputStyle === 'filled',
      'layout-sidebar-active': this.state.sidebarActive,
      'layout-sidebar-anchored': this.state.anchored,
    };
  }

  get sidebarClass() {
    return this.config.colorScheme === 'light'
      ? `layout-sidebar-${this.config.menuTheme}`
      : '';
  }

  constructor(private envService: EnvironmentService) {}

  onMenuToggle() {
    if (this.isOverlay()) {
      this.state.overlayMenuActive = !this.state.overlayMenuActive;

      if (this.state.overlayMenuActive) {
        this.overlayOpen.next(null);
      }
    }

    if (this.isDesktop()) {
      this.state.staticMenuDesktopInactive =
        !this.state.staticMenuDesktopInactive;
    } else {
      this.state.staticMenuMobileActive = !this.state.staticMenuMobileActive;

      if (this.state.staticMenuMobileActive) {
        this.overlayOpen.next(null);
      }
    }
  }

  onOverlaySubmenuOpen() {
    this.overlayOpen.next(null);
  }

  showRightMenu() {
    this.state.rightMenuVisible = true;
  }

  showConfigSidebar() {
    this.state.configSidebarVisible = true;
  }

  toggleSearchBar() {
    this.state.searchBarActive = !this.state.searchBarActive;
  }

  isOverlay() {
    return this.config.menuMode === 'overlay';
  }

  isDesktop() {
    return window.innerWidth > 991;
  }

  isSlim() {
    return this.config.menuMode === 'slim';
  }

  isCompact() {
    return this.config.menuMode === 'compact';
  }

  isHorizontal() {
    return this.config.menuMode === 'horizontal';
  }

  isMobile() {
    return !this.isDesktop();
  }

  onConfigUpdate() {
    this.configUpdate.next(this.config);
  }
}
