<!-- Modal -->
<!-- <div
  class="modal fade in"
  *ngIf="message"
  id="myModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  [ngStyle]="{ display: isVisible }"
>
  <div
    class="modal-dialog"
    role="document"
    class="col-lg-3 col-md-5 col-sm-7"
    style="margin: 100px auto; float: none;"
  >
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="myModalLabel">{{ title }}</h4>
      </div>
      <div class="modal-body">
        {{ message.text }}
      </div>
      <div class="modal-footer">
        <button
          type="button"
          data-dismiss="modal"
          (click)="noOption()"
          class="btn btn-default"
        >
          {{ "No" | translate }}
        </button>
        <button
          type="button"
          data-dismiss="modal"
          (click)="yesOption()"
          class="btn btn-primary"
        >
          {{ "Si" | translate }}
        </button>
      </div>
    </div>
  </div>
</div> -->
<!-- Modal -->

<p-confirmDialog
  #cd
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '30vw' }"
  acceptLabel="{{ 'Si' | translate }}"
  rejectLabel="{{ 'No' | translate }}"
  rejectButtonStyleClass="p-button-text"
>
</p-confirmDialog>
