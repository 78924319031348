import {computed, Injectable, signal} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgressBarService {
  private progress = signal<{percent:number;packet:string}>({percent:0,packet:'1/1'});
 /* private progress = new BehaviorSubject({
    percent: 0,
    packet: '1/1',
  });*/
  public progress$ = computed(()=>this.progress());

 /* get progress$() {
    return this.progress.asObservable();
  }*/

  onProgress(percent: number, packet: string) {
    this.progress.set({
      percent,
      packet,
    });
  }

  onComplete(packet: string) {
    this.progress.set({
      percent:100,
      packet,
    });
  }
}
