import { DataModel, DataListModel } from '../data';
import { CustomerModel } from '../customer';
import { FiltersModel } from '../filters';

export interface RuleCreateModel {
  id?: number;
  customer_id: number;
  carrier_id: number;
  service_type_id: number;
  external_id?: string;
  name?: string;
}

export interface RuleModel extends DataModel {
  carrier: CarrierModel;
  service_type: ServiceTypeModel;
  external_id: string;
  name: string;
  customer: CustomerModel;
}

export interface CarrierModel {
  id: number;
  external_id: string;
  name: string;
}

export interface ServiceTypeModel {
  id: number;
  external_id: string;
  carrier: CarrierModel;
  name: string;
}

export interface RuleListModel extends DataListModel {
  results: RuleModel[];
}
export interface CarrierListModel extends DataListModel {
  results: CarrierModel[];
}
export interface ServiceTypeListModel extends DataListModel {
  results: ServiceTypeModel[];
}

export interface RuleFilterModel extends FiltersModel {
  carrier_id?: number;
}

export interface ServiceTypeFilterModel extends FiltersModel {
  carrier_id: number;
}
