import { Component, OnInit, Input, inject, input } from '@angular/core';
import { LayoutService } from '../../services/layout/layout.service';
@Component({
  selector: 'kanzi-ui-footer',
  templateUrl: './kanzi-ui-footer.component.html',
  styleUrls: ['./kanzi-ui-footer.component.scss'],
})
export class KanziUiFooterComponent implements OnInit {
  versionName = input.required<string>();
  layoutService: LayoutService = inject(LayoutService);
  year: number = 0;

  get logo() {
    return this.layoutService._config.colorScheme === 'light'
      ? 'dark'
      : 'white';
  }

  constructor() {}

  ngOnInit(): void {
    const now = new Date();
    this.year = now.getFullYear();
  }
}
