import { AuditLineModel, AuditModel, LocationModel } from '../../../interfaces';

export class AuditLine implements AuditLineModel {
  audit: AuditModel;
  location: LocationModel;

  constructor(_audit: AuditModel, _location: LocationModel) {
    this.audit = _audit;
    this.location = _location;
  }
}
