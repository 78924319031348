import { SkuModel, PurchaseOrderLineCreateModel } from '../../interfaces';

export class PurchaseOrderLine implements PurchaseOrderLineCreateModel {
  sku: SkuModel | null;
  sku_id: string | null;
  sku_external_id: string | null;
  sku_display_name: string | null;
  amount: number;
  sub_location_id: number | null;

  constructor() {
    this.sku = null;
    this.sku_id = null;
    this.amount = 0;
    this.sku_external_id = null;
    this.sku_display_name = null;
    this.sub_location_id = null;
  }
}
