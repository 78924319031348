import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import { LoginService } from '@kanzi-apes/kanzi-auth';

@Directive({
  selector: '[appMenuHideRigth]',
})
export class MenuHideRigthDirective implements OnInit {
  @Input('appMenuHideRigth') optionRole: string;

  constructor(private el: ElementRef, private loginService: LoginService) {
    this.optionRole = '';
  }

  ngOnInit() {
    if (!this.loginService.viewConfigOption(this.optionRole)) {
      this.el.nativeElement.style.display = 'none';
    }
  }
}
