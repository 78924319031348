<div class="layout-footer">
  <img
    id="footer-logo"
    [src]="'assets/images/logos/logo-apes-' + logo + '.png'"
    alt="kanzi-layout"
  />
  <div class="flex gap-2">
    <span class="footer-app-name">Kanzi v{{ versionName() }}</span>
    <span class="footer-copyright">&#169; Technoapes - {{ year }}.</span>
  </div>
</div>
