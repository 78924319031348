import { ContactModel } from '../contacts';
import { SkuModel } from '../products';
import { LocationModel } from '../location';
import { DataModel, DataListModel } from '../data';
import { FiltersModel } from '../filters';
import { ItemTypesModel } from '../items';

export interface ProductionOrderFilterModel extends FiltersModel {
  status?: string;
  external_id?: string;
  entry_location_id?: any;
}

export interface ProductionOrderLineFilterModel extends FiltersModel {
  production_order_id?: number;
  sku_id?: string;
}

export interface ProductionOrderLineModel extends DataModel {
  display_name?: string;
  sku?: SkuModel;
  sku_id?: string;
  sku_external_id?: string;
  sourceInventory?: number;
  amount?: number;
  status?: string;
  production_order?: number;
  sku_display_name?: string;
  sub_location_id?: number;
  id?: number;
  production_order_id?: number;
}

export interface ProductionOrderLineCreateModel {
  sku: SkuModel | null;
  sku_id: string | null;
  sku_external_id: string | null;
  amount?: number;
  sku_display_name: string | null;
  sub_location_id?: number | null;
}
export interface ProductionOrderLineControlModel
  extends ProductionOrderLineModel {
  count?: number;
  count_cedi?: number;
}

export interface ProductionOrderListModel extends DataListModel {
  count: number;
  next: string;
  previous: string;
  results: ProductionOrderModel[];
}

export interface ProductionOrderFormModel {
  id?: number;
  provider_id: number;
  lines: ProductionOrderLineModel[];
  entry_location_id: number;
  customer_id: number;
  external_id: string;
  properties?: any;
  used_external_id?: boolean;
  item_type_id?: string | number;
  automatic_printing?:boolean;
}

export interface ProductionOrderModel extends DataModel {
  status: string;
  prefix: string;
  external_id: string;
  total_items: number;
  quality_date: Date;
  end_date: Date;
  provider?: ContactModel;
  provider_name?: string;
  provider_id?: number;
  cell?: number;
  cell_name?: string;
  end_name: string;
  entry_location: LocationModel;
  entry_location_id: number;
  entry_location_display_name?: string;
  quality_name: string;
  lines?: ProductionOrderLineModel[];
  used_external_id?: boolean;
  total_amount?: number;
  customer_id?: number;
  template_id?: number;
  template_name?: string;
  created_by_username?: string;
  properties?: any;
  item_type?: ItemTypesModel;
  item_type_name?: string;
}

export interface ProductionOrderLineListModel extends DataListModel {
  results: ProductionOrderLineModel[];
}

export interface ProductionOrderPropertyItemModel {
  code?: string;
  key_name: string;
  model: string;
  model_field: string;
}

export interface ProductionOrderPropertiesListModel extends DataListModel {
  results: ProductionOrderPropertyItemModel[];
}

export interface ProductionOrderPropertyTableModel {
  property: ProductionOrderPropertyItemModel;
  value: any;
  edit: boolean;
  properties: [];
  values: [];
}

export interface ProductionOrderPropertiesFilterModel extends FiltersModel {
  model?: string;
  model_field?: string;
}
