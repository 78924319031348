import { QuestionBase } from './question-base';

export class DropdownQuestion extends QuestionBase<string> {
  children?: string;

  constructor(options: any = {}) {
    super(options);
    this.controlType = 'dropdown';
    this.children = options['children'] || '';
    this.options = options['options'] || [];
  }
}
