import { Component, OnInit, Input, OnChanges, input } from '@angular/core';
import { MenuItem } from '@kanzi-apes/kanzi-prime-ui';

/**
 * @author Hugo Andrés Escobar Ciceri
 * @version 7.0.0
 *
 * Component to display the App Main Menu.
 */
@Component({
  selector: 'kanzi-ui-main-menu',
  templateUrl: './kanzi-ui-main-menu.component.html',
  styleUrls: ['./kanzi-ui-main-menu.component.scss'],
})
export class KanziUiMainMenuComponent implements OnInit, OnChanges {
  menuOptions = input.required<MenuItem[]>();
  constructor() {}
  ngOnInit() {}

  ngOnChanges() {}
}
