import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  LocationModel,
  ProductFilterModel,
  SkuListModel,
  SkuModel,
} from '@kanzi-apes/kanzi-models';
import { KanziRestClientService } from '@kanzi-apes/kanzi-rest-api';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';

@Injectable({
  providedIn: 'root',
})
export class ProductLineService {
  private skuUrl = this.envService.environment
    ? this.envService.environment.kongApi +
      this.envService.environment?.apiModules.inventory +
      'skus/'
    : ''; // URL to web ap

  constructor(
    private restClient: KanziRestClientService,
    private envService: EnvironmentService
  ) {}

  getSkusList(filter: ProductFilterModel): Observable<SkuListModel> {
    return this.restClient.get<SkuListModel>(
      this.skuUrl,
      filter,
      `Error al obtener los SKU's`
    );
  }

  getSkusId(id: number): Observable<SkuModel> {
    const url=`${this.skuUrl}${id}`
    return this.restClient.get<SkuModel>(
      url,
      null,
      `Error al obtener el sku`
    );
  }

  getSkuLocationSuggested(skuId: number, locationId: number): Observable<any> {
    const url = `${this.skuUrl}${skuId}/get_locations/?location_id=${locationId}`;
    return this.restClient.get<any>(
      url,
      null,
      `Error al obtener las locaciones.`
    );
  }
}
