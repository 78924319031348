import {
  Directive,
  Input,
  ElementRef,
  HostBinding,
  OnChanges,
} from '@angular/core';
import { KanziStatus } from '@kanzi-apes/kanzi-models';

@Directive({
  selector: '[appBageStatusStyle]',
})
export class BageStatusStyleDirective implements OnChanges {
  @Input('appBageStatusStyle') status: string;
  @HostBinding('class')
  elemClass = '';
  className = 'corner-ribbon top-right';
  constructor(private el: ElementRef) {
    this.status = '';
  }

  ngOnChanges() {
    switch (this.status) {
      case KanziStatus.CREATED: {
        this.elemClass = `${this.className} bg-blue`;
        break;
      }
      case KanziStatus.EXPIRED: {
        this.elemClass = `${this.className} bg-green`;
        break;
      }

      case KanziStatus.COMPLETED: {
        this.elemClass = `${this.className} bg-green`;
        break;
      }

      case KanziStatus.CALCULATED: {
        this.elemClass = `${this.className} bg-green`;
        break;
      }
      case KanziStatus.ADJUSTED: {
        this.elemClass = `${this.className} bg-green`;
        break;
      }
      case KanziStatus.PRINTED: {
        this.elemClass = `${this.className} bg-green`;
        break;
      }
      case KanziStatus.DISPATCHED: {
        this.elemClass = `${this.className} bg-green`;
        break;
      }
      case KanziStatus.INCOMPLETE: {
        this.elemClass = `${this.className} bg-yellow`;
        break;
      }
      case KanziStatus.FINISHED: {
        this.elemClass = `${this.className} bg-yellow`;
        break;
      }
      case KanziStatus.IN_PROCESS: {
        this.elemClass = `${this.className} bg-yellow`;
        break;
      }
      case KanziStatus.PROCESSING: {
        this.elemClass = `${this.className} bg-yellow`;
        break;
      }
      case KanziStatus.VERIFIED: {
        this.elemClass = `${this.className} bg-yellow`;
        break;
      }
      case KanziStatus.IN_PROGRESS: {
        this.elemClass = `${this.className} bg-yellow`;
        break;
      }
      case KanziStatus.ABORTED: {
        this.elemClass = `${this.className} bg-red`;
        break;
      }
      case KanziStatus.INVALID: {
        this.elemClass = `${this.className} bg-red`;
        break;
      }
      case KanziStatus.CANCELLED: {
        this.elemClass = `${this.className} bg-red`;
        break;
      }
      case KanziStatus.ISSUED: {
        this.elemClass = `${this.className} bg-blue`;
        break;
      }
      case KanziStatus.DRAFT: {
        this.elemClass = `${this.className} bg-blue`;
        break;
      }

      case KanziStatus.CLOSED: {
        this.elemClass = `${this.className} bg-red`;
        break;
      }

      default:
        break;
    }
  }
}
