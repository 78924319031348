export class QuestionBase<T> {
  value: T;
  key: string;
  type?: string;
  label: string;
  labelPosition?: string;
  required: boolean;
  order: number;
  controlType: string;
  size?: number;
  readonly?: boolean;
  options?: any[];
  items?: any[];

  constructor(
    options: {
      value?: T;
      key?: string;
      label?: string;
      labelPosition?: string;
      required?: boolean;
      order?: number;
      controlType?: string;
      size?: number;
      readonly?: boolean;
    } = {}
  ) {
    this.value = options.value!;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.options = [];
    this.size = options.size;
    this.labelPosition = options.labelPosition;
    this.readonly = options.readonly || false;
  }
}
