import { FiltersModel } from '../filters';
import { CustomerModel } from '../customer';
import { UserModel } from '../user';
import { ListResults } from '../data';

interface ChildRuleModel {
  position: {
    structure: {
      separator_character: string;
      ordered_types: string[];
    };
  };
}

interface LocationPropertiesModel {
  weight_capacity?: number;
  address?: string;
  volumetric_capacity?: number;
  latitude?: number;
  length?: number;
  width?: number;
  available_area?: number;
  cell_phone_number?: string;
  phone_number?: string;
  longitude?: number;
  height?: number;
}

interface WarehouseDeliveryConfigModel {
  mode: string;
  delivery_order_automatic_creation: boolean;
  destination_ids: string[];
  state: string;
  shipping_order_required: boolean;
  order_type_id: string;
}

interface WarehouseEnlistmentConfigModel {
  mode: string;
  in_advance_mins: number;
  destination_ids: string[];
  max_elements_per_group: number;
  order_type_id: string;
  default_origin_id: string;
  state: string;
  shipping_order_required: boolean;
}
export interface WarehouseModel {
  children_rules: ChildRuleModel;
  loss_control_location_id: string;
  warehouse_position: string;
  properties: LocationPropertiesModel;
  status: string;
  delivery_configs: WarehouseDeliveryConfigModel[];
  user_workforce_load_per_hour: number;
  lines_amount_factor: number;
  name: string;
  dispatch_location_priority_name: string;
  inventory_location_priority_name: string;
  pk_instance: string;
  type_id: string;
  sk: string;
  external_id: string;
  created_by_username: string;
  weight_factor: number;
  id: string;
  modified_by_username: string;
  modified: string;
  parent_id: string;
  amount_factor: number;
  use_types: any[];
  inventory_location_priority_massive_update_result_data: {
    not_found_count: number;
    update_sent: number;
    create_sent: number;
  };
  created: string;
  dispatch_location_priority_massive_update_result_data: {
    not_found_count: number;
    update_sent: number;
    create_sent: number;
  };
  default_stock_manage_method: string;
  modified_unix_epoch: number;
  warehouse_id: string;
  enlistment_configs: WarehouseEnlistmentConfigModel[];
  created_unix_epoch: number;
  consecutive: string;
  pk: string;
  variant_information_array: any;
  type: {
    id: string;
    external_id: string;
    name: string;
  };
  parent: {
    id: string;
    external_id: string;
    name: string;
  };
  warehouse: {
    id: string;
    external_id: string;
    name: string;
  };
}

export type WarehouseListModel = ListResults<WarehouseModel>;

export interface LocationsWMSFilterModel {
  type__code__eq?: string;
  type__name__eq?: string;
  last_evaluated_key_id?: string | null | undefined;
  warehouse_id__eq?: string;
  search?: string;
}

export interface LocationModel {
  id?: number;
  name: string;
  code?: string;
  display_name: string;
  description: string;
  address: string;
  latitude?: number;
  longitude?: number;
  enabled: boolean;
  created: Date;
  modified: Date;
  type: TypeLocationModel;
  parent: any;
  external_id: number;
  customer_id?: number;
  parent_id?: number;
  created_by?: UserModel;
  customer?: CustomerModel[];
  properties?: any;
  params?: any;
  customers?: CustomerModel[];
}
export interface LocationCreateModel {
  id?: number;
  customer_ids: number[];
  parent_id: number;
  name: string;
  display_name: string;
  external_id?: number;
  description?: string;
  longitude?: number;
  latitude?: number;
  type_id?: number;
  properties?: any;
  params?: any;
}

export interface LocationCreateModelFile {
  id?: number;
  master_file_location: File;
  location_type_id: number;
  customer_id: number;
  parent_id?: number;
}

export interface TypeLocationModel {
  id: number;
  name: string;
  parent: number;
  external_id: string;
}

export interface LocationFilterModel extends FiltersModel {
  type_id?: number;
  type__name?: string;
  type?: string;
  parent_id?: number;
  customer_id?: number | null;
}
export interface LocationTypeFilterModel extends FiltersModel {
  parent_id?: string;
  parent__name?: string;
}

export interface LocationListModel {
  count: number;
  next: string;
  previous: string;
  results: LocationModel[];
}

export interface TypeLocationListModel {
  count: number;
  next: string;
  previous: string;
  results: TypeLocationModel[];
}
export interface LocationListApiModel {
  idConfigs: number;
  configs: {
    audit_locations_ids: number[];
  };
  user_id: number | null;
}
