import { Component, OnInit, OnChanges, Input } from '@angular/core';

@Component({
  selector: 'kanzi-totals-widget',
  templateUrl: './kanzi-totals-widget.component.html',
  styleUrls: ['./kanzi-totals-widget.component.scss'],
})
export class KanziTotalsWidgetComponent implements OnInit, OnChanges {
  @Input() description: string;
  @Input() totalCount = 0;
  @Input() urlRedirect: string;
  @Input() type: string;

  boxClass = '';

  constructor() {
    this.description = '';
    this.urlRedirect = '';
    this.type = '';
  }

  ngOnChanges() {
    if (this.type) {
      switch (this.type) {
        case 'warning': {
          this.boxClass = 'bg-yellow';
          break;
        }

        case 'primary': {
          this.boxClass = 'bg-aqua';
          break;
        }

        case 'success': {
          this.boxClass = 'bg-green';
          break;
        }

        case 'danger': {
          this.boxClass = 'bg-red';
          break;
        }

        default:
          this.boxClass = 'bg-aqua';
      }
    }
  }

  ngOnInit(): void {}
}
