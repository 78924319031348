<section class="content-header">
	<h1>
		Perfil de Usuario
	</h1>
	<ol class="breadcrumb">
		<li><a routerLink="/"><i class="fa fa-user"></i> Inicio</a></li>
		<li>Usuario</li>
		<li class="active">Perfil</li>
	</ol>
</section>

<!-- Main content -->
<section class="content">
	<div class="row">
        <div class="col-lg-4 col-md-6 col-xs-12">
			<!-- Profile Image -->
			<!--
			<div class="box box-primary">
				<div class="box-body box-profile">
					<img class="profile-user-img img-responsive img-circle" src="assets/images/backgrounds/user-icon-tres.jpg" alt="Foto Perfil de Usuario">

					<h3 class="profile-username text-center">{{user?.first_name}} {{user?.last_name}}</h3>

					<p class="text-muted text-center">
						<small>Último acceso: {{user?.last_login | date:'medium'}}</small>
					</p>

					<ul class="nav nav-stacked">
		            	<li>
		            		<strong class="col-lg-5 col-md-6 col-xs-12 control-label"><i class="fa fa-user margin-r-5"></i> Nombre:</strong>
				       		<div class="col-lg-7 col-md-6 col-xs-12">
				       			<label class="control-label text-muted">{{user?.first_name}} {{user?.last_name}}</label>
				       		</div>
		            	</li>		            	
		            	<li>
		            		<strong class="col-lg-5 col-md-6 col-xs-12 control-label"><i class="fa fa-id-card margin-r-5"></i> ID:</strong>
				       		<div class="col-lg-7 col-md-6 col-xs-12">
				       			<label class="control-label text-muted">{{employee?.id | numberFormat}}</label>
				       		</div>
		            	</li>
		            	<li>
		            		<strong class="col-lg-5 col-md-6 col-xs-12 control-label"><i class="fa fa-ticket margin-r-5"></i> Código:</strong>
				       		<div class="col-lg-7 col-md-6 col-xs-12">
				       			<label class="control-label text-muted">{{employee?.code}}</label>
				       		</div>
		            	</li>
		            	<li>
		            		<strong class="col-lg-5 col-md-6 col-xs-12 control-label"><i class="fa fa-genderless margin-r-5" aria-hidden="true"></i> Género:</strong>
				       		<div class="col-lg-7 col-md-6 col-xs-12">
				       			<label class="control-label text-muted">{{employee?.gender | gender}}</label>
				       		</div>
		            	</li>		            	
		            	<li>
		            		<strong class="col-lg-5 col-md-6 col-xs-12 control-label"><i class="fa fa-envelope margin-r-5"></i> Email:</strong>
				       		<div class="col-lg-12 col-md-12 col-xs-12">
				       			<label class="control-label text-muted">{{user?.email}}</label>
				       		</div>
		            	</li>
		            </ul>
					<a href="#" class="btn btn-primary btn-block" disabled><b>Editar</b></a>
				</div>
			
			</div>

			
			<div class="box box-primary">
				<div class="box-header with-border">
				  <h3 class="box-title">Información Adicional</h3>
				</div>
			
				<div class="box-body">
				  <strong><i class="fa fa-book margin-r-5"></i> Cargo</strong>

				  <p class="text-muted">
				    {{employee?.job_title.name}}
				  </p>

				  <hr>

				  <strong><i class="fa fa-map-marker margin-r-5"></i> Location</strong>

				  <p class="text-muted">Bogotá</p>

				  <hr>				  

				  <strong><i class="fa fa-file-text-o margin-r-5"></i> Notas</strong>

				  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam fermentum enim neque.</p>
				</div>
				
			</div>
		</div>
		
        <div class="col-lg-8 col-md-6 col-xs-12">
			<div class="nav-tabs-custom">
				<ul class="nav nav-tabs">
					<li class="active"><a href="#activity" data-toggle="tab">Actividad</a></li>
					<li><a href="#timeline" data-toggle="tab">Historial</a></li>
					<li><a href="#settings" data-toggle="tab">Notificaciones</a></li>
	            </ul>
	            <div class="tab-content">
					<div class="active tab-pane" id="activity">
					 Post 
						<div class="post">
						</div>
					</div>
					<div class="tab-pane" id="timeline">

					</div>
					<div class="tab-pane" id="settings">

					</div>
				</div>
			</div>-->
		</div>
		
    </div>
</section>