import { Routes } from '@angular/router';
import { AuthGuard } from '@kanzi-apes/kanzi-auth';

export const ZENDERBOX_MAIN_ROUTES: Routes = [
  { path: '', redirectTo: '/main/dashboards', pathMatch: 'full' },
  {
    path: 'main',
    loadChildren: () =>
      import('./core/core.module').then((m) => m.CoreZenderboxModule),
    canLoad: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
