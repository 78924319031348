import { Action } from '@ngrx/store';
import { AuthActions, AuthActionTypes } from '../actions/auth';
import { UserModel, Token } from '@kanzi-apes/kanzi-models';
import * as lcStorage from '@kanzi-apes/kanzi-utils';

export interface State {
  loggedIn: boolean;
  token: Token | null;
  user: UserModel | null;
}

export const initialState = (lcStorage.loadState() &&
  lcStorage.loadState()['auth']) || {
  loggedIn: false,
  token: null,
  user: null,
};

export function reducer(state = initialState, action: Action): State {
  const authActions = action as AuthActions;
  switch (authActions.type) {
    case AuthActionTypes.LoginSuccess: {
      return {
        ...state,
        loggedIn: true,
        token: authActions.payload.token,
        user: null,
      };
    }
    case AuthActionTypes.Logout: {
      return (
        lcStorage.loadState()['auth'] || {
          loggedIn: false,
          token: null,
          user: null,
        }
      );
    }
    case AuthActionTypes.UserProfileLoad: {
      return state;
    }
    case AuthActionTypes.UserProfileSuccess: {
      return {
        ...state,
        loggedIn: state.loggedIn,
        token: state.token,
        user: authActions.payload.user,
      };
    }
    default: {
      return state;
    }
  }
}

export const getLoggedIn = (state: State) => state.loggedIn;
export const getToken = (state: State) => state.token;
export const getUser = (state: State) => state.user;
