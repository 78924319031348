import { Action } from '@ngrx/store';
import { CustomerActions, CustomersActionTypes } from '../../actions/customers';
import {
  CustomerFilterModel,
  CustomerGroupFilterModel,
  CustomerGroupListModel,
  CustomerListModel,
} from '@kanzi-apes/kanzi-models';

export interface State {
  list: CustomerListModel | null;
  filter: CustomerFilterModel | null;
  filterGroups: CustomerGroupFilterModel | null;
  listGroups: CustomerGroupListModel | null;
  loaded: boolean;
  loading: boolean;
  error: string | null;
}

export const initialState: State = {
  list: null,
  loaded: false,
  loading: false,
  filterGroups: null,
  listGroups: null,
  filter: { page: 1, page_size: 10 },
  error: null,
};

export function reducer(state = initialState, action: Action): State {
  const customerActions = action as CustomerActions;
  switch (customerActions.type) {
    case CustomersActionTypes.LoadList: {
      return {
        ...state,
        loading: true,
        filter: customerActions.payload,
      };
    }

    case CustomersActionTypes.LoadListComplete: {
      return {
        ...state,
        loading: false,
        loaded: true,
        list: customerActions.payload,
      };
    }

    case CustomersActionTypes.LoadListError: {
      return {
        ...state,
        loading: false,
        error: customerActions.payload,
      };
    }

    case CustomersActionTypes.LoadGroup: {
      return {
        ...state,
        filterGroups: customerActions.payload,
      };
    }

    case CustomersActionTypes.LoadGroupComplete: {
      return {
        ...state,
        listGroups: customerActions.payload,
      };
    }

    default:
      return state;
  }
}

export const getList = (state: State) => state.list;
export const getFilter = (state: State) => state.filter;
export const getLoading = (state: State) => state.loading;
export const getLoaded = (state: State) => state.loaded;
export const getError = (state: State) => state.error;
export const getListGroups = (state: State) => state.listGroups;
