import {
  Directive,
  Input,
  ElementRef,
  OnChanges,
  HostBinding,
} from '@angular/core';
import { KanziActions } from '@kanzi-apes/kanzi-models';

@Directive({
  selector: '[kanziActionStyle]',
})
export class ActionStyleDirective implements OnChanges {
  @Input('kanziActionStyle') action: string | boolean;
  @HostBinding('class')
  elemClass = this.el.nativeElement.className;
  constructor(private el: ElementRef) {
    this.action = '';
  }
  ngOnChanges(): void {
    switch (this.action) {
      case KanziActions.VERIFIED: {
        this.elemClass = `${this.elemClass}  label-success`;
        break;
      }
      case KanziActions.RETURN: {
        this.elemClass = `${this.elemClass}  label-success`;
        break;
      }
      case KanziActions.SIMPLE_ENTRY: {
        this.elemClass = `${this.elemClass}  label-success`;
        break;
      }
      case KanziActions.TRANSFER_IN: {
        this.elemClass = `${this.elemClass}  label-success`;
        break;
      }
      case KanziActions.AUDIT_ENTRY: {
        this.elemClass = `${this.elemClass}  label-success`;
        break;
      }
      case KanziActions.PACK: {
        this.elemClass = `${this.elemClass}  label-success`;
        break;
      }
      case KanziActions.READING: {
        this.elemClass = `${this.elemClass}  label-warning`;
        break;
      }
      case KanziActions.OTHER: {
        this.elemClass = `${this.elemClass}  label-warning`;
        break;
      }
      case KanziActions.TRANSFER: {
        this.elemClass = `${this.elemClass}  label-warning`;
        break;
      }
      case KanziActions.TRANSFER_OUT: {
        this.elemClass = `${this.elemClass}  label-danger`;
        break;
      }
      case KanziActions.SIMPLE_OUTPUT: {
        this.elemClass = `${this.elemClass}  label-danger`;
        break;
      }
      case KanziActions.SALE: {
        this.elemClass = `${this.elemClass}  label-danger`;
        break;
      }
      case KanziActions.UNPACK: {
        this.elemClass = `${this.elemClass}  label-danger`;
        break;
      }
      case KanziActions.TAKEOUT: {
        this.elemClass = `${this.elemClass}  label-danger`;
        break;
      }
      case KanziActions.PRINT: {
        this.elemClass = `${this.elemClass}  label-primary`;
        break;
      }
    }
  }
}
