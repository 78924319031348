<div class="container-file mt-2">
  <div class="flex justify-content-start flex-order-0">
    <input
      #fileUpload
      type="file"
      style="display: none"
      [accept]="accept"
      (change)="onFileSelected($event)"
    />
    <div class="flex justify-content-start">
      <button
        pButton
        pRipple
        type="button"
        label="Adjuntar"
        icon="pi pi-paperclip"
        class="p-button-text p-button-secondary p-button-sm"
        (click)="fileUpload.click()"
        [ngClass]="{ 'btn-upload': multiple || files.length <= 0 && !disabled }"
        [disabled]="!multiple && files.length > 0 || disabled"
      ></button>
      <button
        *ngIf="activeBtnSubmit"
        pButton
        pRipple
        type="button"
        label="Subir"
        icon="pi pi-upload"
        class="p-button-text p-button-secondary p-button-sm"
        (click)="onUpload()"
        [ngClass]="{ 'btn-upload': files.length > 0 && !disabled }"
        [disabled]="files.length === 0 || disabled"
      ></button>
    </div>
  </div>

  <div
    *ngFor="let item of files"
    class="flex flex-row justify-content-between align-items-center text-base mt-1 text-600"
  >
    <div class="justify-content-start ml-3">
      <i
        class="pi pi-paperclip mr-1 text-primary"
        style="font-size: 0.7rem"
      ></i>
      <span class="ml-2">{{ item.name }}</span>
    </div>
    <button
      pButton
      type="button"
      class="p-button-sm p-button-secondary p-button-text text-500 mr-3"
      icon="fa fa-trash"
      pTooltip="Eliminar"
      tooltipPosition="bottom"
      (click)="onDeleteSelected(item)"
    ></button>
  </div>
  <div
    *ngIf="files.length === 0"
    class="flex flex-column justify-content-center align-items-center mt-6"
  >
    <i class="pi pi-inbox text-primary"></i>
    <p class="text-sm font-light">No hay archivos adjuntos</p>
  </div>
</div>
