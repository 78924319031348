/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KanziRestClientService } from '@kanzi-apes/kanzi-rest-api';
import {
  LocationFilterModel,
  LocationListModel,
} from '@kanzi-apes/kanzi-models';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';

@Injectable({
  providedIn: 'root',
})
export class LocationListService {
  private locationUrl: string = '';

  constructor(
    private restClient: KanziRestClientService,
    private envService: EnvironmentService
  ) {
    if (this.envService.environment) {
      this.locationUrl =
        this.envService.environment.kongApi +
        this.envService.environment.apiModules.inventory +
        'locations/';
    }
  }

  getlocationList(filter: LocationFilterModel): Observable<LocationListModel> {
    return this.restClient.get(
      this.locationUrl,
      filter,
      'Error al obtener las locaciones'
    );
  }
}
