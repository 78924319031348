// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import {
  ConditionalBudgets,
  KanziEnvironmentModel,
  KanziWMSEnvironmentModel,
} from './env.model';
export class Environment
  implements KanziEnvironmentModel, KanziWMSEnvironmentModel
{
  wms = false;
  production = false;
  kongApi = '';
  kongTrackingFile = '';
  apesPdfCreatorApi = '';
  masterFileUrl = '';
  templatePdf = '';
  isQuantity = false;
  selectedLocation = false;
  filterByLocation = false;
  formats = {
    date: '',
  };
  apiModules = {
    auth: '',
    authToken: '',
    config: '',
    inventory: '',
    operations: '',
    rfid: '',
    audits: '',
    logistics: '',
    customers: '',
    stock: '',
    integrations: '',
    maintenance: '',
  };
  assets = {
    logo: '',
    background: '',
    root: '',
    rootClient: '',
    paths: {
      css: '',
      logos: '',
      backgrounds: '',
      js: '',
    },
  };
  modules = {
    enabledTypeItems: false,
    customerType: '',
    loadMasterFileLocation: false,
    dashboards: false,
    contacts: false,
    discountsContacts: false,
    disabledCreateContacts: false,
    isEnableButtonExternalId: false,
    contactsParams: false,
    isFilterWerehouse: false,
    products: {
      parent: false,
      products: false,
      assets: false,
      maintenanceTrace: false,
      aditionalParamsInactiveTags: false,
      inactiveTagsAccions: false,
      filterTypeSku: false,
      setProperties: false,
      setImagesProduct: false,
      loadMasterFile: false,
      editProducts: true,
      deleteProduct: true,
    },
    inventories: {
      parent: false,
      products: false,
      assets: false,
      booking: false,
      bookingCreate: false,
      bookingList: false,
      hideColumnsSummary: false,
    },
    moves: {
      parent: false,
      canClose: false,
    },
    production: {
      parent: false,
      productionOrder: false,
      productionLocationType: '',
      productionDelete: false,
      purchaseOrder: false,
      purchaseOrderDelete: false,
      purchaseLocationType: '',
      purchaseOrderEditAddLines: false,
      printOrder: false,
      filterOrder: false,
      external_id: false,
      productionOrderIdHide: false,
      skuIdLines: false,
      isDisabledCreateProduct: false,
      useSkuId: false,
    },
    operations: {
      parent: false,
      storeOrder: false,
      distributionOrder: false,
      workOrder: { parent: false, reasignCreateNewOrder: false },
      packings: false,
      dispatchPacks: false,
      external_id_config: {
        filed: null,
        subfield: null,
      },
      budgets: false,
      booking: false,
      validationClient: false,
    },
    audits: {
      parent: false,
      calendar: false,
      products: false,
      assets: false,
      classic: false,
    },
    hardware: false,
    logistics: {
      parent: false,
      trackings: false,
      trackOrderCreate: false,
      shippingOrders: false,
      printers: false,
      hideStatusTracking: false,
      sendSkuId: false,
      formStatic: false,
      hideAddressType: false,
      onlySkuId: false,
    },
    logisticPacking: {
      parent: false,
      list: false,
      listStates: false,
      create: false,
    },
    maintenance: {
      hasDiagnostics: true,
      budgets: 'REQUIRED' as ConditionalBudgets,
      urlTemplatePdfOT: 'template-ot.docx',
      parent: false,
      scheduler: false,
      orders: false,
      disabledApproveOT: false,
      otUndiagnosed: {
        visible: true,
        disabledSpareParts: false,
        hasDescription: true,
        isMaintenanceEndpoint: true,
      },
    },
    shared: {
      parent: false,
    },
    alerts: false,
    shipments: {
      parent: false,
    },
  };
  configModules = {
    locations: false,
    iconLocation: false,
    logistics: false,
    customers: false,
    users: false,
  };
  viewRigthMenu = false;
  apiRestModules = {};
}
