/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';
import { KanziRestClientService } from '@kanzi-apes/kanzi-rest-api';
import {
  LocationModel,
  TypeLocationListModel,
  LocationTypeFilterModel,
  LocationCreateModel,
} from '@kanzi-apes/kanzi-models';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private locationUrl: string = '';
  private locationTypesUrl: string = '';
  private locationUrlFile: string = '';
  constructor(
    private restClient: KanziRestClientService,
    private envService: EnvironmentService
  ) {
    if (this.envService.environment) {
      this.locationUrl =
        this.envService.environment.kongApi +
        this.envService.environment.apiModules.inventory +
        'locations/';
      this.locationTypesUrl =
        this.envService.environment.kongApi +
        this.envService.environment.apiModules.inventory +
        'location-types/';

      this.locationUrlFile =
        this.envService.environment.kongApi +
        this.envService.environment.apiModules.inventory +
        'load-locations-file/';
    }
  }

  getlocation(id: number): Observable<LocationModel> {
    return this.restClient.get(
      `${this.locationUrl}${id}`,
      null,
      'Error al obtener la locaión'
    );
  }

  createlocation(Location: LocationCreateModel): Observable<LocationModel> {
    console.log('Location ', Location);
    return this.restClient.post(
      `${this.locationUrl}`,
      Location,
      'Error al crear la locación'
    );
  }

  createlocationFile(Location: FormData): Observable<any> {
    return this.restClient.postFormData(
      `${this.locationUrlFile}`,
      Location,
      'Error al crear las locaciones'
    );
  }

  deleteLocation(id: number | null): Observable<LocationModel> {
    return this.restClient.delete(
      `${this.locationUrl}${id}`,
      'Error al eleimina la locación'
    );
  }
  getlocationTypes(
    filter: LocationTypeFilterModel
  ): Observable<TypeLocationListModel> {
    return this.restClient.get(
      `${this.locationTypesUrl}`,
      filter,
      'Error al cargar Tipos'
    );
  }
  editLocation(location: LocationCreateModel): Observable<LocationModel> {
    return this.restClient.put(
      `${this.locationUrl}${location.id}`,
      location,
      'Error al editar locación'
    );
  }
}
