import { DataListModel } from '../data/datalist.model';
export interface FlightReportListModel extends DataListModel {
    results: FlightReportModel[];
}
export interface FlightReportModel {
    id:                          number;
    external_id:                 string;
    created:                     Date | string;
    element:                     string;
    rfid_serial:                 number;
    air_guide:                   string;
    client_status:               string;
    withdrawal_request_datetime: Date | string;
    delta_withdrawal_request:    string;
    withdrawal_request_status:   string;
}
