import { MenuItem } from 'primeng/api';
/**
 * Array objects for main-sibedar menu
 * @type {MenuItem[]} Array for objects compatible with menu primeng
 *
 */
export const ItemsMenu: MenuItem[] = [
  {
    label: 'INICIO',
    expanded: true,
    visible: true,
    items: [
      {
        label: 'Dashboards',
        icon: 'fa-solid fa-chart-line',
        routerLink: 'dashboards',
      },
    ],
  },
  {
    automationId: 'CT_000',
    label: 'TERCEROS',
    icon: 'fa fa-user',
    items: [
      {
        automationId: 'CT_001',
        label: 'Crear Nuevo',
        icon: 'fa fa-plus-square',
        routerLink: 'contacts/create',
      },
      {
        automationId: 'CT_002',
        label: 'Listado',
        icon: 'fa fa-list-ul',
        routerLink: 'contacts/list',
      },
    ],
  },
  {
    automationId: 'PR_000',
    label: 'PRODUCTOS',
    icon: 'fa fa-tags',
    items: [
      {
        automationId: 'PR_002',
        label: 'Listado',
        icon: 'fa fa-tags',
        routerLink: 'products/list',
      },
    ],
  },
  {
    automationId: 'IM_000',
    label: 'INVENTARIOS',
    icon: 'fa fa-area-chart',
    items: [
      {
        automationId: 'IM_003',
        label: 'Dashboards',
        icon: 'fa fa-bar-chart',
        routerLink: 'inventories/dashboards',
      },
      {
        automationId: 'IM_004',
        label: 'Resumen',
        icon: 'fa fa-table',
        routerLink: 'inventories/summary-list',
      },
      {
        label: 'Reservas',
        icon: 'fa fa-calendar',
        routerLink: 'inventories/booking/list',
      },
    ],
  },
  {
    automationId: 'MV_000',
    label: 'MOVIMIENTOS',
    icon: 'fa fa-exchange',
    items: [
      {
        automationId: 'MV_001',
        label: 'Listado Movimientos',
        icon: 'fa fa-exchange',
        routerLink: 'moves/list',
      },
    ],
  },
  {
    automationId: 'PO_000',
    label: 'ORDENES DE INGRESO',
    icon: 'fa fa-ticket',
    items: [
      {
        automationId: 'PO_000',
        label: 'O. Producción',
        icon: 'fa fa-ticket',
        items: [
          {
            automationId: 'PO_001',
            label: 'Crear O. de Producción',
            icon: 'fa fa-plus-square',
            routerLink: 'production-orders/create',
          },
          {
            automationId: 'PO_002',
            label: 'Órdenes de Producción',
            icon: 'fa fa-ticket',
            routerLink: 'production-orders/list',
          },
        ],
      },
      {
        automationId: 'PUO_001',
        label: 'O. Compra',
        icon: 'fa fa-ticket',
        items: [
          {
            automationId: 'PUO_001',
            label: 'Crear O. de Compra',
            icon: 'fa fa-plus-square',
            routerLink: 'purchase-orders/create',
          },
          {
            automationId: 'PUO_002',
            label: 'Órdenes de Compra',
            icon: 'fa fa-ticket',
            routerLink: 'purchase-orders/list',
          },
        ],
      },
      {
        automationId: 'PRNT_002',
        label: 'Órdenes de Impresión',
        icon: 'fa fa-print',
        routerLink: 'print-orders/list',
      },
    ],
  },
  {
    automationId: 'OP_000',
    label: 'OPERACIONES',
    items: [
      {
        automationId: 'PK_000',
        label: 'Cajas',
        icon: 'fa fa-cubes',
        items: [
          {
            automationId: 'PK_003',
            label: 'Por Estado',
            icon: 'fa fa-cubes',
            routerLink: 'operations/packings/list-states',
          },
          {
            automationId: 'PK_003',
            label: 'Listado',
            icon: 'fa fa-table',
            routerLink: 'operations/packings/list',
          },
        ],
      },
    ],
  },
  {
    automationId: 'LS_000',
    label: 'LOGÍSTICA',
    icon: 'fa fa-truck',
    items: [
      {
        label: 'Listado Pedidos',
        icon: 'fa fa-list-ul',
        routerLink: 'logistics/shipping-orders/list',
      },
      {
        label: 'Listado Trackings',
        icon: 'fa fa-qrcode',
        routerLink: 'logistics/tracking-orders/list',
      },
      {
        label: 'Trackings Impresión',
        icon: 'fa fa-print',
        routerLink: 'logistics/tracking-orders/print',
      },
    ],
  },
  {
    automationId: 'AD_000',
    label: 'AUDITORIAS',
    icon: 'fa fa-eye',
    items: [
      {
        automationId: 'AD_002',
        label: 'Listado',
        icon: 'fa fa-table',
        routerLink: 'audits-classic/list',
      },
    ],
  },
  {
    automationId: 'ALY_QS_000',
    label: 'ANALITICA',
    icon: 'fas fa-chart-line',
    items: [
      {
        automationId: 'ALY_QS_001',
        label: 'Inventario',
        icon: 'fas fa-chart-area',
        routerLink: 'analytics/inventory-report',
      },
      {
        label: 'Devoluciones',
        icon: 'fas fa-chart-area',
        routerLink: 'analytics/returns-report',
      },
    ],
  },
];