export class ReportLine {
  id              !: number;
  sku_id          !: string;
  sku_code        !: string;
  sku_display_name!: string;
  sku_name        !: string;
  sku_properties  !: any;
  quantity        !: number;
  observations    !: string;
  state           !: number;
  report          !: number;

  constructor() {}
}
