<form
  class="flex flex-column align-items-center gap-4"
  (ngSubmit)="onSignIn()"
  [formGroup]="loginFormGroup"
>
  <h2>{{ 'Bienvenido a Kanzi' | translate }}</h2>
  <p>{{ 'Ingrese Su Usuario' | translate }}</p>
  <input
    id="email"
    type="text"
    placeholder="{{ 'Usuario' | translate }}"
    required
    name="username"
    formControlName="username"
    pInputText
    class="w-20rem"
  />
  <div
    [hidden]="username?.valid || username?.pristine"
    class="alert alert-danger"
  >
    {{ 'Usuario o email incorrecto' | translate }}.
  </div>
  <p-password
    id="password"
    placeholder="{{ 'Password' | translate }}"
    required
    name="password"
    formControlName="password"
    inputStyleClass="w-20rem"
    [feedback]="true"
    [toggleMask]="true"
  ></p-password>
  <div
    [hidden]="password?.valid || password?.pristine"
    class="alert alert-danger"
  >
    {{ 'Contraseña incorrecta' | translate }}.
  </div>
  <button
    type="submit"
    pButton
    label="{{ 'Ingresar' | translate }}"
    class="w-20rem"
    [disabled]="!loginFormGroup.valid"
  ></button>
</form>
