<p-table
  [value]="customParamsList"
  [rows]="5"
  [paginator]="true"
  [totalRecords]="customParamsList.length"
  class="p-col-6 p-lg-10 p-md-8 p-sm-6"
  [resizableColumns]="true"
  [styleClass]="'ateles-params-table'"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th>{{ 'Nombre' }}</th>
      <th>{{ 'Valor' }}</th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-rowData
    let-columns="columns"
    let-ri="rowIndex"
  >
    <tr id="rowsDatas__tr_overflow">
      <td pEditableColumn>
        <p-cellEditor
          ><ng-template let-key let-item="rowData" pTemplate="input">
            <div class="flex col-10" style="padding: 0">
              <p-autoComplete
                [showEmptyMessage]="true"
                optionLabel="name"
                [dropdown]="true"
                [suggestions]="filteredKeyParams"
                (completeMethod)="filterKeyParam($event)"
                (onSelect)="selectKeyParam($event, ri)"
                class="flex-grow-1"
                [style]="{'width': '100%'}"
              ></p-autoComplete>
              <button
                type="button"
                pButton
                icon="pi pi-plus"
                class="p-button-help flex-none border-noround-left"
                (click)="op.toggle($event)"
              ></button>
              <p-overlayPanel
                #op
                [showCloseIcon]="true"
                [style]="{ width: '200px' }"
              >
                <ng-template pTemplate>
                  <input
                    type="text"
                    [(ngModel)]="newKeyParamName"
                    placeholder="{{ 'Nombre del Parámetro' }}"
                    [ngModelOptions]="{ standalone: true }"
                    pInputText
                    class="p-inputtext-sm"
                    (change)="checkCustomKeyParamText($event)"
                    (keypress)="addKeyParamText($event)"
                  />
                </ng-template>
              </p-overlayPanel>
            </div>
          </ng-template>
          <ng-template pTemplate="output">
            {{ rowData.key || 'Click Para Ingresar Dato...' }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn>
        <p-cellEditor
          ><ng-template let-value let-item="rowData" pTemplate="input">
            @if(loadPropsValue){
            <p-dropdown
              [options]="optionsValue()"
              [ngModel]="rowData.value"
              (ngModelChange)="setValueProp($event, rowData)"
              [checkmark]="true"
              dataKey="value"
              [ngModelOptions]="{ standalone: true }"
              optionLabel="value"
              placeholder="Seleccionar valor"
              [style]="{ width: '100%' }"
              appendTo="body"
            />
            } @else {
            <input
              type="text"
              [(ngModel)]="rowData.value"
              placeholder="{{ 'Valor' }}"
              [ngModelOptions]="{ standalone: true }"
              pInputText
              (change)="addToFormConfig($event, rowData)"
            />
            }
          </ng-template>
          <ng-template pTemplate="output">
            {{ rowData.value || 'Click Para Ingresar Dato...' }}
          </ng-template>
        </p-cellEditor>
      </td>
    </tr>
  </ng-template>
</p-table>
<div class="flex justify-content-end">
  <button
    icon="pi pi-plus"
    class="p-button-help p-button-sm mt-2"
    pButton
    pTooltip="{{ 'Agregar Propiedad' }}"
    type="button"
    (click)="addRowProperty()"
  ></button>
  @if (customParamsList.length > 1 || deleteAll) {
  <button
    icon="pi pi-minus"
    class="p-button-secondary p-button-sm mt-2 ml-2"
    pButton
    pTooltip="{{ 'Eliminar Propiedad' }}"
    type="button"
    (click)="deleteRowProperty()"
  ></button>
  }
</div>
