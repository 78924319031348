import { SkuModel } from '../products';
import { DataModel, DataListModel } from '../data';
import { LocationModel } from '../location';
import { CustomerModel } from '../customer';
import { FiltersModel } from '../filters';

export interface ShipmentModel extends DataModel {
  status?: string;
  external_id?: string;
  order_id?: string;
  wave?: string;
  expected_arrival_date?: string;
  arrival_date?: string;
  consolidation_gate_number?: string;
  source?: LocationModel;
  destination?: LocationModel;
  total_expected_items?: number;
  customer?: CustomerModel;
  properties?: any;
  source_display_name?: string;
  destination_display_name?: string;
}

export interface ShipmentListModel extends DataListModel {
  results: ShipmentModel[];
}

export interface ShipmentFilterModel extends FiltersModel {}

export interface PackageShipmentModel extends DataModel {
  shipment: ShipmentModel;
  location?: LocationModel;
  customer?: CustomerModel;
  external_id?: string;
  type?: string;
  status?: string;
}

export interface PackageShipmentListModel extends DataListModel {
  results: PackageShipmentModel[];
}

export interface PackageShipmentFilterModel extends FiltersModel {
  shipment_id?: number;
  status?: string;
}
export interface ItemsShipmentModel extends DataModel {
  shipment: ShipmentModel;
  external_id?: string;
  type?: string;
  properties?: any;
  amount?: number;
  amount_verified?: number;
  status?: string;
  sku?: SkuModel;
  sku_display_name?: string;
  sku_external_id?: string;
  sku_properties?: any;
  sku_owner?: string;
  pkg_desc?: string;
}

export interface ItemsShipmentListModel extends DataListModel {
  results: ItemsShipmentModel[];
}

export interface ItemsShipmentFilterModel extends FiltersModel {
  shipment_id: number;
}
