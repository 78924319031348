import { KanziColorSchema } from '@kanzi-apes/kanzi-models';
import { Action } from '@ngrx/store';
import { LayoutActions, LayoutActionTypes } from '../actions/layout';

export interface State {
  showMenuMain: string;
  showRightMenu: string;
  showProgressbar: boolean;
  customerParent: boolean;
  colorScheme: KanziColorSchema;
}

const initialState: State = {
  showMenuMain: window.innerWidth < 600 ? 'closed' : 'open',
  showRightMenu: 'close',
  showProgressbar: false,
  customerParent: false,
  colorScheme: KanziColorSchema.light,
};

export function reducer(state: State = initialState, action: Action): State {
  const layoutActions = action as LayoutActions;
  switch (layoutActions.type) {
    case LayoutActionTypes.ActionMainMenu:
      return {
        ...state,
        showMenuMain: layoutActions.payload,
      };
    case LayoutActionTypes.ActionRightMenu:
      return {
        ...state,
        showRightMenu: layoutActions.payload,
      };
    case LayoutActionTypes.OpenMainMenu:
      return {
        ...state,
      };
    case LayoutActionTypes.CloseMainMenu:
      return {
        ...state,
      };

    case LayoutActionTypes.ShowProgressBar:
      return {
        ...state,
        showProgressbar: true,
      };

    case LayoutActionTypes.HideProgressBar:
      return {
        ...state,
        showProgressbar: false,
      };

    case LayoutActionTypes.ActivateCustomerParent:
      return {
        ...state,
        customerParent: true,
      };

    case LayoutActionTypes.DeactivateCustomerParent:
      return {
        ...state,
        customerParent: false,
      };
    case LayoutActionTypes.ColorScheme:
      return {
        ...state,
        colorScheme: layoutActions.payload,
      };
    default:
      return state;
  }
}

export const getShowMenuMain = (state: State) => state.showMenuMain;
export const getShowRightMenu = (state: State) => state.showRightMenu;
export const getShowProgressBar = (state: State) => state.showProgressbar;
export const getCustomerParent = (state: State) => state.customerParent;
export const getColorScheme = (state: State) => state.colorScheme;
