import { Action } from '@ngrx/store';
import { CustomerActions, CustomersActionTypes } from '../../actions/customers';
import { CustomerModel } from '@kanzi-apes/kanzi-models';
import * as lcStorage from '@kanzi-apes/kanzi-utils';

export interface State {
  selected: CustomerModel | null;
}

export const initialState: State = (lcStorage.loadState() &&
  lcStorage.loadState()['customer']) || {
  selected: null,
};

export function reducer(state = initialState, action: Action): State {
  const customerActions = action as CustomerActions;
  switch (customerActions.type) {
    case CustomersActionTypes.Selected: {
      return {
        ...state,
        selected: customerActions.payload,
      };
    }

    default:
      return state;
  }
}

export const getSelected = (state: State) => state.selected;
