import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import {
  ColorScheme,
  DiamondLayoutService,
  MenuMode,
} from '@kanzi-apes/kanzi-diamond-ui';
import { Store } from '@ngrx/store';
import { MainMenuService } from '../../services/menus';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';

@Component({
  selector: 'kanzi-right-sidebar',
  templateUrl: './kanzi-right-sidebar.component.html',
  styleUrls: ['./kanzi-right-sidebar.component.scss'],
})
export class KanziRightSidebarComponent implements OnInit, OnChanges {
  @Input() modulesApp: any | null;
  @Input() actionSidebar: string;
  @Input() setStyleColor: string;
  @Input() rolUserApp: any | null;
  @Input() minimal: boolean = false;
  @Output() actionConfigBar = new EventEmitter();
  @Output() styleColor = new EventEmitter();

  openMenu = false;
  visibleSidebar: boolean = false;
  tabSelected = 'Config';
  modelTheme: any;
  scales: number[] = [12, 13, 14, 15, 16];

  get visible(): boolean {
    return this.layoutService.state.configSidebarVisible;
  }

  set visible(_val: boolean) {
    this.layoutService.state.configSidebarVisible = _val;
  }

  get scale(): number {
    return this.layoutService.config.scale;
  }

  set scale(_val: number) {
    this.layoutService.config.scale = _val;
  }

  get menuMode(): MenuMode {
    return this.layoutService.config.menuMode;
  }

  set menuMode(_val: MenuMode) {
    this.layoutService.config.menuMode = _val;
    if (
      this.layoutService.isSlim() ||
      this.layoutService.isHorizontal() ||
      this.layoutService.isCompact()
    ) {
      this.menuService.reset();
    }
  }

  get colorScheme(): ColorScheme {
    return this.layoutService.config.colorScheme;
  }

  set colorScheme(_val: ColorScheme) {
    this.changeColorScheme(_val);
  }

  constructor(
    private store: Store,
    private layoutService: DiamondLayoutService,
    private menuService: MainMenuService,
    private envService: EnvironmentService
  ) {
    this.modulesApp = null;
    this.actionSidebar = '';
    this.setStyleColor = '';
    this.rolUserApp = null;
    this.menuMode = this.envService.environment
      ? this.envService.environment.configUI
        ? this.envService.environment.configUI.mainMenuStyle
        : 'static'
      : 'static';
    this.scale = this.envService.environment
      ? this.envService.environment.configUI
        ? this.envService.environment.configUI.scale
        : 14
      : 14;
    this.applyScale();
  }

  ngOnInit(): void {}
  ngOnChanges() {}

  onConfigButtonClick() {
    this.layoutService.showConfigSidebar();
  }

  changeColorScheme(colorScheme: ColorScheme) {
    const themeLink = <HTMLLinkElement>document.getElementById('theme-link');
    const themeLinkHref = themeLink.getAttribute('href');
    const currentColorScheme = 'theme-' + this.layoutService.config.colorScheme;
    const newColorScheme = 'theme-' + colorScheme;
    const newHref = themeLinkHref!.replace(currentColorScheme, newColorScheme);

    this.replaceThemeLink(newHref, 'theme-link', () => {
      this.layoutService.config.colorScheme = colorScheme;
      this.layoutService.onConfigUpdate();
    });
  }

  changeComponentTheme(theme: string) {
    const themeLink = <HTMLLinkElement>document.getElementById('theme-link');
    const newHref = themeLink
      .getAttribute('href')!
      .replace(this.layoutService.config.theme, theme);

    this.replaceThemeLink(newHref, 'theme-link', () => {
      this.layoutService.config.theme = theme;
      this.layoutService.onConfigUpdate();
    });
  }

  replaceThemeLink(href: string, targetId: string, onComplete?: Function) {
    const id = targetId;
    const targetLink = <HTMLLinkElement>document.getElementById(id);
    const cloneLinkElement = <HTMLLinkElement>targetLink.cloneNode(true);

    cloneLinkElement.setAttribute('href', href);
    cloneLinkElement.setAttribute('id', id + '-clone');

    targetLink.parentNode!.insertBefore(
      cloneLinkElement,
      targetLink.nextSibling
    );

    cloneLinkElement.addEventListener('load', () => {
      targetLink.remove();
      cloneLinkElement.setAttribute('id', id);
      onComplete && onComplete();
    });
  }

  changeMenuTheme(theme: any) {
    this.layoutService.config.menuTheme = theme.name;
    this.layoutService.onConfigUpdate();
  }

  decrementScale() {
    this.scale--;
    this.applyScale();
  }

  incrementScale() {
    this.scale++;
    this.applyScale();
  }

  applyScale() {
    document.documentElement.style.fontSize = this.scale + 'px';
  }
}
