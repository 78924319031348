import { Component, OnInit, Input } from '@angular/core';
import { DiamondLayoutService } from '@kanzi-apes/kanzi-diamond-ui';
@Component({
  selector: 'kanzi-footer',
  templateUrl: './kanzi-footer.component.html',
  styleUrls: ['./kanzi-footer.component.scss'],
})
export class KanziFooterComponent implements OnInit {
  @Input() versionName: string;
  year: number = 0;

  get logo() {
    return this.layoutService.config.colorScheme === 'light' ? 'dark' : 'white';
  }

  constructor(private layoutService: DiamondLayoutService) {
    this.versionName = '';
  }

  ngOnInit(): void {
    const now = new Date();
    this.year = now.getFullYear();
  }
}
