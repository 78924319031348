<div
  class="layout-sidebar"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
>
  <div class="sidebar-header">
    <a [routerLink]="['/']" class="logo">
      <img class="logo-image" [src]="logoPath" alt="diamond-ng" />
      <span class="app-name">Kanzi</span>
    </a>
    <button
      class="layout-sidebar-anchor p-link"
      type="button"
      (click)="anchor()"
    ></button>
  </div>
  <div
    #menuContainer
    class="layout-menu-container"
    joyrideStep="mainMenuStep"
    title="Menú Principal"
    text="Menú principal de la aplicación donde se puede acceder a los módulos configurados"
  >
    <ul class="layout-menu">
      <ng-container *ngFor="let item of menuItems; let i = index">
        <li
          kanzi-menuitem
          *ngIf="!item.separator && item.visible"
          [item]="item"
          [index]="i"
          [root]="true"
        ></li>
        <li *ngIf="item.separator" class="menu-separator"></li>
      </ng-container>
    </ul>
  </div>
</div>
