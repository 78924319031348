import { CustomerModel } from './../../customer/customer.models';
import { CheckListSheetsLinesModel } from './../subtask-models/index';
import { EventTaskModel, RevisionModel, WorkPlannerModel } from '..';
import { DataListModel, DataModel } from '../../data';
import { FiltersModel } from '../../filters';
import { LocationModel } from '../../location';
import { SkuModel } from '../../products';
import { UserModel } from '../../user';
import { MaintenanceBudgetOrderModel, Part, TaskPDF } from '../budgets';
import { ContactModel } from '../../contacts';
import { IAPISuggestedTask } from '@kanzi-apes/kanzi-maintenance';
import { MaintenanceTypeModel } from '../../maintenance-configs';

export interface MaintenanceWorkOrderEditModel {
  id: number;
  data: MaintenanceWorkOrderCreateModel;
}
export interface MaintenanceWorkOrderCreateModel {
  sku_id?: number;
  event_task_id?: number;
  event_tasks_ids?: string[];
  status?: string;
  source_id?: number;
  type_id?: number;
  parent_id?: number;
  external_id?: string;
  description?: string;
  start_date?: string;
  finish_date?: string;
  duration_seconds?: number;
  properties?: any;
  params?: any;
  customer_group_id?: number;
  customer_id?: number;
  requester?: any;
  requester_id?: number;
  service_time?: number;
  priority?: string;
}

export interface MaintenanceWorkOrderListModel extends DataListModel {
  results: MaintenanceWorkOrderModel[];
}
export interface MaintenanceWorkOrderModel {
  id: number;
  external_id?: string;
  parent?: any;
  sku: SkuModel;
  sku_display_name?: string;
  sku_external_id?: string;
  status: string;
  description?: string;
  completed_by: UserModel;
  customer_id?: number;
  completed_name?: string;
  source: LocationModel;
  source_display_name?: string;
  event_planner: WorkPlannerModel;
  event_planner_description?: string;
  // event_task: EventTaskModel;
  // event_task_name?: string;
  product_group_name?: string;
  created_by: UserModel;
  finish_date?: string;
  created_name?: string;
  duration_seconds?: number;
  task_list?: any;
  duration_hours?: string;
  source_id?: number;
  start_date?: string;
  properties?: any;
  attachments?: string;
  percentage_progress?: number;
  principal_assign: MaintenanceWorkOrderUserAssignModel;
  active_budget?: MaintenanceBudgetOrderModel;
  start_date_timestamp?: number;
  entrance?: {
    external_id: string;
    name: string;
  };
  requester?: {
    email?: string;
    external_id?: string;
    id: number;
    identification: string;
    last_name: string;
    name: string;
    type_contact: string;
    params?: {
      city?: string;
      contact?: string;
      email?: string;
      discount_rates?: string[];
      special_customer?: boolean;
    };
  } & ContactModel;
  //NEW:
  event_tasks: any[];
  event_tasks_ids: any[];
  spare_parts: null;
  show_returns: null;
  created: string;
  modified: string;
  params: any;
  service_time: number;
  customer_group: null;
  created_timestamp: number;
  modified_timestamp: number;
  finish_date_timestamp: null;
  type: MaintenanceTypeModel;
  type_name?: string;
  priority?: string;
}

export interface MaintenanceWorkOrderUserAssignModel extends DataModel {
  revision: RevisionModel;
  assigned_to: UserModel;
  is_principal: boolean;
  start_date: string;
  finish_date: string;
}
export interface MaintenanceWorkOrderUserAssignListModel extends DataListModel {
  results: MaintenanceWorkOrderUserAssignModel[];
}

export interface MaintenanceWorkOrderUserAssignFilterModel
  extends FiltersModel {
  revision?: number;
  assigned_to_id?: number;
}

export interface MaintenanceWorkOrderUserAssignCreateModel {
  revision_id?: number;
  assigned_to_id: number;
  is_principal: boolean;
  start_date?: string;
  finish_date?: string;
}

export interface MaintenanceWorkOrderUserAssignEditModel {
  id: number;
  data: MaintenanceWorkOrderUserAssignCreateModel;
}

export interface MaintenanceWorkOrderSparePartsCreateModel {
  sku_id: number;
  revision_id?: number;
  status?: string;
  unit_price?: number;
  amount: number;
  // quantity?:number;
}

export interface MaintenanceWorkOrderSparePartsEditModel {
  id: number;
  data: MaintenanceWorkOrderSparePartsCreateModel;
}

export interface MaintenanceWorkOrderSparePartsModel extends DataModel {
  sku: SkuModel;
  revision: MaintenanceWorkOrderModel;
  status: string;
  unit_price: number;
  amount: number;
}

export interface MaintenanceWorkOrderSparePartsListModel extends DataListModel {
  results: MaintenanceWorkOrderSparePartsModel[];
}

export interface MaintenanceWorkOrderSparePartsFilterModel
  extends FiltersModel {
  sku_id?: number;
  revision_id?: number;
  sku__group__name?: string;
}

export interface MaintenanceWorkOrderFormCreateModel {
  work_order_data: MaintenanceWorkOrderCreateModel;
  spare_parts: MaintenanceWorkOrderSparePartsCreateModel[];
  order_assign_principal: MaintenanceWorkOrderUserAssignCreateModel | null;
  order_assign_secondaries: MaintenanceWorkOrderUserAssignCreateModel[];
  revision_event_task: IAPISuggestedTask[];
}

/** Revision traces model */

export interface RevisionTracesFilterModel extends FiltersModel {
  checklist_sheet_id?: string;
  revision_id?: string;
}

export interface RevisionEntranceFilterModel extends FiltersModel {
  client?: string;
  sku_id?: number;
}

export interface EntranceListModel extends DataListModel {
  results: EntranceModel[];
}

export interface EntranceModel {
  external_id: string;
  name: string;
  status: string;
  client_id: number;
  params: {
    priority?: string;
    horometro_motor_diesel?: MeasureModel;
    horometro_motor_electrico?: MeasureModel;
    odometro?: MeasureModel;
  };
  event_task_id: number;
  client: Client;
  event_task: EventTaskModel;
  source: null;
  source_id: null;
  item: {
    id: string;
    sku: {
      id: number;
      external_id: string;
      name: string;
      display_name: string;
      reference: string;
      customer_id: number;
    };
    state: string;
    properties: null;
  };
}

export interface MeasureModel {
  readinds: {
    apply: boolean;
    measure: string;
  };
}

export interface Client {
  id: number;
  external_id: string;
  name: string;
  last_name: string;
  type_contact: string;
  identification: string;
  email: string;
}

export interface RevisionTrecesListModel extends DataListModel {
  results: RevisionTrecesModel[];
}

export interface RevisionTrecesModel {
  id: number;
  created_by: UserModel;
  modified_by: UserModel;
  checklist_sheet_line: CheckListSheetsLinesModel;
  revision: RevisionModel;
  service_state: ServiceState | null;
  created: string;
  modified: string;
  timestamp: number;
  check_results: CheckResults;
  created_timestamp: number;
  modified_timestamp: number;
}

export interface ServiceState {
  id: number;
  external_id: string;
  name: string;
  active: boolean;
  customer_id?: number;
  customer?: CustomerModel;
}

export interface CheckResults {
  comments: string;
  result_value: string;
}

export interface ImageListDetailsModel extends DataListModel {
  results: ImageDetailsModel[];
}

export interface ImageDetailsModel {
  id: number;
  created_by: UserModel;
  modified_by: UserModel;
  revision: RevisionImgModel;
  customer_id: number;
  type: null;
  created: string;
  modified: string;
  name: string;
  photo: string;
  timestamp: number;
  created_timestamp: number;
  modified_timestamp: number;
}
export interface RevisionImgModel {
  id: number;
  external_id: null;
  status: string;
  description: string;
  completed_by_id: null;
  source: SourceImgModel;
  event_task_id: number;
  start_date: string;
  finish_date: string;
  properties: any;
  sku_id: number;
  start_date_timestamp: number;
  finish_date_timestamp: number;
}

export interface SourceImgModel {
  external_id: string;
  display_name: string;
  id: number;
  name: string;
  type: Type;
  parent: Parent;
}

export interface Parent {
  id: number;
  display_name: string;
}

export interface Type {
  id: number;
  external_id: string;
  name: string;
}

/** OT pdf generate models */

export interface workOrderPDFCreateModel {
  templateURI: string;
  dataToParse: DataToParseOTModel;
}

export interface DataToParseOTModel {
  start_date: string;
  finish_date: string;
  comments?: string;
  images:{
    width: number;
    height: number;
    url: string;
    extension: string;
    title:string;
  }[],
  revision: {
    id: string;
    type: string;
    assings_to: string;
    assings_to_parent: string;
  };
  costs?: {
    total_price: string;
    subtotal_price?: string;
    iva_price?: string;
    total_price_iva?: string;
  };
  customer: {
    discount_rate_list?: {
      discount_name: string;
      discount_rate: string;
      discount_value: string;
    }[];
    name: string;
    identification: string;
    properties: {
      contact: string;
      email: string;
      city: string;
    };
  };
  sku: {
    model: string;
    serie: string;
    plate: string;
    horometroDiesel: string;
    horometroElectrico: string;
    odometerMeasure:string;
  };
  parts: Part[];
  tasks: TaskPDF[];
  traces: {
    name: string;
    result_value: string;
    step_number: number;
  }[];
}
