import { SkuModel } from '../products';
import { LocationModel, TypeLocationModel } from '../location';
import { CustomerModel } from '../customer';
import { FiltersModel } from '../filters';
import { UserModel } from '../user';
import { DataListModel } from '../data';

export interface TotalItemsLocationFilter {
  page: number;
  current_location_id: number | null;
}

export interface FilterTotalSummaryModel extends FiltersModel {
  current_location__type_id?: number;
  current_location_id?: number;
  parent_id?: number;
  sku_id?: number;
  type_id?: number;
}

export interface FilterStockLinesModel extends FiltersModel {
  sku_id?: number | string;
  type_id?: number;
  current_location_id?: number;
  item_id__sku_id?: number | string;
  states?: any[];
}

export interface TotalSummaryModel {
  id: number;
  current_location: LocationModel;
  current_location_name?: string;
  current_sub_location?: { code: string };
  current_sub_location_code?: string;
  customer: CustomerModel;
  total_present: number;
  total_in_transfer: number;
  total_lost: number;
  total_packed?: number;
  total_dispatch?: number;
  total_reserved?: number;
  total_difference?: number;
  total: number;
  parent?: number;
  location?:any
}

export interface TotalSummayList extends DataListModel {
  count: number;
  next: string | null;
  previus: string | null;
  results: TotalSummaryModel[];
}

export interface DrillInfo {
  text: string;
  node?: any;
}

export interface TreeMapListModel {
  id?: number;
  name: string;
  value?: number;
  items: any[];
}

export interface StockLineListModel {
  count: number;
  next: string | null;
  previous: string | null;
  results: StockLineModel[];
}

export interface StockLineModel {
  id: number;
  customer: CustomerModel;
  current_location: LocationModel;
  type: TypeLocationModel;
  sku: SkuModel;
  sku_id?: number | string;
  sku_display_name?: string;
  sku_external_id?: string | number;
  sku_collection?: string;
  sku_color?: string;
  total_issued: number;
  total_present: number;
  total_in_transfer: number;
  total_lost: number;
  total_not_present: number;
  total_packed: number;
  total_dispatch_pending?: number;
  total_dispatch?: number;
  total_reserved?: number;
  total_difference?: number;
  total: number;
  customer_id?: number;
}
export interface StockSummaryFilter extends FiltersModel {
  sku?: string;
  sku_id?: number | string;
  current_location?: string;
  current_location_id?: number;
  sub_location?: string;
  state?: string;
}

export interface IBooking {
  sku: SkuModel;
  amount: number;
  bookingDate: string;
  user: UserModel;
  location: LocationModel;
  mode?: string;
}

export interface IBookSku {
  reserved: boolean;
  sku_id: number;
  location_id: number;
  value: number;
  cut_date?: string;
}
