import { DataModel, DataListModel } from '../data';
import { FiltersModel } from '../filters';
import { SkuModel } from '../products';
import { LocationModel } from '../location';
import { UserModel } from '../user';
import { HardwareModel } from '../hardware';

export interface PortalAlertModel extends DataModel {
  tags: EventTagModel[];
  comment: string;
  node: HardwareModel;
  node_name?: string;
  status: string;
  location_name: string;
  total_amount?: number;
}
export interface PortalAlertFilterModel extends FiltersModel {}
export interface PortalAlertListModel extends DataListModel {
  results: PortalAlertModel[];
}

export interface EventTagModel extends DataModel {
  name: string;
  display_name: string;
  active: boolean;
  description?: string;
}
export interface CreateEventTagModel {
  name: string;
  description: string;
}
export interface EventTraceLineModel extends DataModel {
  sku: SkuModel;
  event: PortalAlertModel;
  amount: number;
  items: {
    items: string[];
  };
}
export interface EventTraceLineListModel extends DataListModel {
  results: EventTraceLineModel[];
}

export interface ActivityModel extends DataModel {
  location: LocationModel;
  sub_location: LocationModel;
  reporter: UserModel;
  reporter_source: string;
  action: string;
  description: string;
  additional_data: any;
  item: string;
}

export interface ActivityModelListModel extends DataListModel {
  results: ActivityModel[];
}
