import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducers } from './reducers';
import { CustomerListEffects } from './effects/customers/customer-list.effects';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    StoreModule.forFeature('customer', reducers),
    EffectsModule.forFeature([CustomerListEffects]),
  ],
})
export class KanziCustomerModule {}
