import { UserModel, AuditModel, LocationModel } from '../../../interfaces';
import { Location } from '../../location';

export class Audit implements AuditModel {
  id                    !: number;
  created_by            !: UserModel;
  modified_by           !: UserModel;
  cut_date              !: string;
  created               !: Date;
  modified              !: Date;
  location              !: LocationModel;
  sub_location          !: any;
  prefix                !: string;
  external_id           !: string;
  status                !: string;
  adjusted              !: boolean;
  audit_items_file?     : string;
  expected_items_file?  : string;
  adjust_items_file?    : string;
  audit_assets_file?    : string;
  expected_assets_file? : string;
  adjust_assets_file?   : string;
  extra_items_file?     : string;
  summary_results_file  !: string;
  total_expected_items? : number;
  total_audit_items?    : number;
  total_found_items?    : number;
  total_missing_items?  : number;
  total_extra_items?    : number;
  total_expected_assets?: number;
  total_audit_assets?   : number;
  total_found_assets?   : number;
  total_missing_assets? : number;
  total_extra_assets?   : number;
  created_timestamp     !: number;
  modified_timestamp    !: number;
  cut_date_timestamp    !: number;

  constructor() {
    this.location = new Location();
  }
}
