import { Action } from '@ngrx/store';
import {
  CustomerModel,
  CustomerFilterModel,
  CustomerListModel,
  CustomerGroupFilterModel,
  CustomerGroupListModel,
} from '@kanzi-apes/kanzi-models';

export enum CustomersActionTypes {
  Load = '[Customer] Load',
  LoadComplete = '[Customer] Load Complete',
  LoadError = '[Customer] Load Error',
  LoadList = '[Customer] Load List',
  LoadListComplete = '[Customer] Load List Complete',
  LoadListError = '[Customer] Load List Error',
  LoadGroup = '[Customer] Load Groups',
  LoadGroupComplete = '[Customer] Load Groups complete',
  Selected = '[Customer] Select',
}

export class Load implements Action {
  readonly type = CustomersActionTypes.Load;

  constructor(public payload: number) {}
}

export class LoadComplete implements Action {
  readonly type = CustomersActionTypes.LoadComplete;

  constructor(public payload: CustomerModel) {}
}

export class LoadError implements Action {
  readonly type = CustomersActionTypes.LoadError;

  constructor(public payload: any) {}
}

export class LoadList implements Action {
  readonly type = CustomersActionTypes.LoadList;

  constructor(public payload: CustomerFilterModel) {}
}

export class LoadListComplete implements Action {
  readonly type = CustomersActionTypes.LoadListComplete;

  constructor(public payload: CustomerListModel) {}
}

export class LoadListError implements Action {
  readonly type = CustomersActionTypes.LoadListError;

  constructor(public payload: any) {}
}

export class Selected implements Action {
  readonly type = CustomersActionTypes.Selected;

  constructor(public payload: CustomerModel) {}
}

export class LoadGroup implements Action {
  readonly type = CustomersActionTypes.LoadGroup;

  constructor(public payload: CustomerGroupFilterModel) {}
}

export class LoadGroupComplete implements Action {
  readonly type = CustomersActionTypes.LoadGroupComplete;

  constructor(public payload: CustomerGroupListModel) {}
}

export type CustomerActions =
  | Load
  | LoadComplete
  | LoadError
  | LoadList
  | LoadListComplete
  | LoadListError
  | Selected
  | LoadGroup
  | LoadGroupComplete;
