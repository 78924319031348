import { createSelector, createFeatureSelector, Store } from '@ngrx/store';
// import * as fromRoot from '../../reducers';
import * as fromCustomers from './customers/customers';
import * as fromCustomer from './customers/customer';

export interface CustomerState {
  list: fromCustomers.State;
  customer: fromCustomer.State;
}

export interface State { // extends fromRoot.State {
  customer: CustomerState;
}

export const reducers = {
  list: fromCustomers.reducer,
  customer: fromCustomer.reducer,
};

export const getCustomerState = createFeatureSelector<CustomerState>(
  'customer'
);
export const selectCustomers = createSelector(
  getCustomerState,
  (state: CustomerState) => state.list
);

export const selectCustomer = createSelector(
  getCustomerState,
  (state: CustomerState) => state.customer
);

export const getCustomersLoading = createSelector(
  selectCustomers,
  fromCustomers.getLoading
);

export const getCustomersLoaded = createSelector(
  selectCustomers,
  fromCustomers.getLoaded
);
export const getCustomersList = createSelector(
  selectCustomers,
  fromCustomers.getList
);
export const getCustomersFilter = createSelector(
  selectCustomers,
  fromCustomers.getFilter
);
export const getCustomersError = createSelector(
  selectCustomers,
  fromCustomers.getError
);

export const getListGroups = createSelector(
  selectCustomers,
  fromCustomers.getListGroups
);

export const getCustomerSelected = createSelector(
  selectCustomer,
  fromCustomer.getSelected
);





