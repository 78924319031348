import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ElementRef,
  ViewChild,
  inject,
  input,
} from '@angular/core';
import {
  CustomerListModel,
  UserModel,
  CustomerModel,
  UserRoles,
  AddComponent,
  KanziEnvironmentModel,
  LocationModel,
  WarehouseListModel,
  WarehouseModel,
} from '@kanzi-apes/kanzi-models';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';
import { LayoutService } from '../../services/layout/layout.service';
import { co } from '@fullcalendar/core/internal-common';
@Component({
  selector: 'kanzi-ui-top-bar',
  templateUrl: './kanzi-ui-top-bar.component.html',
  styleUrls: ['./kanzi-ui-top-bar.component.scss'],
})
export class KanziUiTopBarComponent {
  @ViewChild('menuButton') menuButton!: ElementRef;
  @ViewChild('mobileMenuButton') mobileMenuButton!: ElementRef;
  warehouseRootList = input<WarehouseListModel>();
  warehouseRootSelected = input<WarehouseModel | null>();
  @Output() selectWarehouseRoot: EventEmitter<WarehouseModel> =
    new EventEmitter();

  layoutService: LayoutService = inject(LayoutService);
  activeItem!: number;
  languajes = [
    { value: 'en', label: 'Inglés', code: 'us' },
    { value: 'es', label: 'Español', code: 'es' },
  ];

  /**
   * Constructor
   * @param el {ElementRef} - ElementRef
   */
  constructor(public el: ElementRef) {}

  /**
   * Get mobile topbar active
   * return {boolean}
   */
  get mobileTopbarActive(): boolean {
    return this.layoutService.state.topbarMenuActive;
  }

  /**
   * Get menu toggle action
   */
  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  /**
   * Get mobile topbar menu button click
   */
  onMobileTopbarMenuButtonClick() {
    this.layoutService.onTopbarMenuToggle();
  }

  onSelectWarehouse(warehouseRoot: WarehouseModel) {
    this.selectWarehouseRoot.emit(warehouseRoot);
  }

  get logo() {
    const logo =
      this.layoutService._config.menuTheme === 'light' ||
      this.layoutService._config.menuTheme === 'dark'
        ? 'dark'
        : 'white';
    return logo;
  }
}
