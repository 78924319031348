<p-table
  #dt
  [autoLayout]="true"
  [columns]="colSource"
  [lazy]="configs ? (configs.lazy ? configs.lazy : false) : false"
  [value]="dataListTable"
  [dataKey]="configs ? (configs.key ? configs.key : '') : ''"
  [paginator]="
    configs ? (configs.paginator ? configs.paginator : false) : false
  "
  [rows]="configs ? (configs.rows ? configs.rows : 0) : 0"
  [sortMode]="
    configs ? (configs.sortMode ? configs.sortMode : 'single') : 'single'
  "
  [totalRecords]="dataSource ? dataSource.count : 0"
  [lazyLoadOnInit]="false"
  [loading]="pending ? pending : false"
  [resizableColumns]="true"
  columnResizeMode="expand"
  [styleClass]="configs ? (configs.expandRow ? 'rowexpand-table' : '') : ''"
  [exportFilename]="configs ? (configs.fileName ? configs.fileName : '') : ''"
  [(selection)]="itemsSelected"
  (onLazyLoad)="loadLazy($event)"
  (onRowExpand)="onRowExpand($event)"
  (onFilter)="onFilter($event)"
  (onRowSelect)="onRowSelected($event)"
  (sortFunction)="sortFunction($event)"
  [customSort]="true"
  [breakpoint]="'960px'"
  [scrollable]="true"
  [scrollHeight]="
    configs ? (configs.scrollHeight ? configs.scrollHeight : '400px') : '400px'
  "
  responsiveLayout="scroll"
  scrollDirection="both"
  [virtualScroll]="
    configs ? (configs.virtualScroll ? configs.virtualScroll : false) : false
  "
  [virtualScrollItemSize]="
    configs
      ? configs.virtualScrollItemSize
        ? configs.virtualScrollItemSize
        : 40
      : 40
  "
>
  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between">
      <span class="mb-2 text-base"
        >{{ 'Total Registros' | translate }}:
        {{
          (dataSource?.count ? dataSource?.count : dataListTable.length)
            | number
        }}</span
      >
      <span class="mb-2 text-base"
        >{{ 'Total Filtrados' | translate }}:
        {{ filteredTotals | number }}</span
      >
      <span *ngIf="configs?.search" class="p-input-icon-left mb-2">
        <i class="pi pi-search"></i>
        <input
          type="text"
          class="form-control"
          pInputText
          placeholder="{{ 'Buscar' | translate }}..."
          (input)="onSearch($any($event.target).value, 'contains')"
          [formControl]="searchFilter"
        />
      </span>

      <p-calendar
        *ngIf="configs && configs.filters && configs.filters.date"
        #rangeDate
        selectionMode="range"
        dateFormat="dd/mm/yy"
        dataType="date"
        placeholder="{{ 'Rango de Fechas' | translate }}"
        [showButtonBar]="true"
        [showIcon]="true"
        [readonlyInput]="true"
        [touchUI]="false"
        [(ngModel)]="rangeDateCalendar"
        (onClearClick)="clearFilters(true, dt)"
        (onSelect)="onFilterDateTable(rangeDate.value)"
      ></p-calendar>

      <div class="p-col mt-4 sm:mt-0" *ngIf="isDownloadAll">
        <button
          #btn
          alt="Opciones"
          pTooltip="Descargar todos los registros"
          tooltipPosition="bottom"
          type="button"
          pButton
          icon="fa-solid fa-download"
          class="mr-2 p-button-help p-button-outlined p-button-sm"
          (click)="menu.toggle($event)"
        ></button>
        <p-tieredMenu
          #menu
          [model]="items"
          [popup]="true"
          appendTo="body"
        ></p-tieredMenu>
      </div>

      <div class="p-col mt-4 sm:mt-0">
        <!-- Menu options -->
        <button
          *ngIf="configs?.downloadFile && !isDownloadAll"
          #btn
          alt="Opciones"
          pTooltip="Descargar los registros filtrados"
          tooltipPosition="bottom"
          type="button"
          pButton
          icon="pi pi-list"
          class="mr-2 p-button-help p-button-outlined p-button-sm"
          (click)="menu.toggle($event)"
        ></button>
        <p-tieredMenu
          #menu
          [model]="items"
          [popup]="true"
          appendTo="body"
        ></p-tieredMenu>
        <!-- End Menu options -->

        <!-- Limpiar Filtros -->
        <button
          *ngIf="(configs?.downloadFile || configs?.filters) && columSelect"
          type="button"
          pButton
          alt="Filtrar"
          pTooltip="Limpiar filtros"
          tooltipPosition="bottom"
          icon="pi pi-filter-slash"
          class="mr-2 p-button-info p-button-outlined p-button-sm"
          (click)="clearAllFilters(dt)"
        ></button>
        <!-- Limpiar Filtros -->

        <!-- Filter opcion -->
        <button
          *ngIf="
            configs?.downloadFile &&
            configs?.filters &&
            (configs?.displayFilters ?? true)
          "
          type="button"
          pButton
          alt="Filtrar"
          pTooltip="Filtrar"
          tooltipPosition="bottom"
          icon="pi pi-filter"
          class="mr-2 p-button-info p-button-outlined p-button-sm"
          (click)="showFilters()"
        ></button>
        <!-- Filter opcions -->

        <button
          type="button"
          alt="Refrescar"
          pTooltip="Refrescar"
          tooltipPosition="bottom"
          pButton
          icon="pi pi-refresh"
          class="p-button-outlined p-button-sm"
          iconPos="left"
          (click)="clearFilters(true, dt)"
        ></button>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th style="width: 3rem" *ngIf="configs?.multiSelect">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th style="width: 3rem" *ngIf="configs?.onlySelect"></th>
      <th style="width: 3rem" *ngIf="configs?.expandRow"></th>
      <th
        *ngFor="let col of columns"
        [pSortableColumn]="col.field"
        [pSortableColumnDisabled]="!col.sortable"
        pResizableColumn
        (click)="customSort(col)"
        [alignFrozen]="col.alignFrozen"
        [frozen]="col.frozen"
        pFrozenColumn
        style="min-width: 100px"
      >
        <div class="flex justify-content-between align-content-center text-sm">
          {{ col.header | translate }}
          <p-sortIcon
            [hidden]="!col.sortable"
            [field]="col.field"
            ariaLabel="Activate to sort"
            ariaLabelDesc="Activate to sort in descending order"
            ariaLabelAsc="Activate to sort in ascending order"
          ></p-sortIcon>
        </div>
      </th>
      <th *ngIf="configs?.options">Acciones</th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-rowData
    let-columns="columns"
    let-expanded="expanded"
  >
    <tr class="p-selectable-row" style="height: 46px">
      <td *ngIf="configs?.multiSelect" style="text-overflow: initial">
        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
      </td>
      <td *ngIf="configs?.onlySelect" style="text-overflow: initial">
        <p-tableRadioButton [value]="rowData"></p-tableRadioButton>
      </td>
      <td *ngIf="configs?.expandRow">
        <a href="#" [pRowToggler]="rowData">
          <i
            [ngClass]="expanded ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"
          ></i>
        </a>
      </td>
      <td
        *ngFor="let col of columns"
        [ngSwitch]="col.pipe"
        [ngStyle]="{ width: col.colWidht ? col.widht : null }"
        [style]="{ overflow: 'hidden', 'text-overflow': 'ellipsis' }"
        [alignFrozen]="col.alignFrozen"
        [frozen]="col.frozen"
        pFrozenColumn
        class="p-1"
      >
        <div
          *ngIf="col.subfield; then nested_object_content; else normal_content"
        ></div>
        <ng-template #nested_object_content>
          <div *ngIf="rowData[col.field]" class="text-sm">
            {{ rowData[col.field][col.subfield] }}
          </div>
        </ng-template>
        <ng-template #normal_content>
          <div *ngSwitchCase="true" class="text-sm">
            <!-- <span [appStatusStyle]="rowData[col.field]">{{
              rowData[col.field]
            }}</span> -->
            <span>{{ rowData[col.field] }}</span>
          </div>
          <div *ngSwitchDefault>
            @if (col.typeData === 'boolean') {
            <span>
              <i
                class="pi"
                [ngClass]="{
                  'true-icon pi-check-circle text-green-500':
                    rowData[col.field],
                  'false-icon pi-times-circle text-pink-500 ':
                    !rowData[col.field]
                }"
              ></i>
            </span>
            }
            @else if(col.typeData === 'date'){
            <span>
              {{ rowData[col.field] | date : 'dd/MM/yyyy:HH:MM' }}
            </span>
            }
            @else if(col.typeData === 'tag'){
            <p-tag
              [value]="rowData[col.field] | translate"
              [severity]="rowData[col.field] | kanziStatusSeverity"
            ></p-tag>
            } @else {
            <span class="text-sm"> {{ rowData[col.field] }}</span>
            }

            <!-- <span
              *ngIf="col.typeData !== 'boolean' || !col.typeData"
              [appStatusStyle]="rowData[col.field]"
              class="text-sm"
            >
              {{ rowData[col.field] }}</span
            > -->
          </div>
        </ng-template>
      </td>
      <td *ngIf="configs && configs.options">
        <div class="flex">
          <button
            *ngIf="configs && configs.options && configs.options.view"
            pButton
            pRipple
            pTooltip="{{ 'Ver Detalles' | translate }}"
            tooltipPosition="top"
            icon="pi pi-eye"
            class="p-button-rounded p-button-success mr-2"
            (click)="onRowSelect(rowData)"
          ></button>
          <button
            *ngIf="configs && configs.options && configs.options.edit"
            pButton
            pRipple
            pTooltip="{{ 'Editar' | translate }}"
            tooltipPosition="top"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-warning mr-2"
            (click)="onRowEdit(rowData)"
          ></button>
          <button
            *ngIf="configs && configs.options && configs.options.delete"
            pButton
            pRipple
            pTooltip="{{ 'Eliminar' | translate }}"
            tooltipPosition="top"
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger mr-2"
            (click)="onRowDelete(rowData)"
          ></button>
          <button
            *ngIf="configs && configs.options && configs.options.customButton"
            pButton
            pRipple
            pTooltip="{{
              configs.options.customButtonOptions
                ? configs.options.customButtonOptions.tooltipLabel
                : ('' | translate)
            }}"
            tooltipPosition="top"
            [icon]="configs.options.customButtonOptions?.icon!"
            class="p-button-rounded p-button-help mr-2"
            (click)="onClickButtonCustom(rowData)"
          ></button>
          <button
            *ngIf="configs && configs.options && configs.options.unsubscribe"
            pButton
            pRipple
            pTooltip="{{
              rowData.status === 'ACTIVE' ? 'Dar de Baja' : 'Activar'
            }}"
            tooltipPosition="top"
            icon="{{
              rowData.status === 'ACTIVE' ? 'pi pi-ban' : 'pi pi-shield'
            }}"
            class="{{
              rowData.status === 'ACTIVE'
                ? 'p-button-rounded p-button-secondary'
                : 'p-button-rounded p-button-help '
            }}"
            (click)="onRowUnsubscribe(rowData)"
          ></button>
          <span *ngIf="configs && configs.options && configs.options.external">
            <a class="text-red">
              <i class="fa fa-link"></i>
            </a>
          </span>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length">
        {{ emptyMsg | translate }}
      </td>
    </tr>
  </ng-template>
  <ng-template
    *ngIf="configs?.expandRow"
    pTemplate="rowexpansion"
    let-rowData
    let-columns="columns"
  >
    <tr>
      <td
        [attr.colspan]="
          configs?.options ? columns.length + 3 : columns.length + 1
        "
      >
        <div class="row">
          <div class="col-lg-12">
            <!--  <kanzi-dynamic-component
              [dynamicComponent]="dynamicComponent"
              [data]="rowData"
            ></kanzi-dynamic-component> -->
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-sidebar
  position="right"
  [autoZIndex]="false"
  [draggable]="false"
  [modal]="false"
  [(visible)]="showFilter"
  [style]="{ width: '25em' }"
>
  <form [formGroup]="formFilters">
    <div class="grid mt-3">
      <div class="col-6">
        <h4>Aplicar Filtros</h4>
      </div>
      <div class="col-6">
        <button
          pButton
          label="Limpiar"
          class="p-button-outlined p-button-sm"
          icon="pi pi-filter-slash"
          (click)="clearAllFilters(dt)"
        ></button>
      </div>
    </div>

    <div class="fluid" *ngFor="let col of colsOptionsFilter">
      <label for="multiselect mb-0">{{ col.header | titlecase }}</label>
      <p-multiSelect
        inputId="multiselect"
        [options]="col.filterOptions"
        [style]="{
          width: '100%',
          'margin-bottom': '30px',
          'margin-top': '10px'
        }"
        [formControlName]="col.field"
        [panelStyle]="{ Width: '100%' }"
        (onClick)="selectFilter(null, col, null)"
      >
        <ng-template let-value pTemplate="selectedItems">
          <div *ngFor="let val of value">
            <span>{{ val }}</span>
          </div>
          <span *ngIf="!value || value.length === 0">{{
            col.labelFilter
          }}</span>
        </ng-template>
        <ng-template let-types pTemplate="item">
          <div
            style="
              font-size: 14px;
              margin-top: 4px;
              text-align: left;
              display: inline-block;
            "
          >
            {{ types.value }}
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
  </form>
</p-sidebar>

<p-dialog
  header="Descargando archivos"
  [(visible)]="isVisibleProgressBar"
  [style]="{ width: '50vw' }"
>
  <p>{{ progressBarService.progress$().packet }}</p>
  <p-progressBar
    [value]="progressBarService.progress$().percent"
  ></p-progressBar>
</p-dialog>
