<p-table
  [value]="userListData"
  [rows]="5"
  [paginator]="true"
  [totalRecords]="userListData.length"
  [responsive]="true"
  [styleClass]="'kanzi-ui'"
  [resizableColumns]="true"
  editMode="row"
  dataKey="id"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th>{{ 'Usuario' | translate }}</th>
      <th>{{ 'Nombre' | translate }}</th>
      <th>{{ 'Rol' | translate }}</th>
      <th>{{ 'Ubicación' | translate }}</th>
      <th style="width: 8em"></th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-user
    let-editing="editing"
    let-ri="rowIndex"
  >
    <tr [pEditableRow]="user" id="rowsDatas__tr_overflow">
      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-autoComplete
              [(ngModel)]="user.data"
              [suggestions]="usersListFiltered"
              (completeMethod)="filterUsers($event)"
              [minLength]="1"
              placeholder="{{ 'Buscar Usuario' | translate }} "
              class="autoCompleteList"
              field="username"
              dataKey="username"
              appendTo="body"
            ></p-autoComplete>
          </ng-template>
          <ng-template pTemplate="output">
            {{ user?.data.username }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td>{{ user?.data.first_name }} {{ user?.data.last_name }}</td>
      <td>{{ user?.data.configs?.role?.name }}</td>
      <td>{{ user?.data.configs?.default_location?.display_name }}</td>
      <td style="text-align: center">
        <button
          *ngIf="!editing"
          pButton
          type="button"
          pInitEditableRow
          icon="pi pi-pencil"
          class="p-button-warning mr-2"
          (click)="onRowEditInit(user)"
        ></button>
        <button
          *ngIf="!editing"
          pButton
          type="button"
          icon="pi pi-trash"
          class="p-button-danger mr-2"
          (click)="onRowDelete(ri, user)"
        ></button>
        <button
          *ngIf="editing"
          pButton
          type="button"
          pSaveEditableRow
          icon="pi pi-check"
          class="p-button-success mr-2"
          (click)="onRowEditSave(user, ri)"
        ></button>
        <button
          *ngIf="editing"
          pButton
          type="button"
          pCancelEditableRow
          icon="pi pi-times"
          class="p-button-danger"
          (click)="onRowEditCancel(user, ri)"
        ></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary">
    <div class="lex justify-content-start">
      <button
        icon="pi pi-plus"
        type="button"
        class="p-button-help mr-3"
        pButton
        pRipple
        (click)="addUserToList()"
      ></button>
    </div>
  </ng-template>
</p-table>
<p-toast></p-toast>
