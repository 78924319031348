import {
  Directive,
  Input,
  ElementRef,
  OnInit,
  HostBinding,
} from '@angular/core';
import { KanziStatus } from '@kanzi-apes/kanzi-models';
/**
 * @author Hugo Andrés Escobar Ciceri
 * @version 5.0.0
 *
 * Directive to give a Style to text with status word.
 */
@Directive({
  selector: '[appStatusStyle]',
})
export class StatusStyleDirective implements OnInit {
  @Input('appStatusStyle') status: string | boolean;
  @HostBinding('class')
  elemClass = '';

  constructor(private el: ElementRef) {
    this.status = '';
  }

  ngOnInit() {
    switch (this.status) {
      case KanziStatus.COMPLETED: {
        this.elemClass = 'customer-badge status-qualified';
        break;
      }
      case KanziStatus.PRINTED: {
        this.elemClass = 'customer-badge status-qualified';
        break;
      }
      case KanziStatus.DISPATCHED: {
        this.elemClass = 'customer-badge status-qualified';
        break;
      }

      case KanziStatus.DISTRIBUTED: {
        this.elemClass = 'customer-badge status-negotiation';
        break;
      }

      case KanziStatus.ADJUSTED: {
        this.elemClass = 'customer-badge status-qualified';
        break;
      }
      case KanziStatus.ENLISTED: {
        this.elemClass = 'customer-badge status-new';
        break;
      }
      case KanziStatus.FINISHED: {
        this.elemClass = 'customer-badge status-proposal';
        break;
      }
      case KanziStatus.INCOMPLETE: {
        this.elemClass = 'customer-badge status-negotiation';
        break;
      }
      case KanziStatus.IN_PROCESS: {
        this.elemClass = 'customer-badge status-negotiation';
        break;
      }
      case KanziStatus.IN_PROGRESS: {
        this.elemClass = 'customer-badge status-negotiation';
        break;
      }
      case KanziStatus.PROCESSING: {
        this.elemClass = 'customer-badge status-negotiation';
        break;
      }
      case KanziStatus.VERIFIED: {
        this.elemClass = 'customer-badge status-qualified';
        break;
      }
      case KanziStatus.CANCELLED: {
        this.elemClass = 'customer-badge status-unqualified';
        break;
      }
      case KanziStatus.ABORTED: {
        this.elemClass = 'customer-badge status-unqualified';
        break;
      }
      case KanziStatus.INVALID: {
        this.elemClass = 'customer-badge status-unqualified';
        break;
      }
      case KanziStatus.FAILED: {
        this.elemClass = 'customer-badge status-unqualified';
        break;
      }
      case KanziStatus.ISSUED: {
        this.elemClass = 'customer-badge status-new';
        break;
      }
      case KanziStatus.DRAFT: {
        this.elemClass = 'customer-badge status-renewal';
        break;
      }
      case KanziStatus.CREATED: {
        this.elemClass = 'customer-badge status-new';
        break;
      }
      case KanziStatus.EXPIRED: {
        this.elemClass = 'customer-badge status-renewal';
        break;
      }
      case KanziStatus.BLOCKED: {
        this.elemClass = 'customer-badge status-unqualified';
        break;
      }
      case KanziStatus.CLOSED: {
        this.elemClass = 'customer-badge status-unqualified';
        break;
      }
      case KanziStatus.PRESENT: {
        this.elemClass = 'customer-badge status-qualified';
        break;
      }
      case KanziStatus.NOT_PRESENT: {
        this.elemClass = 'customer-badge status-negotiation';
        break;
      }
      case KanziStatus.REJECTED: {
        this.elemClass = 'customer-badge status-unqualified';
        break;
      }
      case KanziStatus.APPROVED: {
        this.elemClass = 'customer-badge status-qualified';
        break;
      }
      case true: {
        this.elemClass = 'pi pi-check-circle text-green-500';
        break;
      }
      case false: {
        this.elemClass = 'pi pi-times-circle text-pink-600';
        break;
      }
      case KanziStatus.DEMORADO: {
        this.elemClass = 'label label-danger';
        break;
      }
      case KanziStatus['A TIEMPO']: {
        this.elemClass = 'label label-success';
        break;
      }
      case KanziStatus.BUDGET_PENDING: {
        this.elemClass = 'customer-badge status-unqualified';
        break;
      }
      case KanziStatus.BY_ASSIGN: {
        this.elemClass = 'customer-badge status-new';
        break;
      }
      case KanziStatus.MAINTENANCE_PENDING: {
        this.elemClass = 'customer-badge status-new';
        break;
      }
      case KanziStatus.SHEDULED: {
        this.elemClass = 'customer-badge status-new';
        break;
      }

      default:
        break;
    }
  }
}
