export enum TypeIdentification {
  CC = 'CC',
  NIT = 'NIT',
  TI = 'TI',
  PASSPORT = 'PASSPORT',
}

export enum ContactType {
  PROVIDER = 'PROVIDER',
  CELL_PROVIDER = 'CELL_PROVIDER',
  CLIENT = 'CLIENT',
}
