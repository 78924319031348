export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('kanziState');
    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('kanziState', serializedState);
  } catch (error) {
    // log errors
  }
};

export const saveKanziTour = (data: {
  tourCompleted: boolean;
  kanziVersion: string;
}) => {
  try {
    const kanziTourData = JSON.stringify(data);
    localStorage.setItem('kanziTour', kanziTourData);
  } catch (error) {}
};

export const loadKanziTour = ():
  | {
      tourCompleted: boolean;
      kanziVersion: string;
    }
  | undefined => {
  try {
    const kanziTourData = localStorage.getItem('kanziTour');
    if (kanziTourData === null) {
      return undefined;
    }

    return JSON.parse(kanziTourData);
  } catch (error) {
    return undefined;
  }
};
