<aside class="control-sidebar control-sidebar-light" [@collapseSettings]="event">
    <!-- Create the tabs -->
    <ul class="nav nav-tabs nav-justified control-sidebar-tabs">
      <li class="active"><a href="#control-sidebar-home-tab" data-toggle="tab" aria-expanded="false"><i class="fa fa-home"></i></a></li>
      <li class=""><a href="#control-sidebar-theme-demo-options-tab" data-toggle="tab" aria-expanded="true"><i class="fa fa-wrench"></i></a></li>
      <li class=""><a href="#control-sidebar-settings-tab" data-toggle="tab" aria-expanded="false"><i class="fa fa-gears"></i></a></li>
    </ul>
    <!-- Tab panes -->
    <div class="tab-content">
      <!-- Home tab content -->
      <div class="tab-pane active" id="control-sidebar-home-tab">
        <h3 class="control-sidebar-heading">{{'Actividad Reciente' | translate}}</h3>

        <h3 class="control-sidebar-heading">{{'Tareas' | translate}}</h3>

      </div>
      <div id="control-sidebar-theme-demo-options-tab" class="tab-pane">
      	<div>
      		<h4 class="control-sidebar-heading">{{'Configuración del Sistema' | translate}}</h4>
      	</div>
      </div>
      <!-- /.tab-pane -->
      <!-- Settings tab content -->
      <div class="tab-pane" id="control-sidebar-settings-tab">
      	<div>
      		<h4 class="control-sidebar-heading">{{'Configuración General' | translate}}</h4>
      	</div>
      </div>
      <!-- /.tab-pane -->
    </div>
</aside>
<div class="control-sidebar-bg" style="position: fixed; height: auto;" [@collapseSettings]="event"></div>