import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as ngAnimation from '@angular/animations';
import { CustomerModel, CustomerListModel } from '@kanzi-apes/kanzi-models';

@Component({
  selector: 'app-customer-selector',
  templateUrl: './customer-selector.component.html',
  styleUrls: ['./customer-selector.component.scss'],
  animations: [
    ngAnimation.trigger('collapse', [
      ngAnimation.state(
        'open',
        ngAnimation.style({
          opacity: '1',
          display: 'block',
          transform: 'translate3d(0,0,0)',
        })
      ),
      ngAnimation.state(
        'closed',
        ngAnimation.style({
          opacity: '0',
          display: 'none',
          transform: 'translate3d(0,-100%,0)',
        })
      ),
      ngAnimation.transition(
        'closed => open',
        ngAnimation.animate('200ms ease-in')
      ),
      ngAnimation.transition(
        'open => closed',
        ngAnimation.animate('100ms ease-out')
      ),
    ]),
  ],
})
export class CustomerSelectorComponent implements OnInit {
  @Input() customers: CustomerListModel | null;
  @Input() customerSelected: CustomerModel | null;
  @Input() collapseMenu: string;
  @Output() selectCustomer: EventEmitter<CustomerModel> = new EventEmitter();

  constructor() {
    this.customers = null;
    this.customerSelected = null;
    this.collapseMenu = '';
  }

  ngOnInit() {
    this.collapseMenu = 'closed';
  }

  /**
   *
   * @param section {string} Component name to activate the animation.
   *
   * Function to activate the animation.
   */
  collapseTop(section: string) {
    switch (section) {
      case 'notification':
        this.collapseMenu = this.collapseMenu == 'open' ? 'closed' : 'open';
        break;
      default:
        break;
    }
  }

  onSelectCustomer(customer: CustomerModel) {
    if (this.customerSelected && customer.id !== this.customerSelected.id) {
      this.selectCustomer.emit(customer);
    }
  }
}
