import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MenuItem } from '@kanzi-apes/kanzi-prime-ui';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'kanzi-menu-rigth',
  templateUrl: './menu-rigth.component.html',
  styleUrls: ['./menu-rigth.component.scss'],
})
export class MenuRigthComponent implements OnInit, OnChanges {
  @Input() modules: any | null;
  @Input() rolAccess: string | null;
  itemsMenu: MenuItem[] = [];
  accessVisible: boolean | null;
  constructor() {
    this.modules = null;
    this.rolAccess = null;
    this.accessVisible = null;
  }
  ngOnInit() {}
  ngOnChanges() {
    if (this.rolAccess === 'SUPER_ADMIN' || this.rolAccess === 'ADMIN') {
      this.accessVisible = true;
    } else {
      this.accessVisible = false;
    }
    this.itemsMenu = [
      {
        label: 'Configuraciones',
        expanded: true,
        disabled: this.accessVisible ? this.accessVisible : false,
        items: [
          {
            label: 'Locaciones',
            icon: 'fa fa-globe',
            visible: this.modules.locations,
            items: [
              {
                label: 'Crear Nuevo',
                icon: 'fa fa-plus-square',
                routerLink: 'configurations/location/create',
              },
              {
                label: 'Listado',
                icon: 'fa fa-list-ul',
                routerLink: 'configurations/location/list',
              },
            ],
          },
          {
            label: 'Customers',
            icon: 'fas fa-user-friends',
            visible: this.modules.customers,
            disabled: this.accessVisible ? !this.accessVisible : true,
            items: [
              {
                label: 'Crear Nuevo',
                icon: 'fa fa-plus-square',
                routerLink: 'configurations/customer/create',
              },
              {
                label: 'Listado',
                icon: 'fa fa-list-ul',
                routerLink: 'configurations/customer',
              },
            ],
          },
          {
            label: 'Usuarios',
            icon: 'fas fa-users',
            visible: this.modules.users,
            disabled: this.accessVisible ? !this.accessVisible : true,
            items: [
              {
                label: 'Crear Nuevo',
                icon: 'fa fa-plus-square',
                routerLink: 'configurations/user/create',
              },
              {
                label: 'Listado',
                icon: 'fa fa-list-ul',
                routerLink: 'configurations/user',
              },
            ],
          },
          {
            label: 'Mantenimientos',
            icon: 'fa-solid fa-road-barrier',
            visible: this.modules.maintenances,
            disabled: this.accessVisible ? !this.accessVisible : true,
            items: [
              {
                label: 'Tipos',
                icon: 'fa-solid fa-layer-group',
                routerLink: 'configurations/maintenances/types-list',
              },
              {
                label: 'Clasificaciones',
                icon: 'fa-solid fa-list-check',
                routerLink: 'configurations/maintenances/classification-list',
              },
              {
                label: 'Grupos de Subtareas',
                icon: 'fa-solid fa-object-group',
                routerLink: 'configurations/maintenances/group-list',
              },
            ],
          },
          // {
          //   label: 'Logística',
          //   icon: 'fas fa-dolly',
          //   visible: this.modules.logistics,
          //   items: [
          //     {
          //       label: 'Crear Nueva Regla',
          //       icon: 'fa fa-plus-square',
          //       routerLink: 'configurations/rule/create',
          //     },
          //     {
          //       label: 'Reglas',
          //       icon: 'fa fa-list-ul',
          //       routerLink: 'configurations/rule',
          //     },
          //     {
          //       label: 'Crear Nuevo Tipo de Cajas',
          //       icon: 'fa fa-plus-square',
          //       routerLink: 'configurations/package/create',
          //     },
          //     {
          //       label: 'Tipos de Cajas',
          //       icon: 'fa fa-list-ul',
          //       routerLink: 'configurations/package',
          //     },
          //   ],
          // },
        ],
      },
    ];
  }
}
