<form class="form-horizontal" [formGroup]="bookingForm" (ngSubmit)="onSubmit()">
  <div
    class="modal fade in"
    id="myModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    [ngStyle]="{ display: isVisible }"
  >
    <div
      class="modal-dialog"
      role="document"
      class="col-lg-4 col-md-6 col-sm-8"
      style="margin: 100px auto; float: none"
    >
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="closeWindow()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title" id="myModalLabel">
            {{ title }} - Sku: {{ booking?.sku?.external_id }}
          </h4>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label
              for="inputPhone"
              class="col-lg-4 col-md-12 col-sm-12 control-label"
              >{{ 'Cantidad' | translate }}</label
            >
            <div class="col-lg-8 col-md-12 col-sm-12">
              <input
                type="number"
                [max]="booking ? (booking.amount ? booking.amount : 0) : 0"
                [min]="0"
                class="form-control"
                id="inputContact1"
                placeholder="{{ 'Cantidad' | translate }}"
                formControlName="amount"
                [required]="true"
              />
            </div>
          </div>
          <div class="form-group" *ngIf="booking?.mode === _mode.ADD">
            <label
              for="inputPhone"
              class="col-lg-4 col-md-12 col-sm-12 control-label"
              >{{ 'Fecha Límite' | translate }}</label
            >
            <div class="col-lg-8 col-md-12 col-sm-12">
              <p-calendar
                formControlName="dateBook"
                [minDate]="_minDate"
                [maxDate]="_maxDate"
                [showIcon]="true"
                name="dateBook"
                dateFormat="yy-mm-dd"
                dataType="string"
              ></p-calendar>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="reset" data-dismiss="modal" class="btn btn-default">
            {{ 'Cancelar' | translate }}
          </button>
          <button
            *ngIf="booking?.mode === _mode.ADD"
            type="submit"
            data-dismiss="modal"
            class="btn btn-primary"
            [disabled]="!bookingForm.valid"
          >
            {{ 'Reservar' | translate }}
          </button>
          <button
            *ngIf="booking?.mode === _mode.DELETE"
            type="submit"
            data-dismiss="modal"
            class="btn btn-danger"
            [disabled]="!bookingForm.valid"
          >
            {{ 'Quitar' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
