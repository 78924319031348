import { FiltersModel } from '../filters';
import { UserModel } from '../user';
import { SkuModel } from '../products';
import { BookingLine } from '@kanzi-apes/kanzi-models';

export interface BookingLineModel {
  sku: SkuModel;
  sku_id: number | string;
  total_present: number;
  total_reserved: number;
  total_difference: number;
  amount: number;
  sku_name?:string;
  sku_display_name?:string;
  sku_external_id?:string;
}

export interface BookingModel {
  id?: number;
  created?: Date;
  modified?: Date;
  cut_date: Date;
  created_by?: UserModel;
  customer_id: number;
  external_id?: string;
  status?: string;
  location_id: number | null;
  location?: Location;
  lines?: BookingLine[] | {sku_id:number,amount:number}[];
  params?:{
    model?:string;
    model_pk?:number;
    model_external_id?:string;
  }
}

export interface BookingListModel {
  count: number;
  next: string | null;
  previous: string | null;
  results: BookingModel[];
}

export interface BookingFilterModel extends FiltersModel {
  store_order_id?:number;
}

export interface BookingLineFilterModel extends FiltersModel {
  sku_id?: number;
  reserve__status?: string;
  reserve_id?: number;
}

export interface BookingLineModel {
  id: number;
  created_by?: UserModel;
  modified_by?: UserModel;
  created?: Date;
  modified?: Date;
  reserve_id: number;
  reserve?: BookingModel;
  sku: SkuModel;
  amount: number;
}

export interface BookingLineListModel {
  count: number;
  next: string;
  previous: string;
  results: BookingLineModel[];
}
