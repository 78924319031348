/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromAuth from '../../reducers';
import * as Auth from '../../actions/auth';
import { AlertService } from '@kanzi-apes/kanzi-alerts';
import { KanziEnvironmentModel, Authenticate } from '@kanzi-apes/kanzi-models';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';
import kanziVersion from 'kanziVersion.json';
/**
 * @author Hugo Andrés Escobar Ciceri
 * @version 5.0.0
 *
 * Login Component to the authentication.
 */
@Component({
  selector: 'kanzi-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  styles: [
    `
      i {
        opacity: 0.6;
        transition-duration: 0.12s;
        color: #2196f3;

        &:hover {
          opacity: 1;
        }
      }
    `,
  ],
})
export class LoginComponent implements OnInit {
  appVersion: string = '';
  environment: KanziEnvironmentModel | null = null;
  logoPath: string = '';
  backgroundPath: string = '';
  heightDiv: string;
  pending$ = this.store.pipe(select(fromAuth.getLoginPending));
  error$ = this.store.pipe(select(fromAuth.getLoginError));
  loading = false;
  returnUrl: string = '';
  year: number = 0;

  backgrounLoginStyles: any;

  /**
   *
   * @param store {Store}
   * @param alertService {AlertService} Service to open an alert message.
   */
  constructor(
    private store: Store<fromAuth.State>,
    private alertService: AlertService,
    private envService: EnvironmentService
  ) {
    window.addEventListener('resize', this.sizeWindow);
    this.heightDiv = '0px';
    this.appVersion = kanziVersion.version;
  }

  ngOnInit() {
    const now = new Date();
    this.year = now.getFullYear();
    this.heightDiv = window.innerHeight + 'px';
    this.environment = this.envService.environment;
    this.logoPath =
      './' +
      this.environment?.assets.rootClient +
      this.environment?.assets.paths.logos +
      this.environment?.assets.logo;
    this.backgroundPath = `./${this.environment?.assets.rootClient}${this.environment?.assets.paths.backgrounds}${this.environment?.assets.background}`;
    this.error$.subscribe((error) => {
      if (error) {
        this.alertService.error('Usuario o Contraseña incorrectas.');
      }
    });
    this.backgrounLoginStyles = {
      height: this.heightDiv,
      'background-image': `url(${this.backgroundPath})`,
    };
  }

  /**
   *
   * @param $event {Authenticate} Form Data.
   *
   * Function to receive the login data and dispatch the login event.
   */
  onSubmit($event: Authenticate) {
    this.loading = true;
    this.store.dispatch(new Auth.Login($event));
  }

  /**
   * Function to change the height by the window height default.
   */
  sizeWindow() {
    this.heightDiv = window.innerHeight + 'px';
  }
}
