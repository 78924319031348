import { LocationsWMSFilterModel } from '@kanzi-apes/kanzi-models';

export const WarehouseQueryKeys = {
  all: (filters: LocationsWMSFilterModel) => ['warehouseRoot', filters],
};

export const WarehouseManagementQueryKeys = {
  all: (filters: LocationsWMSFilterModel) => ['warehouseManagment', filters],
};

export const WarehouseManagementPositionsQueryKeys = {
  all: (filters: LocationsWMSFilterModel) => [
    'warehouseManagmentPositions',
    filters,
  ],
};

export const WarehouseManagementZonesQueryKeys = {
  all: (filters: LocationsWMSFilterModel) => [
    'warehouseManagmentZones',
    filters,
  ],
};
