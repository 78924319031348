import { effect, Injectable, signal } from '@angular/core';
import { AppConfig, LayoutState } from '../../models';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  _config: AppConfig = {
    ripple: true,
    inputStyle: 'outlined',
    menuMode: 'static',
    colorScheme: 'light',
    componentTheme: 'black',
    scale: 14,
    menuTheme: 'light',
    topbarTheme: 'light',
    menuProfilePosition: 'start',
  };
  state: LayoutState = {
    staticMenuDesktopInactive: false,
    overlayMenuActive: false,
    configSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false,
    rightMenuActive: false,
    topbarMenuActive: false,
    menuProfileActive: false,
    sidebarActive: false,
    anchored: false,
  };
  config = signal<AppConfig>(this._config);

  private configUpdate = new Subject<AppConfig>();

  private overlayOpen = new Subject<any>();

  private topbarMenuOpen = new Subject<any>();

  private menuProfileOpen = new Subject<any>();

  configUpdate$ = this.configUpdate.asObservable();

  overlayOpen$ = this.overlayOpen.asObservable();

  topbarMenuOpen$ = this.topbarMenuOpen.asObservable();

  menuProfileOpen$ = this.menuProfileOpen.asObservable();

  constructor() {
    effect(() => {
      const config = this.config();
      if (this.updateStyle(config)) {
        this.changeTheme();
      }
      this.changeScale(config.scale);
      this.onConfigUpdate();
    });
  }

  /**
   * Function to update the layout configuration
   * @param config {AppConfig} - The configuration object
   * @returns {boolean} - Returns true if the configuration is updated
   */
  updateStyle(config: AppConfig): boolean {
    return (
      config.componentTheme !== this._config.componentTheme ||
      config.colorScheme !== this._config.colorScheme
    );
  }

  /**
   * Method to close the right sidebar
   */
  onMenuToggle() {
    if (this.isOverlay()) {
      this.state.overlayMenuActive = !this.state.overlayMenuActive;

      if (this.state.overlayMenuActive) {
        this.overlayOpen.next(null);
      }
    }

    if (this.isDesktop()) {
      this.state.staticMenuDesktopInactive =
        !this.state.staticMenuDesktopInactive;
    } else {
      this.state.staticMenuMobileActive = !this.state.staticMenuMobileActive;

      if (this.state.staticMenuMobileActive) {
        this.overlayOpen.next(null);
      }
    }
  }

  /**
   * Method to close the right sidebar
   */
  onTopbarMenuToggle() {
    this.state.topbarMenuActive = !this.state.topbarMenuActive;
    if (this.state.topbarMenuActive) {
      this.topbarMenuOpen.next(null);
    }
  }

  /**
   * Method to close the right sidebar
   */
  onOverlaySubmenuOpen() {
    this.overlayOpen.next(null);
  }

  /**
   * Method to show the right sidebar
   */
  showConfigSidebar() {
    this.state.configSidebarVisible = true;
  }

  /**
   * Method to toggle the search bar
   * @returns {boolean} - Returns true if the overlay menu is active
   */
  isOverlay() {
    return this.config().menuMode === 'overlay';
  }

  /**
   * Method to check if the device is desktop
   * @returns {boolean} - Returns true if the device is desktop
   */
  isDesktop() {
    return window.innerWidth > 991;
  }

  /**
   * Method to check if the menu mode is slim
   * @returns {boolean} - Returns true if the menu mode is slim
   */
  isSlim() {
    return this.config().menuMode === 'slim';
  }

  /**
   * Method to check if the menu mode is slim plus
   * @returns {boolean} - Returns true if the menu mode is slim plus
   */
  isSlimPlus() {
    return this.config().menuMode === 'slim-plus';
  }

  /**
   * Method to check if the menu mode is compact
   * @returns {boolean} - Returns true if the menu mode is compact
   */
  isHorizontal() {
    return this.config().menuMode === 'horizontal';
  }

  /**
   * Method to check if the device is mobile
   * @returns {boolean} - Returns true if the device is mobile
   */
  isMobile() {
    return !this.isDesktop();
  }

  /**
   * Method to update the configuration
   */
  onConfigUpdate() {
    this._config = { ...this.config() };
    this.configUpdate.next(this.config());
  }

  /**
   * Method to check if the right sidebar is active
   * @returns {boolean} - Returns true if the right sidebar is active
   */
  isRightMenuActive(): boolean {
    return this.state.rightMenuActive;
  }

  /**
   * Method to open the right sidebar
   */
  openRightSidebar(): void {
    this.state.rightMenuActive = true;
  }

  /**
   * Method to toggle the search bar
   */
  onMenuProfileToggle() {
    this.state.menuProfileActive = !this.state.menuProfileActive;
    if (
      this.state.menuProfileActive &&
      this.isHorizontal() &&
      this.isDesktop()
    ) {
      this.menuProfileOpen.next(null);
    }
  }

  /**
   * Method to change the theme of the application
   */
  changeTheme() {
    let { colorScheme, componentTheme } = this.config();

    const themeLink = <HTMLLinkElement>document.getElementById('theme-link');
    const themeLinkHref = themeLink.getAttribute('href')!;
    const newHref = themeLinkHref
      .split('/')
      .map((el) =>
        el == this._config.componentTheme
          ? (el = componentTheme)
          : el == `theme-${this._config.colorScheme}`
          ? (el = `theme-${colorScheme}`)
          : el
      )
      .join('/');
    this.replaceThemeLink(newHref);
  }

  /**
   * Method to replace the theme link
   * @param href {string} - The new href value
   */
  replaceThemeLink(href: string) {
    const id = 'theme-link';
    let themeLink = <HTMLLinkElement>document.getElementById(id);
    const cloneLinkElement = <HTMLLinkElement>themeLink.cloneNode(true);

    cloneLinkElement.setAttribute('href', href);
    cloneLinkElement.setAttribute('id', id + '-clone');

    themeLink.parentNode!.insertBefore(cloneLinkElement, themeLink.nextSibling);
    cloneLinkElement.addEventListener('load', () => {
      themeLink.remove();
      cloneLinkElement.setAttribute('id', id);
    });
  }

  /**
   * Method to change the scale of the application
   * @param value {number} - The scale value
   */
  changeScale(value: number) {
    document.documentElement.style.fontSize = `${value}px`;
  }
}
