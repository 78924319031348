import { Component } from '@angular/core';

/**
 * Principal Component Zenderbox
 */
@Component({
  selector: 'kanzi-zenderbox-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppZenderboxComponent {
  title = 'kanzi-zenderbox';
}
