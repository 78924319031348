import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { AlertService } from '@kanzi-apes/kanzi-alerts';
import { KEYS, MESSAGES_ERROR } from './messages-errores';

/**
 * @author Hugo Andrés Escobar Ciceri
 * @version 2.2.0
 *
 * Service to control the errors messages.
 */
@Injectable({
  providedIn: 'root',
})
export class KanziControlErrorsService {
  /**
   *
   * @param alertMessage {AlertService} Service to activate an alert message.
   */
  constructor(private alertMessage: AlertService) {}

  /**
   *
   * @param operation
   * @param result
   *
   * Function to control errors.
   */
  public handleError<T>(operation: any, result?: T) {
    return (error: any): Observable<T> => {
      console.log('🚀 ERROR:', error);
      // TODO: send the error to remote logging infrastructure
      let errorText = '';
      if (error.error) {
        if (error.status >= 500) {
          errorText = operation;
        } else if (error.status >= 400 && error.status < 500) {
          const errors = error.error;
          if (errors?.message) {
            errorText = this.setMessage(errors);
          } else {
            const keys = Object.keys(errors);
            for (let i = 0; i < errors.length; i++) {
              errorText = `${errorText} ${errors[i].message[0].Message}\n`;
            }
          }
        }
      }
      if (errorText !== '') {
        this.log(`Se encontró los siguientes errores:\n` + errorText);
      } else {
        this.log(`Operación ${operation} ha fallado.`);
      }

      // Let the app keep running by returning an empty result.
      return throwError(() => operation as T);
    };
  }

  /**
   *
   * @param message {string}
   *
   * Function to open an alert with error message.
   */
  private log(message: string) {
    this.alertMessage.error(message);
  }

  /**
   *
   * @param message {string}
   *
   * Function to open an alert with error message.
   */
  private setMessage(response: any) {
    console.log('🚀 RESPONSE', response);
    const message = response.message;
    const keys = Object.keys(response);
    let detail = '';
    keys
      .filter((i) => i !== 'message')
      .forEach((key) => {
        detail =
          detail +
          ' ' +
          `${
            KEYS[key as keyof typeof KEYS]
              ? KEYS[key as keyof typeof KEYS]
              : key
          }: ${response[key]}`;
      });
    return `${
      MESSAGES_ERROR[message as keyof typeof MESSAGES_ERROR]
        ? MESSAGES_ERROR[message as keyof typeof MESSAGES_ERROR]
        : message
    } ${detail}`;
  }
}
