import { QuestionBase } from './question-base';
import { TextboxQuestion } from './question-textbox';

export class GroupTextboxQuestion extends QuestionBase<string> {
  constructor(options: {}, items: TextboxQuestion[]) {
    super(options);
    this.controlType = 'grouptext';
    this.items = items;
  }
}
