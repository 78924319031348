import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Services, ServicesSelectedModel } from './services.interfaces';
import { ServicesService } from './services.service';
import {
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';

@Component({
  selector: 'kanzi-dynamic-table-services',
  templateUrl: './kanzi-dynamic-table-services.component.html',
  styleUrls: ['./kanzi-dynamic-table-services.component.scss'],
})
export class KanziDynamicTableServicesComponent implements OnInit, OnChanges {
  @Input() resetServices: boolean = false;
  @Input() estimatedTime = 0;
  @Input() set loadList(data:ServicesSelectedModel[]){
    if(data && data.length){
      this.services = data;
    }
  }
  @Input() loading = false;
  @Output() onServicesSelected = new EventEmitter<ServicesSelectedModel[]>();
  services: ServicesSelectedModel[] = [];
  servicesListOptions: Services[] = [];
  viewAddServices: boolean = false;
  formService: UntypedFormGroup = this.fb.group({
    service: [null, [Validators.required]],
    amount: [null, [Validators.required]],
    basePrice: [null],
  });

  get basePrice() {
    return this.formService.get('basePrice');
  }

  constructor(
    private servicesService: ServicesService,
    private fb: UntypedFormBuilder,
    public envService:EnvironmentService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.resetServices) {
      this.formService.reset();
      this.services = [];
      this.onServicesSelected.emit(this.services);
    }
  }

  ngOnInit(): void {
    this.servicesService.getServices().subscribe((services) => {
      if (services) {
        this.servicesListOptions = services.results;
      }
    });

    this.formService.get('service')?.valueChanges.subscribe((value) => {
      if (value) {
        value.price > 1 ? this.basePrice?.disable() : this.basePrice?.enable();
        this.basePrice?.patchValue(value.price);

        if(value.description==='SERVICIO MANO DE OBRA'){
          this.formService.get('amount')?.patchValue(this.estimatedTime);
        }else{
          this.formService.get('amount')?.reset();
        }
      }
    });
  }

  addServices() {
    this.services.push({
      id: this.formService.get('service')?.value.id,
      external_id: this.formService.get('service')?.value.external_id,
      description: this.formService.get('service')?.value.description,
      price: this.basePrice?.value,
      amount: this.formService.get('amount')?.value,
      total_price:
        this.basePrice?.value * this.formService.get('amount')?.value,
    });
    this.viewAddServices = false;
    this.formService.reset();
    this.onServicesSelected.emit(this.services);
  }

  onRowEditInit(row: any) {}

  onRowEditSave(row: ServicesSelectedModel, index: number) {
    this.services[index].amount = row.amount;
    this.services[index].total_price = row.price * row.amount;
    this.onServicesSelected.emit(this.services);
  }

  onRowEditCancel(row: any, i: number) {}

  onRowDelete(row: ServicesSelectedModel) {
    this.services = this.services.filter((s) => s.id !== row.id);
    this.onServicesSelected.emit(this.services);
  }
}
