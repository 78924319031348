import { createSelector, createFeatureSelector, Store } from '@ngrx/store';
// import * as fromRoot from '../../reducers';
import * as fromAuth from './auth';
import * as fromLogin from './login-page';

export interface AuthState {
  status: fromAuth.State;
  loginPage: fromLogin.State;
}

export interface State {  // extends fromRoot.State {
  auth: AuthState;
}

export const reducers = {
  status: fromAuth.reducer,
  loginPage: fromLogin.reducer
};

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectAuthStatusState = createSelector(
  selectAuthState,
  (state: AuthState) => state.status
);

export const getLoggedIn = createSelector(
  selectAuthStatusState,
  fromAuth.getLoggedIn
);

export const getToken = createSelector(
  selectAuthStatusState,
  fromAuth.getToken
);

export const getUser = createSelector(selectAuthStatusState, fromAuth.getUser);

export const selectLoginPageState = createSelector(
  selectAuthState,
  (state: AuthState) => state.loginPage
);

export const getLoginError = createSelector(
  selectLoginPageState,
  fromLogin.getError
);

export const getLoginPending = createSelector(
  selectLoginPageState,
  fromLogin.getPending
);
