import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { aditionalHeadersModel } from '@kanzi-apes/kanzi-models';

@Injectable({
  providedIn: 'root',
})
export class KanziUtilService {
  constructor() {}

  /**
   * @returns Headers {HttpHeaders} Headers to be used in the http requests.
   *
   * Function to create the Http Headers.
   */
  getHeaders(
    additionalHeaders?: aditionalHeadersModel,
    tokenName?: string,
    tokenPrefix?: string
  ): HttpHeaders {
    const kanziToken = localStorage.getItem(tokenName || 'kanziUserToken');
    if (kanziToken) {
      const token = !tokenName ? JSON.parse(kanziToken).auth_token : kanziToken;
      const prefix = tokenPrefix === 'COGNITO' ? '' : 'Token ';
      let opcions = {
        'Content-Type': 'application/json',
        Authorization: prefix + token,
      };
      if (additionalHeaders) {
        opcions = {
          ...opcions,
          ...additionalHeaders,
        };
      }
      const headers = new HttpHeaders(opcions);
      return headers;
    } else {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      return headers;
    }
  }

  getHeadersUploadFile(additionalHeaders?: aditionalHeadersModel): HttpHeaders {
    const kanziToken = localStorage.getItem('kanziUserToken');
    if (kanziToken) {
      const token = JSON.parse(kanziToken);
      let opcions = {
        Authorization: 'Token ' + token.auth_token,
      };
      if (additionalHeaders) {
        opcions = {
          ...opcions,
          ...additionalHeaders,
        };
      }
      const headers = new HttpHeaders(opcions);
      return headers;
    } else {
      const headers = new HttpHeaders();
      return headers;
    }
  }
}
