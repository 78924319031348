import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { UserListService } from '@kanzi-apes/kanzi-auth';
import {
  UserModel,
  KanziStatus,
  CustomerModel,
  UserListModel,
} from '@kanzi-apes/kanzi-models';
import { ConfirmationService, MessageService } from 'primeng/api';

interface UserListDataModel {
  data: UserModel;
}

@Component({
  selector: 'kanzi-apes-users-assign',
  templateUrl: './kanzi-users-assign.component.html',
  styleUrls: ['./kanzi-users-assign.component.scss'],
})
export class KanziUsersAssignComponent implements OnInit, OnChanges {
  @Input() userList: UserModel[];
  @Input() userRole: string;
  @Input() customer: CustomerModel | null;
  @Output() onUserAddedList = new EventEmitter<UserModel[]>();
  @Output() onUserAdded = new EventEmitter<{ row: number; user: UserModel }>();
  @Output() onUserDelete = new EventEmitter<UserModel>();

  userCloneList: UserListDataModel[] = [];
  usersListFiltered: UserModel[] = [];
  userListData: UserListDataModel[] = [];

  /**
   *
   * @param messageService {MessageService} Service to show a toast message
   * @param userListService {UserListService} Service to get the users
   */
  constructor(
    private messageService: MessageService,
    private userListService: UserListService,
    private confirmationService: ConfirmationService
  ) {
    this.userList = [];
    this.userRole = '';
    this.customer = null;
  }

  ngOnInit(): void {
    this.addUserToList();
  }

  ngOnChanges() {
    if (this.userList) {
      this.userListData = this.userList.map((user) => {
        return { data: user };
      });
    }
  }

  filterUsers(event: any) {
    const query = event.query;
    this.userListService
      .getUsersList({
        page: 1,
        search: query,
        page_size: 1000,
        //FIXME: role__name: this.userRole,
        customer_id: this.customer ? this.customer.id : 0,
      })
      .subscribe((users: UserListModel) => {
        this.usersListFiltered = users.results;
      });
  }

  addUserToList() {
    this.userListData.push({
      data: { id: 0, username: '', first_name: '', last_name: '' },
    });
  }

  onRowEditInit(user: UserListDataModel) {
    this.userCloneList[user.data.id] = { ...user };
  }

  onRowDelete(index: number, user: UserListDataModel) {
    if (this.userListData.length > 0) {
      this.confirmationService.confirm({
        message: `Desea eliminar el usuario ${user.data.username}`,
        header: 'Eliminar Usuario',
        icon: 'pi pi-info-circle',
        accept: () => {
          this.userListData.splice(index, 1);
          this.onUserDelete.emit(user.data);
        },
      });
    }
  }

  onRowEditSave(user: UserListDataModel, index: number) {
    if (user) {
      delete this.userCloneList[user.data.id];
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Linea Actualizada.',
      });
      this.userList = this.userListData.map((user) => user.data);
      this.onUserAdded.emit({ row: index, user: user.data });
      this.onUserAddedList.emit(this.userList);
    }
  }

  onRowEditCancel(user: UserListDataModel, index: number) {
    this.userListData[index] = this.userCloneList[user.data.id];
    delete this.userCloneList[user.data.id];
  }
}
