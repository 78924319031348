export interface StatusApiMsgModel {
  title: string;
  type: string;
  msg: string;
}

export interface StateConnectionModel {
  actual: string | null;
  previous: string | null;
}
