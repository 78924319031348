import { FiltersModel } from '../filters';
import { DataModel, DataListModel } from '../data';
import { LocationModel } from '../location';
import { SkuModel } from '../products';

export interface IntegrationFilterModel extends FiltersModel {
  type__name?: string;
  type__type?: string;
}

export interface AdjustAuditCreate {
  integration_config_id?: number;
  action_missing_items?: string;
  action_extra_items?: string;
  action_missing_assets?: string;
  action_extra_assets?: string;
}

export interface AdjustMultipleAuditsModel extends AdjustAuditCreate {
  audit_ids: number[];
  customer_id?: number | null;
}

export interface AuditsFilterModel extends FiltersModel {
  location_id?: number;
  cut_date?: string;
  finish_date?: string;
  cut_date_min?: string;
  cut_date_max?: string;
  type?: string;
  code?: string;
}

export interface AuditLinesFilterModel extends FiltersModel {
  audit_id?: number;
}

export interface AuditModel extends DataModel {
  cut_date: string;
  location: any;
  sub_location?: any;
  prefix: string;
  external_id: string;
  status: string;
  adjusted?: boolean;
  audit_items_file?: string;
  expected_items_file?: string;
  audit_summary_file?: string;
  found_summary_file?: string;
  missing_summary_file?: string;
  extra_summary_file?: string;
  adjust_items_file?: string;
  audit_assets_file?: string;
  expected_assets_file?: string;
  expected_summary_file?: string;
  adjust_assets_file?: string;
  extra_items_file?: string;
  found_items_file?: string;
  missing_items_file?: string;
  summary_results_file: string;
  total_expected_items?: number;
  total_audit_items?: number;
  total_found_items?: number;
  total_missing_items?: number;
  total_extra_items?: number;
  total_expected_assets?: number;
  total_audit_assets?: number;
  total_found_assets?: number;
  total_missing_assets?: number;
  total_extra_assets?: number;
  cut_date_timestamp: number;
  finish_date?: string;
  finish_date_timestamp?: number;
  allows_adjustment?: boolean;
  is_partial_count?: boolean;
  properties?: object;
}

export interface AuditListModel extends DataListModel {
  results: AuditModel[];
}

export interface LocationAuditsModel extends DataListModel {
  results: AuditLineModel[];
}

export interface AuditLineModel {
  location: LocationModel;
  audit: AuditModel;
}

export interface AuditSkuLineModel extends DataModel {
  sku: SkuModel;
  asset?: SkuModel;
  audit: AuditModel;
  total_expected_items: number;
  total_audit_items: number;
  total_found_items: number;
  total_missing_items: number;
  total_extra_items: number;
}

export interface AuditSkuLineListModel extends DataListModel {
  results: AuditSkuLineModel[];
}

export interface AuditCalendarModel {
  id: number;
  title: string;
  start: string;
  end: string;
  color: string;
  extendedProps: AuditModel;
}

export interface AuditCreateModel {
  type: string;
  location_id: number | null;
  cut_date: string;
  properties: Object | null;
  external_id?: string;
  customer_id?: number;
}
export interface PropertyListModel extends DataListModel {
  results: PropertyModel[];
}

export interface PropertyModel {
  id?: number;
  key?: string;
}
export interface ValuesListModel extends DataListModel {
  results: ValuesModel[];
}

export interface ValuesModel {
  id?: number;
  key?: string;
  value?: string;
}

export interface PropertyFilterModel {
  id?: number;
  page?: number;
  type?: string;
  code?: string;
  name?: string;
  page_size?: number;
  search?: string;
  current_location_id?: number;
  customer_id?: number;
}
export interface ValueFilterModel extends FiltersModel {
  key: String;
}

export interface PropertyModel {
  code?: string;
  options: string[];
}

export interface PropertyTableModel {
  property: PropertyModel;
  value: any;
  edit: boolean;
  properties: [];
  values: [];
}

export interface SkuCsvModel {
  sku_id?: string;
  sku_external_id?: string;
  sku_amount?: number;
  sku_name?: string;
  current_location_id?: number;
  sku_amount_found?: number;
  sku_amount_extra?: number;
  total_sum?: number;
  type?:string;
}

export interface SkuListCsvModel extends DataListModel {
  results: SkuCsvModel[];
}

export interface TraceAuditModel extends DataModel {
  audit?: AuditModel;
  audit_items_file?: string;
  total_audit_items?: number;
  status?: string;
  created_by_username?: string;
  modified_by_username?: string;
}

export interface TraceAuditListModel extends DataListModel {
  results: TraceAuditModel[];
}

export interface TraceAuditFilterModel extends FiltersModel {
  audit_id: number | null;
}
