import {
  MaintenanceWorkOrderSparePartsModel,
  SkuModel,
  StoreOrderLineCreateModel,
  StoreOrderLineModel,
} from '../../interfaces';
export class StoreOrderLine implements StoreOrderLineCreateModel {
  sku: SkuModel | null;
  sku_id: number | null;
  sku_external_id: string | null;
  sku_display_name: string | null;
  amount: number;
  total_amount: number;

  constructor(
    line?: MaintenanceWorkOrderSparePartsModel | StoreOrderLineModel
  ) {
    this.sku = null;
    this.sku_id = null;
    this.amount = 0;
    this.sku_external_id = null;
    this.sku_display_name = null;
    this.total_amount = 0;

    if (line) {
      this.sku_id = line.sku ? (line.sku.id ? line.sku.id : 0) : 0;
      this.sku = line.sku ? line.sku : null;
      this.sku_display_name = line.sku ? line.sku.display_name : '';
      this.sku_external_id = line.sku ? line.sku.external_id : '';
      this.amount = line.amount ? line.amount : 0;
      this.total_amount = line.amount ? line.amount : 0;
    }
  }
}
