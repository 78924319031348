import { DataModel, DataListModel } from '../data';
import { UserModel } from '../user';
import { SkuModel } from '../products';
import { LocationModel } from '../location';
import { FiltersModel } from '../filters';
import { ContactModel } from '../contacts';
import { CustomerModel } from '../customer';
import { PackageSizeModel } from '../package';
import { RuleModel } from './rule';
export * from './rule';

export interface TrackingMultipleModel {
  shipping_order_ids: string[];
  ship_date: string;
  convayor_id?: number;
  tracking_type: string;
  location_id: number;
}

export interface IntegrationsFilterModel extends FiltersModel {
  type?: string;
  name?: string;
  external_id?: string;
}

export interface ConveyorListModel extends DataListModel {
  results: ConveyorModel[];
}

export interface ConveyorModel extends DataModel {
  external_id: string;
  name: string;
  type: string;
  active: boolean;
}

export interface TrackingModel extends DataModel {
  created_by_name?: string;
  external_id: string;
  ship_date: Date;
  order_date: Date;
  order_number: number;
  contact_name: string;
  user_name: string;
  description: string;
  total_expected_items: number;
  properties: any;
  ship_date_timestamp: number;
  order_date_timestamp: number;
  verified: boolean;
  verified_name?: string;
  graphic_image: string;
  status?: string;
  shipping_order?: ShippingOrderModel;
  shipping_order_shipping_address_name?: string;
  carrier_display_name?: string;
  current_location?: LocationModel;
  location_display_name?: string;
}

export interface TrackingLineModel {
  id: number;
  created_by: UserModel;
  modified_by: UserModel;
  created_timestamp: number;
  modified_timestamp: number;
  created: Date;
  modified: Date;
  sku: SkuModel;
  tracking: TrackingModel;
  amount: number;
  items: any[];
  verified: boolean;
}

export interface TrackingListModel extends DataListModel {
  results: TrackingModel[];
}

export interface TrackingLineListModel extends DataListModel {
  results: TrackingLineModel[];
}

export interface TrackingFilterModel extends FiltersModel {
  external_id?: string;
  order_number?: string;
  created_min?: string;
  created_max?: string;
  verified?: boolean | string;
  status?: string;
  type?: string;
}

export interface TrackingLineFilterModel extends FiltersModel {
  tracking_external_id?: string;
  sku_id?: number;
}

export interface TrackingPrintLabelLineFilterModel extends FiltersModel {
  print_order_label_id?: number;
  tracking_id?: number;
}

export interface TrackingPrintOrderFilterModel extends FiltersModel {
  external_id?: string;
  search?: string;
  status?: string;
  location_id?: number;
  printed_by_id?: number;
}

export interface TrackingPrintOrderModel extends DataModel {
  created_by_name?: string;
  printed_by: UserModel;
  printed_by_name?: string;
  template_label: any;
  current_location: LocationModel;
  current_location_name?: string;
  status: string;
  prefix: string;
  external_id: string;
  batch_number: string;
  batch_type: string;
  total_label_used: number;
  total_aborted?: number;
  total_print?: number;
}

export interface TrackingPrintOrderListModel extends DataListModel {
  results: TrackingPrintOrderModel[];
}

export interface TrackingLabelLineModel extends DataModel {
  tracking: TrackingModel;
  print_order_label: number;
}

export interface TrackingLabelLineListModel extends DataListModel {
  results: TrackingLabelLineModel[];
}

export interface ShippingOrderModel extends DataModel {
  created_by_name?: string;
  contact: ContactModel;
  seller?: ContactModel;
  seller_name?: string;
  external_id: string;
  customer: number;
  billing_address: AddressModel;
  shipping_address: AddressModel;
  shipping_address_name?: string;
  shipping_address_city?: string;
  shipping_address_address1?: string;
  shipping_address_address2?: string;
  package_order?: PackageOrderUPSModel;
  status: string;
  country_code?: string;
  costs?: any;
}

export interface ShippingOrderCreateModel {
  contact_id: number;
  external_id?: string;
  seller_id?: string;
  billing_address: AddressModel;
  shipping_address: AddressModel;
  customer: number;
  lines?: shippingLinesModel[] | ShippingOrderLineModel[];
  id?: number;
}

export interface shippingLinesModel{
  sku_external_id?:string;
  amount: number;
}

export interface ShippingLineModel extends DataModel {
  shipping_order: ShippingOrderModel;
  sku: SkuModel;
  amount: number;
  costs: any;
  location_param: any;
  iva_id: number;
}

export interface ShippingLineListModel extends DataListModel{
  results: ShippingLineModel[];
}

export interface PackageOrderUPSModel extends DataModel {
  external_id: string;
  type?: PackageTypeModel;
  properties?: any;
  customer?: CustomerModel;
  package_size?: PackageSizeModel;
  service_rule?: RuleModel;
}

export interface ShippingOrderLineModel extends DataModel {
  shipping_order?: ShippingOrderModel;
  sku: SkuModel;
  sku_display_name?: string;
  sku_id?: number | string;
  sku_external_id?: string;
  amount: number;
}

export interface ShippingOrderListModel extends DataListModel {
  results: ShippingOrderModel[];
}

export interface ShippingOrderLineListModel extends DataListModel {
  results: ShippingOrderLineModel[];
}

export interface ShippingOrderFilterModel extends FiltersModel {
  external_id?: string;
  status?: string;
  package_id?: number;
  contact_id?: number;
}

export interface AddressModel {
  zip: string;
  city: string;
  name: string;
  phone: string;
  company: string;
  country: string;
  address1: string;
  address2: string;
  latitude: string;
  province: string;
  last_name: string;
  longitude: string;
  first_name: string;
  country_code: string;
  province_code: string;
  state?: string;
}

export interface PackageServiceTypeModel extends DataModel {
  external_id?: string;
  name: string;
  description: string;
}

export interface PackageServiceTypeListModel extends DataListModel {
  results: PackageServiceTypeModel[];
}

export interface PackageTypeModel extends DataModel {
  external_id?: string;
  name: string;
  description: string;
  service: PackageServiceTypeModel[];
}

export interface PackageTypeListModel extends DataListModel {
  results: PackageTypeModel[];
}

export interface PackageTypeFilterModel extends FiltersModel {
  service_id?: number;
}

export interface PackageOrderCreateModel {
  customer_id: number;
  external_id: string;
  service_rule_id: number;
  type_id: number;
  package_size_id: number;
  properties?: {};
}

export interface ShippingOrderCreateResponseModel {
  shipping_ids: number[];
}

export interface ConfigParamModel {
  id?: number;
  description?: string;
  type?: {
    id?: number;
    name?: string;
    active: boolean;
  };
  customer: CustomerModel;
}

export interface IntegrationParamModel extends DataModel {
  group?: {
    id?: number;
    external_id?: string;
    name?: string;
    active?: boolean;
  };
  config?: ConfigParamModel;
  name?: string;
  external_id?: string;
  description?: string;
  properties?: any;
  active?: boolean;
}

export interface IntegrationParamFilter extends FiltersModel {
  group__name: String;
  active?: boolean;
  default?: boolean;
}

export interface IntegrationParamListModel extends DataListModel {
  results: IntegrationParamModel[];
}

export interface ShippingBillCreate {
  invoice_param_id?: number | null;
  payment_param_id?: number | null;
  cost_center_param_id?: number | null;
  customer_id?: number;
  shipping_order_id?: number;
}

export interface PickingFileModel extends DataModel {
  file: string;
  status: string;
  customer_id: number;
}

export interface PickingFileListModel extends DataListModel {
  results: PickingFileModel[];
}

export interface ShippingReturnFilterModel extends FiltersModel {
  external_id?: string;
  shipping_order_id?: number;
  status?: string;
}
export interface ShippingReturnModel extends DataModel {
  shipping_order: ShippingOrderModel;
  total_amount: number;
  customer?: CustomerModel;
  external_id: string;
  costs?: any;
  status: string;
  entry_location: LocationModel;
  created_by_name?: string;
  shipping_order_contact_name?: string;
  shipping_order_external_id?: string;
  shipping_order_seller_name?: string;
}
export interface ShippingReturnListModel extends DataListModel {
  results: ShippingReturnModel[];
}

export interface ShippingReturnLineModel extends DataModel {
  shipping_return: ShippingReturnModel;
  sku: SkuModel;
  amount: number;
  status: string;
  costs?: any;
  items?: { items: string[] };
  verified: boolean;
  sku_external_id?: string;
  sku_display_name?: string;
  sku_group_name?: string;
}

export interface ShippingReturnLineListModel extends DataListModel {
  results: ShippingReturnLineModel[];
}

export interface ShippingReturnLineFilterModel extends FiltersModel {
  sku__external_id?: string;
  sku_id?: number;
  shipping_return_id?: number;
  status?: string;
  verified?: boolean;
}

export interface TrackingsVerifiedModel {
  id?:      number;
  item_ids: string[];
  verified: boolean;
}
