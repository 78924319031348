export enum CUSTOMERTYPES {
  ASSETS = 'ASSETS',
  ASSETS_CUSTOM = 'ASSETS_CUSTOM',
  ASSETS_NORFID = 'ASSETS_NORFID',
  RETAIL = 'RETAIL',
  FULFILLMENT = 'FULFILLMENT',
  SHIPMENTS = 'SHIPMENTS',
  STOCK_MANAGEMENT = 'STOCK_MANAGEMENT',
  STOCK_OLDER = 'STOCK_OLDER',
  RETAIL_NORFID = 'RETAIL_NORFID',
}
