import {
  Component,
  Output,
  EventEmitter,
  input,
  inject,
  effect,
} from '@angular/core';
import { WarehouseListModel, WarehouseModel } from '@kanzi-apes/kanzi-models';

@Component({
  selector: 'kanzi-warehouse-root-selector',
  templateUrl: './kanzi-ui-warehouse-root-selector.component.html',
  styleUrls: ['./kanzi-ui-warehouse-root-selector.component.scss'],
  animations: [],
})
export class KanziUIWarehouseRootSelectorComponent {
  placeholderSelector = input<string>('Seleccionar');
  warehouseRootsSelected = input<WarehouseModel | null>();
  warehouseList = input<WarehouseListModel>();
  warehouseRootsSelectedForm: WarehouseModel | null = null;

  @Output() selectWarehouseRoot: EventEmitter<WarehouseModel> =
    new EventEmitter();

  constructor() {
    effect(() => {
      let warehouseRootSelected = this.warehouseRootsSelected();
      if (warehouseRootSelected) {
        this.warehouseRootsSelectedForm = warehouseRootSelected;
      }
    });
  }
  onSelectWarehouse(warehouseRoot: any) {
    if (
      this.warehouseRootsSelected() &&
      this.warehouseRootsSelected()?.id !== warehouseRoot.id
    ) {
      this.selectWarehouseRoot.emit(warehouseRoot.value);
    } else if (!this.warehouseRootsSelected()) {
      this.selectWarehouseRoot.emit(warehouseRoot.value);
    }
  }
}
