import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { LayoutModel, UserModel } from '@kanzi-apes/kanzi-models';
import { MenuItem } from '@kanzi-apes/kanzi-prime-ui';
import { DiamondLayoutService } from '@kanzi-apes/kanzi-diamond-ui';

@Component({
  selector: 'kanzi-kanzi-main-sidebar',
  templateUrl: './kanzi-main-sidebar.component.html',
  styleUrls: ['./kanzi-main-sidebar.component.scss'],
})
export class KanziMainSidebarComponent implements OnDestroy {
  @Input() actionSidebar: string | null;
  @Input() user: UserModel | null;
  @Input() logoPath: string;
  @Input() status: string;
  @Input() modulesApp: any;
  @Input() menuItems: MenuItem[];
  @Input() layoutOptions: LayoutModel | null;
  @Output() menuClick = new EventEmitter();

  visibleSidebar: boolean = false;
  timeout: any = null;

  @ViewChild('menuContainer') menuContainer!: ElementRef;

  get logoColor() {
    let logo;

    if (this.layoutService.config.colorScheme == 'light') {
      logo =
        this.layoutService.config.menuTheme === 'white' ||
        this.layoutService.config.menuTheme === 'orange'
          ? 'dark'
          : 'white';
    } else {
      logo = 'dark';
    }
    return logo;
  }

  constructor(
    private layoutService: DiamondLayoutService,
    public el: ElementRef
  ) {
    this.actionSidebar = '';
    this.user = null;
    this.logoPath = '';
    this.status = '';
    this.menuItems = [];
    this.layoutOptions = null;
  }

  onMouseEnter() {
    if (!this.layoutService.state.anchored) {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.layoutService.state.sidebarActive = true;
    }
  }

  onMouseLeave() {
    if (!this.layoutService.state.anchored) {
      if (!this.timeout) {
        this.timeout = setTimeout(
          () => (this.layoutService.state.sidebarActive = false),
          300
        );
      }
    }
  }

  resetOverlay() {
    if (this.layoutService.state.overlayMenuActive) {
      this.layoutService.state.overlayMenuActive = false;
    }
  }

  anchor() {
    this.layoutService.state.anchored = !this.layoutService.state.anchored;
  }

  ngOnDestroy() {
    this.resetOverlay();
  }
}
