import { DataModel, DataListModel } from '../data';
import { CustomerModel } from '../customer';
import { FiltersModel } from '../filters';

export interface PackageSizeModel extends DataModel {
  customer: CustomerModel;
  external_id: string;
  name: string;
  dimensions: DimensionsModel;
  unit_dimensions: string;
  weight: number;
  unit_weight: string;
}
export interface PackageSizeCreateModel {
  id?: number;
  customer_id: number;
  external_id?: string;
  name: string;
  dimensions: DimensionsModel;
  unit_dimensions?: string;
  weight?: number;
  unit_weight?: string;
}

export interface DimensionsModel {
  width: number;
  height: number;
  length: number;
}

export interface PackageSizeFilterModel extends FiltersModel {
  customer_id: number;
}

export interface PackageSizeListModel extends DataListModel {
  results: PackageSizeModel[];
}
