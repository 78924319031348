export interface ConfigPackageList {
  title: string;
  styleClass?: string;
  rows: number;
  lazy?: boolean;
  scrollable?: boolean;
  scrollHeight?: string;
  immutable?: boolean;
  paginator?: boolean;
  cube?: {
    color?: string;
    destination?: {
      filed?: string;
      subfield?: string;
    };
    external_id?: string;
  };
}
