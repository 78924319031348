export enum UserRoles {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  GENERAL_USER = 'GENERAL_USER',
  WAREHOUSE = 'WAREHOUSE',
  MAINTENANCE_ADMIN = 'MAINTENANCE_ADMIN',
  ASSISTANT = 'ASSISTANT',
  ASSISTANT_LEADER = 'ASSISTANT_LEADER',
  GUEST = 'GUEST',
}
