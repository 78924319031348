export enum AUDIT_STATES {
  CREATED = 'CREATED',
  PROCESSING = 'PROCESSING',
  CALCULATED = 'CALCULATED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  ADJUSTED = 'ADJUSTED',
  CANCELED = 'CANCELED'
}

export enum ACTIONS {
  LOAD = 'LOAD',
  IGNORE = 'IGNORE',
  DOWNLOAD = 'DOWNLOAD'
}
