import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'kanzi-kanzi-count-card',
  templateUrl: './kanzi-count-card.component.html',
  styleUrls: ['./kanzi-count-card.component.scss'],
})
export class KanziCountCardComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() description: string;
  @Input() boxClass: string;

  totalCount = 0;

  constructor() {
    this.description = '';
    this.boxClass = '';
  }

  ngOnInit(): void {}

  ngOnChanges() {
    if (this.data) {
      this.totalCount = this.data;
    } else {
      this.totalCount = 0;
    }
  }
}
