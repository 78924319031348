import { UserModel } from '../user';
import { SkuModel } from '../products';
import { FiltersModel } from '../filters';
import { LocationModel } from '../location';
import { DistributionModel } from '../distribution-order';
import { StoreOrderModel } from '../store-order';
import { WorkOrderModel } from '../work-order';
import { DataListModel, CreatedModel } from '../data';

export interface PackageCreateModel {
  customer_id: number;
  location_id: number;
  external_id?: string;
  items: string[];
}

export interface PackageModel {
  id: number;
  user: UserModel;
  total_amount: number;
  prefix: string;
  type: number;
  created: string;
  created_by: UserModel;
  modified_by: UserModel;
  modified: string;
  status: string;
  store_order?: StoreOrderModel;
  distribution?: DistributionModel;
  work_order?: WorkOrderModel;
  work_order_id: number;
  external_id: number;
  items_file: string;
  summary_file: string;
  store_order_external_id?: string;
  created_by_name?: string;
  modified_by_name?: string;
  tracking?: { tracking: string[] };
  location?: LocationModel;
  total_quantities?: number;
  created_timestamp: number;
}

export interface PackageListModel extends DataListModel {
  results: PackageModel[];
}

export interface PackageLineModel {
  sku: SkuModel;
  sku_display_name?: string;
  sku_id?: number;
  sku_external_id?: string;
  store_order_external_id?: string;
  items: { items: string[] };
  count?: number;
  amount: number;
  amount_verified?: number;
  packing?: PackageModel;
  packing_id?: number;
  created_by?: CreatedModel;
}

export interface PackageLineListModel {
  count: number;
  next: string;
  previous: string;
  results: PackageLineModel[];
}

export interface MovePackingModel {
  source_id?: number;
  destination_id: number;
  external_id: string;
  packing_ids: number[];
  customer_id?: number;
}

export interface PackingFilterModel extends FiltersModel {
  store_order_external_id?: string;
  work_order_id?: number;
  status?: string;
  location_id?: number | string;
}

export interface PackingLineFilterModel {
  page?: number;
  page_size?: number;
  modified_min?: string;
  modified_max?: string;
  created_min?: string;
  created_max?: string;
  search?: string;
  sku_id?: string;
  packing_id?: number;
}

export interface ISkuLine {
  display_name: string;
  sku: SkuModel;
  sku_id?: string;
  amount: number;
  in_work_order_amount?: number;
  in_distribution_amount?: number;
  packed_amount?: number;
  balance?: number;
  total_amount: number;
}
