import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { MAINTENANCE_BUDGET_ORDER_STATUS } from '@kanzi-apes/kanzi-models';

@Directive({
  selector: '[kanziBudgetStatusStyle]',
})
export class BudgetStatusStyleDirective implements OnInit {
  @Input('kanziBudgetStatusStyle') status: string | boolean;
  @HostBinding('class')
  elemClass = '';
  constructor(private el: ElementRef) {
    this.status = '';
  }

  ngOnInit() {
    switch (this.status) {
      case MAINTENANCE_BUDGET_ORDER_STATUS.CREATED: {
        this.elemClass = 'customer-badge status-new';
        break;
      }
      case MAINTENANCE_BUDGET_ORDER_STATUS.REJECTED: {
        this.elemClass = 'customer-badge status-unqualified';
        break;
      }
      case MAINTENANCE_BUDGET_ORDER_STATUS.APPROVED: {
        this.elemClass = 'customer-badge status-qualified';
        break;
      }
      default:
        break;
    }
  }
}
