<p-dataView
  [value]="dataSource ? (dataSource.results ? dataSource.results : []) : []"
  [lazy]="
    configuration ? (configuration.lazy ? configuration.lazy : false) : false
  "
  [emptyMessage]="'No se encontraron cajas'"
  [paginator]="
    configuration
      ? configuration.paginator
        ? configuration.paginator
        : false
      : false
  "
  [rows]="configuration ? (configuration.rows ? configuration.rows : 0) : 0"
  [totalRecords]="dataSource ? (dataSource.count ? dataSource.count : 0) : 0"
  [styleClass]="
    configuration
      ? configuration.styleClass
        ? configuration.styleClass
        : ''
      : ''
  "
  [loading]="loading"
  (onLazyLoad)="loadLazy($event)"
>
  <p-header>
    <div class="flex flex-column md:flex-row md:justify-content-between">
      <span>{{
        configuration
          ? configuration.title
            ? configuration.title
            : ''
          : ('' | translate)
      }}</span>
      <span
        >Total Cajas: {{ countPacket | number }}
        <br />
        Items Por página : {{ countItems | number }} 
      </span>
      <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
    </div>
  </p-header>

  <ng-template let-pack pTemplate="listItem">
    <div
      class="flex justify-content-between flex-wrap rowPackDataList col-12"
      (click)="selectPack(pack)"
    >
      <div class="col-6 flex justify-content-center flex-wrap">
        <i
          class="fa fa-cube"
          [ngStyle]="{ color: configuration?.cube?.color }"
          style="font-size: 36px; text-align: center"
        ></i>
      </div>

      <div class="col-6 flex flex-column">
        <h4
          class="muted"
          *ngIf="
            configuration &&
            configuration.cube &&
            configuration.cube.destination &&
            configuration.cube.destination.filed &&
            configuration.cube.destination.subfield &&
            pack[configuration.cube.destination.filed]
          "
        >
          {{
            pack[configuration.cube.destination.filed][
              configuration.cube.destination.subfield
            ]
          }}
        </h4>
        <h4
          class="muted"
          *ngIf="
            configuration &&
            configuration.cube &&
            configuration.cube.destination &&
            configuration.cube.destination.filed &&
            !configuration.cube.destination.subfield
          "
        >
          {{ pack[configuration.cube.destination.filed] }}
        </h4>
        <div>
          <span class="text-cyan-700 text-lg" *ngIf="pack.prefix"
            >{{ pack.prefix }}-</span
          >
          <span class="text-cyan-700 text-lg">{{ pack.id }}</span>
          <span
            class="text-800"
            *ngIf="
              configuration &&
              configuration.cube &&
              configuration.cube.external_id
            "
          >
            | #{{ pack[configuration.cube.external_id] }}</span
          >
        </div>
        <div>
          <span class="text-700"
            >{{ 'Total' | translate }}: {{ pack.total_amount | number }}
            {{ 'Items' | translate }}</span
          >
        </div>
        <div>
          <span class="text-700">{{ pack.modified }}</span>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template let-pack pTemplate="gridItem">
    <div style="padding: 0.5em" class="col-12 md:col-6 lg:col-4">
      <p-panel
        [header]="pack.prefix + '-' + pack.id"
        [style]="{ 'text-align': 'center' }"
      >
        <i
          class="fa fa-cube"
          [ngStyle]="{ color: configuration?.cube?.color }"
          style="font-size: 36px; text-align: center"
        ></i>
        <div class="pack-detail">
          <label class="text-700"
            >{{ 'Total' | translate }}:
            {{
              envService.environment?.isQuantity
                ? pack.total_quantities
                : (pack.total_amount | number)
            }}
            {{ 'Items' | translate }}</label
          >
          <div class="col-lg-12 col-md-12 col-xs-12">
            <label class="text-700">{{ pack.modified }}</label>
          </div>
        </div>
        <hr class="ui-widget-content" style="border-top: 0" />
        <button
          pButton
          type="button"
          icon="pi pi-eye"
          (click)="selectPack(pack)"
          style="margin-top: 0"
        ></button>
      </p-panel>
    </div>
  </ng-template>
</p-dataView>
