import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { tap, map, exhaustMap, catchError } from 'rxjs/operators';
import { LoginService } from '../services/login.service';

import {
  Login,
  LoginSuccess,
  LoginFailure,
  AuthActionTypes,
  UserProfileSuccess,
  UserProfileLoad,
  UserLoginFailureToken,
} from '../actions/auth';
import { Authenticate } from '@kanzi-apes/kanzi-models';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.Login),
      map((action: Login) => action.payload),
      exhaustMap((auth: Authenticate) =>
        this.authService.login(auth).pipe(
          map((token) =>
            token ? new LoginSuccess({ token }) : new UserLoginFailureToken()
          ),
          catchError((error) => of(new LoginFailure(error)))
        )
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.LoginSuccess),
        tap(() => this.router.navigate(['/main']))
      ),
    { dispatch: false }
  );

  loginRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.LoginRedirect, AuthActionTypes.Logout),
        tap((authed) => {
          this.router.navigate(['/login']);
        })
      ),
    { dispatch: false }
  );

  userProfileLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.UserProfileLoad),
      map((action: UserProfileLoad) => action),
      exhaustMap(() =>
        this.authService
          .userProfile()
          .pipe(map((user) => new UserProfileSuccess({ user })))
      )
    )
  );

  constructor(
    private actions$: Actions,
    private authService: LoginService,
    private router: Router
  ) {}
}
