import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AlertService } from './alert.service';

/**
 *  @author Hugo Andrés Escobar Ciceri
 *  @version 2.1.0
 *  Alert Component for the generic messages.
 */

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css'],
})
export class AlertComponent implements OnInit {
  message: any;
  isVisible: string;
  title = 'Mensaje';
  displayMessage = false;

  /**
   * @param alertService {AlertService} Service to send and receive messages
   * @param route {ActivatedRoute}
   * @param router {Router}
   */
  constructor(
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.isVisible = 'none';
  }

  ngOnInit() {
    this.alertService.getMessage().subscribe({
      next: (message: any) => {
        if (message) {
          this.message = message;
          this.displayMessage = true;
          this.isVisible = 'block';
          if (this.message.type === 'error') {
            this.title = 'Alerta';
          } else if (this.message.type === 'success') {
            this.title = 'Mensaje';
          } else if (this.message.type === 'warning') {
            this.title = 'Mensaje';
          }
        }
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  }

  /**
   * Route the page to other component.
   */
  goDetail() {
    this.router.navigate([this.message.link.path, this.message.link.param]);
    this.message = '';
    this.isVisible = 'none';
    this.displayMessage = false;
  }

  /**
   * Close the modal alert.
   */
  closeModal() {
    this.message = '';
    this.isVisible = 'none';
    this.displayMessage = false;
  }
}
