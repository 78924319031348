<p-dropdown
  [placeholder]="placeholderSelector()"
  optionLabel="name"
  [options]="warehouseList()?.results || []"
  (onChange)="onSelectWarehouse($event)"
  [(ngModel)]="warehouseRootsSelectedForm"
  [styleClass]="'w-full'"
>
  <ng-template pTemplate="selectedItem">
    <div
      class="flex align-items-center gap-2"
      *ngIf="warehouseRootsSelectedForm"
    >
      <i class="pi pi-row pi-building" style="width: 18px"></i>
      <div>{{ warehouseRootsSelectedForm.name }}</div>
    </div>
  </ng-template>
  <ng-template let-warehouse pTemplate="item">
    <div class="flex align-items-center gap-2">
      <i class="pi pi-row pi-building" style="width: 18px"></i>
      <div>{{ warehouse.name }}</div>
    </div>
  </ng-template>
</p-dropdown>
