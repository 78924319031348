import { RouterStateSerializer } from '@ngrx/router-store';
import { RouterStateSnapshot, Params } from '@angular/router';
export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}
export interface DataForExcelModel {
  title: string;
  data: any[];
  headers: string[];
  properties: any;
}
