import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'kanzi-progress-bar',
  templateUrl: './kanzi-progress-bar.component.html',
  styleUrls: ['./kanzi-progress-bar.component.scss'],
})
export class KanziProgressBarComponent implements OnInit {
  @Input() mode = 'indeterminate';
  @Input() height: string;
  @Input() show: boolean;
  @Input() value: number;
  constructor() {
    this.height = '';
    this.show = false;
    this.value = 0;
  }

  ngOnInit(): void {}
}
