<ul class="kanzi-lang-select nav navbar-nav">
  <!-- Control Sidebar Toggle Button -->
  <li class="dropdown">
    <a
      (click)="collapseTop('lenguaje')"
      class="dropdown-toggle"
      data-toggle="dropdown"
      aria-expanded="true"
    >
      <span>{{ translateService.currentLang }}</span>
    </a>
    <ul class="dropdown-menu menu-lenguaje" [@collapse]="collLenguaje">
      <li *ngFor="let lang of languajes" (click)="changeLanguaje(lang)">
        <span class="customer-name">{{ lang }}</span>
      </li>
    </ul>
  </li>
</ul>
