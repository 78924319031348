export enum MAINTENANCE_WORK_ORDER_STATUS {
  ISSUED = 'ISSUED',
  BY_ASSIGN = 'BY_ASSIGN',
  BUDGET_PENDING = 'BUDGET_PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_REVIEW = 'IN_REVIEW',
  CLOSED = 'CLOSED',
  COMPLETED = 'COMPLETED',
  MAINTENANCE_PENDING = 'MAINTENANCE_PENDING',
  DIAGNOSTIC='DIAGNOSTIC'
}

export enum MAINTENANCE_BUDGET_ORDER_STATUS {
  APPROVED = 'APPROVED',
  CREATED = 'CREATED',
  REJECTED = 'REJECTED',
}

export enum MAINTENANCE_WORK_ORDER_STATUS_DISPLAY_NAME {
  ISSUED = 'PENDIENTE',
  BY_ASSIGN = 'POR ASIGNAR',
  BUDGET_PENDING = 'COTIZACIÓN PENDIENTE',
  IN_PROGRESS = 'EN PROCESO',
  IN_REVIEW = 'EN REVISIÓN',
  CLOSED = 'CERRADO',
  COMPLETED = 'COMPLETADO',
  MAINTENANCE_PENDING = 'DIAGNOSTICO'
}