import { LocationModel, SkuModel } from '@kanzi-apes/kanzi-models';
export * from './booking-line.class';
export * from './inventory-tree.class';
export * from './queue.class';
export * from './tree-map.class';

 export class ItemStock {
   epc              !: string;
   current_location !: LocationModel;
   previous_location!: LocationModel;
   sub_location_id  !: string;
   serial           !: number;
   state            !: number;
   batch_number     !: string;
   batch_type       !: number;
   properties       !: string;
   locked           !: boolean;
   created          !: Date;
   modified         !: Date;
   quality_check    !: boolean;
   completed_check  !: boolean;
   sku              !: string;
 }

 export class ItemStockList {
   count  !: number;
   next   !: string;
   previus!: string;
   results!: ItemStock[];
 }

export class Stock {
  id!: number;
  current_location!: LocationModel;
  sub_location: any;
  sku!: SkuModel;
  total_issued: number;
  total_present: number;
  total_in_transfer: number;
  total_inactive: number;
  total_lost: number;
  total_not_present: number;
  total_packed: number;
  total_sold: number;
  total_dispatch?: number;
  total: number;
  total_reserved?: number;
  total_difference?: number;

   constructor() {
     this.total_issued = 0;
     this.total_present = 0;
     this.total_in_transfer = 0;
     this.total_inactive = 0;
     this.total_lost = 0;
     this.total_not_present = 0;
     this.total_packed = 0;
     this.total_sold = 0;
     this.total_dispatch = 0;
     this.total = 0;
   }
 }

 export class StockList {
   count   !: number;
   next    !: string;
   previous!: string;
   results !: Stock[]
   constructor() {}
 }
