import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-properties-table',
  templateUrl: './properties-table.component.html',
  styleUrls: ['./properties-table.component.scss'],
})
export class PropertiesTableComponent implements OnInit {
  private _properties: Array<any> = [];

  constructor() {}

  ngOnInit() {}

  @Input()
  set properties(properties: any) {
    if (properties) {
      this._properties = [];
      this.getPropertiesList(properties);
    }
  }

  get properties(): any {
    return this._properties;
  }

  private nameConcat(arr2: Array<any>): Promise<string> {
    let nameConcat = '';
    if (arr2.length === 1) {
      nameConcat = arr2[0];
      return Promise.resolve(nameConcat);
    } else {
      for (let j = 0; j < arr2.length; ++j) {
        // code...
        if (j === 0) {
          nameConcat = arr2[j];
        } else if (j + 1 === arr2.length) {
          nameConcat = nameConcat + ' ' + arr2[j];
          return Promise.resolve(nameConcat);
        } else {
          nameConcat = nameConcat + ' ' + arr2[j];
        }
      }
    }

    return Promise.resolve(nameConcat);
  }

  private getPropertiesList(properties: { [key: string]: string }) {
    const keys = Object.keys(properties);
    for (let i = 0; i < keys.length; ++i) {
      const nameArray = keys[i].split('_');
      this.nameConcat(nameArray).then((n) => {
        this._properties.push({ name: n, value: properties[keys[i]] });
      });
    }
  }
}
