import { Component, OnInit } from '@angular/core';
import { AlertVoiceService } from './alert-voice.service';
import { MessageService } from '@kanzi-apes/kanzi-prime-ui';
/**
 * @author Hugo Andrés Escobar Ciceri
 * @version 2.1.0
 *
 * Alert Component to show messages on the top the main section.
 */
@Component({
  selector: 'app-alert-voice',
  templateUrl: './alert-voice.component.html',
  styleUrls: ['./alert-voice.component.css'],
  providers: [MessageService],
})
export class AlertVoiceComponent implements OnInit {
  /**
   *
   * @param alertService {AlertVoiceService} Service to send and receive messages.
   */
  constructor(
    private alertService: AlertVoiceService,
    private primeMsgService: MessageService
  ) {}

  ngOnInit() {
    this.alertService
      .getMessage()
      .subscribe((message: { type: string; title: string; text: string }) => {
        if (message) {
          this.primeMsgService.clear('alertVoice');
          this.primeMsgService.add({
            key: 'alertVoice',
            severity: message.type,
            summary: message.title,
            detail: message.text,
            sticky: false,
          });
        }
      });
  }
}
