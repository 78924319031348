import { CreatedModel, DataListModel } from '@kanzi-apes/kanzi-models';

export interface ServicesListModel extends DataListModel {
  results: Services[];
}

export interface Services {
  id: number;
  created_by: CreatedModel;
  modified_by: CreatedModel;
  customer: Customer;
  created: string;
  modified: string;
  is_active: boolean;
  name: string;
  description: string;
  external_id: null;
  price: number;
  params: any;
  created_timestamp: number;
  modified_timestamp: number;
}



export interface Customer {
  id: number;
  name: string;
  type_customer: string;
  identification: null;
  parent: number;
}

export interface ServicesSelectedModel {
  id:number;
  external_id:string;
  description: string;
  price: number;
  amount:number;
  total_price:number;
  iva_price?:number;
}
