export enum APP_MODULES {
  PR_CREATE = 'PR_001',
  PR_LIST = 'PR_002',
  PR_DETAIL = 'PR_003',
  CT_CREATE = 'CT_001',
  CT_LIST = 'CT_002',
  CT_DETAIL = 'CT_003',
  IM_ITEM_DETAIL = 'IM_001',
  IM_ITEM_POSITION = 'IM_002',
  IM_ITEM_RECIEVE = 'IM_003',
  IM_RESUME = 'IM_004',
  PO_CREATE = 'PO_001',
  PO_LIST = 'PO_002',
  PO_DETAIL = 'PO_003',
  PUO_CREATE = 'PUO_001',
  PUO_LIST = 'PUO_002',
  PUO_DETAIL = 'PUO_003',
  SO_CREATE = 'SO_001',
  SO_LIST = 'SO_002',
  SO_DETAIL = 'SO_003',
  DT_CREATE = 'DT_001',
  DT_LIST = 'DT_003',
  DT_DETAIL = 'DT_002',
  WO_CREATE = 'WO_001',
  WO_LIST = 'WO_002',
  WO_DETAIL = 'WO_003',
  PK_DETAIL = 'PK_001',
  PK_DISPATCH = 'PK_002',
  PK_LIST = 'PK_003',
  PK_DETROY = 'PK_004',
  MV_LIST = 'MV_001',
  AD_CREATE = 'AD_001',
  AD_LIST = 'AD_002',
  AD_ADJUST = 'AD_003',
  HW_LIST = 'HW_002',
  PRNT_LIST = 'PRNT_002',
  PRNT_DETAIL = 'PRNT_003',
  PRNT_CREATE = 'PRNT_001',
  TRKG_LIST = 'TRKG_001'
}
