import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ValidationsFormsService {
  private subject = new Subject<any>();
  private onSubmit = new Subject<any>();

  constructor() {}

  validationForm(data: boolean) {
    this.subject.next(data);
  }

  onClickbtn(data: boolean) {
    this.onSubmit.next(data);
  }

  getOnSubmit(): Observable<any> {
    return this.onSubmit.asObservable();
  }

  getValidation(): Observable<any> {
    return this.subject.asObservable();
  }

  noWhitespaceValidator(control: UntypedFormControl) {
    if (control.value) {
      const value =
        typeof control.value === 'object'
          ? control.value.external_id.trim()
          : control.value.trim();
      const reg = /\s/;
      const isWhiteSpace = reg.test(value);
      return !isWhiteSpace
        ? null
        : {
            whitespace: true,
          };
    } else {
      return null;
    }
  }
}
