import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  inject,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ConfigPackageList, DataListModel } from '@kanzi-apes/kanzi-models';
import { LazyLoadEvent } from '@kanzi-apes/kanzi-prime-ui';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';
import { PacketListService } from './packet-list.service';
import { EMPTY, expand, map } from 'rxjs';

@Component({
  selector: 'kanzi-package-list',
  templateUrl: './kanzi-package-list.component.html',
  styleUrls: ['./kanzi-package-list.component.scss'],
})
export class KanziPackageListComponent implements OnChanges {
  @Input() configuration: ConfigPackageList | null;
  @Input() dataSource: DataListModel | null;
  @Input() loading: boolean;
  @Output() packSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() onloadLazy: EventEmitter<LazyLoadEvent> =
    new EventEmitter<LazyLoadEvent>();

  envService: EnvironmentService = inject(EnvironmentService);
  countPacket = 0;
  countItems = 0;

  constructor(private readonly packetListService: PacketListService) {
    this.configuration = null;
    this.dataSource = null;
    this.loading = false;
  }

  ngOnChanges(): void {
    if (this.dataSource && this.countPacket===0  && this.countItems===0 ) {
      this.countPacket = this.dataSource
        ? this.dataSource.count
          ? this.dataSource.count
          : 0
        : 0;
      this.countItems = this.dataSource.results
        .map((res) => res.total_amount)
        .reduce((total, value) => total + value);

      // if (this.dataSource.next) {
      //   this.getItemsCount(this.dataSource.next);
      // }
    }
  }

  // getItemsCount(url: string) {
  //   this.packetListService
  //     .getPackageList(url)
  //     .pipe(
  //       expand(({ next }: any) =>
  //         next ? this.packetListService.getPackageList(next) : EMPTY
  //       )
  //     )
  //     .subscribe({
  //       next: (response) => {
  //         const currentItems = response.results
  //           .map((res) => res.total_amount)
  //           .reduce((total, value) => total + value);

  //         this.countItems = this.countItems + currentItems;
  //       },
  //     });
  // }

  loadLazy(event$: LazyLoadEvent) {
    this.onloadLazy.emit(event$);
  }
  selectPack(pack: any) {
    this.packSelected.emit(pack);
  }
}
