import { Action } from '@ngrx/store';
import {
  MonitoringActions,
  MonitoringActionTypes,
} from '../../actions/monitoring';
import { KanziStatus, StatusApiMsgModel } from '@kanzi-apes/kanzi-models';

export interface State {
  status: { actual: string | null; previous: string | null } | null;
  statusMsg: StatusApiMsgModel | null;
}

export const initialState: State = {
  status: { actual: KanziStatus.ONLINE, previous: null },
  statusMsg: null,
};

export function reducer(state = initialState, action: Action): State {
  const monitoringActions = action as MonitoringActions;
  switch (monitoringActions.type) {
    case MonitoringActionTypes.StatusOnline: {
      return {
        ...state,
        status: {
          actual: KanziStatus.ONLINE,
          previous: state.status?.actual
            ? state.status?.actual
            : KanziStatus.OFFLINE,
        },
        statusMsg: monitoringActions.payload,
      };
    }
    case MonitoringActionTypes.StatusOffline: {
      return {
        ...state,
        status: {
          actual: KanziStatus.OFFLINE,
          previous: state.status?.actual
            ? state.status?.actual
            : KanziStatus.ONLINE,
        },
        statusMsg: monitoringActions.payload,
      };
    }
    default:
      return state;
  }
}

export const getStatus = (state: State) => state.status;
export const getStatusMsg = (state: State) => state.statusMsg;
