export function toFormData<T>(formValue: T) {
  const formData = new FormData();
  const keyName = ['photo','file','master_file_sku','master_file_location'];
  for (const key of Object.keys(formValue as Object)) {
    const value: any = formValue[key as keyof typeof formValue];
    if (typeof value === 'object' && value && !keyName.includes(key) ) {
      const valueTransform = JSON.stringify(value);
      formData.append(key, valueTransform);
    } else {
      formData.append(key, value);
    }
  }
  return formData;
}
