import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ComponentFactoryResolver,
  OnChanges,
} from '@angular/core';
import { AdDirective } from '../../directives/add.directive';
import { AddComponent } from '@kanzi-apes/kanzi-models';

@Component({
  selector: 'kanzi-dynamic-component',
  templateUrl: './kanzi-dynamic-component.component.html',
  styleUrls: ['./kanzi-dynamic-component.component.css'],
})
export class KanziDynamicComponentComponent implements OnInit, OnChanges {
  @Input() dynamicComponent: AddComponent | null;
  @Input() data: any;
  @ViewChild(AdDirective, { static: true }) adHost: AdDirective | null;
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    this.dynamicComponent = null;
    this.data = null;
    this.adHost = null;
  }

  ngOnInit() {}
  ngOnChanges() {
    if (this.adHost && this.dynamicComponent) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          this.dynamicComponent.component
        );
      const viewContainerRef = this.adHost.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent(componentFactory);
      if (this.dynamicComponent.data) {
        (<AddComponent>componentRef.instance).data = this.dynamicComponent.data;
      } else if (this.data) {
        (<AddComponent>componentRef.instance).data = this.data;
      }
    }
  }
}
