import { ModuleAction } from '@kanzi-apes/kanzi-models';
import { MenuItem } from 'primeng/api';

export function validator(
  permission: string,
  permissionList: ModuleAction[]
): boolean {
  return (
    permissionList.filter((item) => item.code === permission).length > 0 ||
    permission === undefined
  );
}

export function menuValidator(
  menuItems: MenuItem[],
  permissionList: ModuleAction[]
): MenuItem[] {
  return menuItems.map((menuItem) => {
    return {
      ...menuItem,
      visible: validator(<string>menuItem.automationId, permissionList),
      items: menuItem.items
        ? menuItem.items.length > 0
          ? menuValidator(menuItem.items, permissionList)
          : undefined
        : undefined,
    };
  });
}
