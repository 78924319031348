import { TreeMapListModel } from '../../interfaces';
import { Queue } from './queue.class';
export class TreeMap implements TreeMapListModel {
  id: number;
  name: string;
  items: any[];
  value?: number;
  parent_id?: number | null;
  parent_node?: any;

  constructor(
    name: string,
    id?: number,
    value?: number,
    parent_id?: number | null,
    parent_node?: any,
    items: any[] = []
  ) {
    this.name = name;
    this.value = value;
    this.id = id ? id : 0;
    this.items = items;
    this.parent_id = parent_id;
    this.parent_node = parent_node;
  }

  traverseDF(callback: any) {
    (function recurse(currentNode: TreeMapListModel) {
      for (let i = 0, length = currentNode.items.length; i < length; i++) {
        recurse(currentNode.items[i]);
      }
      callback(currentNode);
    })(this);
  }

  traverseBF(callback: any) {
    const queue = new Queue();
    queue.enqueue(this);
    let currentTree = queue.dequeue();
    while (currentTree) {
      for (let i = 0, length = currentTree.items.length; i < length; i++) {
        queue.enqueue(currentTree.items[i]);
      }
      callback(currentTree);
      currentTree = queue.dequeue();
    }
  }

  contains(callback: any, traversal: any) {
    traversal.call(this, callback);
  }

  add(data: any, toData: any, traversal: any) {
    const child = new TreeMap(data);
    let parent: any = null;
    const callback = function (node: any) {
      if (node.name === toData) {
        parent = node;
      }
    };

    this.contains(callback, traversal);

    if (parent) {
      parent.items.push(Object.assign({}, child));
    }
  }

  editLastChild(value: any, toData: any, traversal: any) {
    const child = value;
    let parent: any = null;
    const callback = function (node: any) {
      if (node.name === toData) {
        parent = node;
      }
    };

    this.contains(callback, traversal);

    if (parent) {
      parent.value = child;
    }
  }
}
