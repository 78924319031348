/* eslint-disable @nx/enforce-module-boundaries */

import { Injectable } from '@angular/core';
import { PackageListModel } from '@kanzi-apes/kanzi-models';
import { KanziRestClientService } from '@kanzi-apes/kanzi-rest-api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PacketListService {
  constructor(private restClient: KanziRestClientService) {}

  getPackageList(url: string): Observable<PackageListModel> {
    return this.restClient.get<PackageListModel>(
      url,
      null,
      `Error al obtener las cajas.`
    );
  }
}
