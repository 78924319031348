import { Component, OnInit, forwardRef } from '@angular/core';
import { ItemTypesService } from './item-types.service';
import { ItemTypesModel } from './models';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'input-item-types',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KanziItemTypesComponent),
      multi: true,
    },
  ],
  template: `
    <p-dropdown
      [options]="optionsItems"
      [(ngModel)]="itemControl"
      [disabled]="isDisabled"
      (ngModelChange)="onChangeOption($event)"
      optionLabel="display_name"
      placeholder="Seleccionar"
    ></p-dropdown>
  `,
})
export class KanziItemTypesComponent implements ControlValueAccessor {
  private onChanged!: (value: number) => void;
  private onTouch!: () => void;

  public optionsItems: ItemTypesModel[] = [];
  public itemControl: ItemTypesModel | null = null;
  public isDisabled = false;

  constructor(private readonly itemTypesService: ItemTypesService) {}

  ngOnInit(): void {
    this.itemTypesService.getItemTypes(null).subscribe({
      next: (response) => {
        if (response) {
          this.optionsItems = response.results;
        }
      },
    });
  }

  // validate(control: AbstractControl<any, any>): ValidationErrors | null {
  //   return control.hasValidator(Validators.required) && !this.itemControl
  //     ? {
  //         isRequired: true,
  //       }
  //     : null;
  // }

  // registerOnValidatorChange?(fn: () => void): void {
  //   this.onChanged = fn;
  // }

  writeValue(id: any): void {
    console.info('Write ID: ', id);
    const findObj = this.optionsItems.find((i) => i.id == id);
    console.info('Find Obj: ', findObj);
    if (findObj) {
      this.itemControl = findObj;
    }
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChangeOption(event: ItemTypesModel) {
    this.onChanged(event.id);
  }
}
