import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  LOCATIONS_FEATURE_KEY,
  LocationsState,
  locationsAdapter,
} from './locations.reducer';

// Lookup the 'Locations' feature state managed by NgRx
export const getLocationsState = createFeatureSelector<LocationsState>(
  LOCATIONS_FEATURE_KEY
);

const { selectAll, selectEntities } = locationsAdapter.getSelectors();

export const getSelectedLocationId = createSelector(
  getLocationsState,
  (state: LocationsState) => state.selectedId
);

export const getLocationsLoading = createSelector(
  getLocationsState,
  (state: LocationsState) => state.loading
);

export const getWarehouseRootList = createSelector(
  getLocationsState,
  (state: LocationsState) => state.warehouseRootList
);

export const getWarehouseRootSelected = createSelector(
  getLocationsState,
  (state: LocationsState) => state.warehouseRootSelected
);

export const getWarehouseRootFilter = createSelector(
  getLocationsState,
  (state: LocationsState) => state.warehouseRootFilter
);

export const getLocationsError = createSelector(
  getLocationsState,
  (state: LocationsState) => state.error
);

export const getAllLocations = createSelector(
  getLocationsState,
  (state: LocationsState) => selectAll(state)
);

export const getLocationsEntities = createSelector(
  getLocationsState,
  (state: LocationsState) => selectEntities(state)
);
