import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UserListService } from '@kanzi-apes/kanzi-auth';
import {
  CustomerModel,
  UserListModel,
  UserModel,
  UserRoles,
} from '@kanzi-apes/kanzi-models';

@Component({
  selector: 'kanzi-apes-kanzi-user-assign-modal',
  templateUrl: './kanzi-user-assign-modal.component.html',
  styleUrls: ['./kanzi-user-assign-modal.component.scss'],
})
export class KanziUserAssignModalComponent implements OnInit {
  @Input() customer: CustomerModel | null;
  @Input() openDialog: boolean;
  @Input() title: string;
  @Output() openDialogChange = new EventEmitter<boolean>();
  @Output() onUserAssignSelected = new EventEmitter<UserModel>();

  get openDialogModal(): boolean {
    return this.openDialog;
  }

  set openDialogModal(data: boolean) {
    this.openDialog = data;
    this.openDialogChange.emit(data);
  }

  userAssignForm = this.fb.group({
    userAssigned: ['', Validators.required],
  });

  get userAssigned() {
    return this.userAssignForm.get('userAssigned');
  }

  filteredUser: UserModel[] = [];

  /**
   *
   * @param fb {FormBuilder} Builder to User Assign Form
   * @param userListService {UserListService} Service to ger Users List
   */
  constructor(
    private fb: UntypedFormBuilder,
    private userListService: UserListService
  ) {
    this.title = '';
    this.openDialog = false;
    this.customer = null;
  }

  ngOnInit(): void {}

  onSubmit() {
    if (this.userAssignForm.valid) {
      this.onUserAssignSelected.emit(this.userAssignForm.value.userAssigned);
    }
  }

  filterUserList(event: any) {
    const query = event.query;
    this.userListService
      .getUsersList({
        page: 1,
        page_size: 1000,
        role__name: UserRoles.ASSISTANT_LEADER,
        search: query,
        customer_id: this.customer ? this.customer.id : null,
      })
      .subscribe((list: UserListModel) => {
        this.filteredUser = list.results;
      });
  }

  oncloseModal() {
    this.userAssignForm.reset();
  }
}
