import { DataListModel, DataModel } from '../../data';
import { FiltersModel } from '../../filters';
import { EventPlannerModel } from '../event-planner';
import { SkuModel } from '../../products';
import { MaintenanceTypeModel } from '../../maintenance-configs';
import { EventTaskModel } from '..';

/**
 * Tasks models
 */

export interface PendingTaskFilterModel extends FiltersModel {
  customer_id?: number;
}

export interface TaskListModel extends DataListModel {
  results: TaskModel[];
}

export interface TaskModel extends DataModel {
  name: string;
  nextDate: string;
  periodicity: string;
  priority: string;
  asset: SkuModel;
  event_planner: EventPlannerModel;
}

export interface EventTasksFilterModel extends FiltersModel {
  event_planner_id?: number;
  group_id?:number;
}

export interface FilesFilterModel extends FiltersModel {
  event_task_id?: number;
}

export interface EventTasksCreateModel {
  id?: number;
  event_planner_id?: number;
  external_id?: string;
  name: string;
  description: string;
  status?: string;
  type_id: number;
  estimated_time: string;
  params?: any;
  properties: any;
  type?: MaintenanceTypeModel;
}

export interface EventTasksCreateListModel extends DataListModel {
  results: EventTasksCreateModel[];
}

export interface EventTasksModel extends DataModel {
  id: number;
  event_planner: EventPlannerModel;
  external_id: string;
  name: string;
  description: string;
  status: string;
  type_name?: string;
  type?: MaintenanceTypeModel;
  classification_name?: string;
  active?: boolean;
  estimated_time: string;
  params: any;
  properties: any;
}

export interface EventTasksListModel extends DataListModel {
  results: EventTasksModel[];
}

/**
 *
 * Upload file
 */

export interface FilesModel extends DataModel {
  id: number;
  event_task: EventTask;
  external_id: string;
  name: string;
  description: string;
  file: string;
}

export interface EventTask {
  id: number;
  event_planner: number;
  external_id: string;
  name: string;
  type: string;
  properties: Properties;
}

export interface Properties {
  priority: string;
  sub_group_1: string;
}

export interface UploadFileModel {
  id?: number;
  event_task_id: number;
  external_id?: string;
  name: string;
  description?: string;
  file: File;
  event_task?: any; //TODO:ADD INTERFACE
}

export interface UploadFilesListModel extends DataListModel {
  results: FilesModel[];
}

export interface SkuEventTaskCreateModel {
  name?:string;
  external_id?:string,
  event_task_id?: number;
  sku_id: number;
  status: string;
  expected_start_date?: string;
  warning_date?: string;
}
export interface SkuEventTaskModel extends DataModel {
  id: number;
  event_task: EventTaskModel;
  event_task_name?: string;
  sku: SkuModel;
  sku_display_name?: string;
  sku_external_id?: string;
  event_planner_description?: string;
  status: string;
  expected_start_date: string;
  warning_date: string;
  expected_start_date_timestamp: number;
  warning_date_timestamp: number;
}

export interface SkuEventTaskListModel extends DataListModel {
  results: SkuEventTaskModel[];
}

export interface SkuEventTaskFilterModel extends FiltersModel {
  event_task_id?: number;
  expected_start_date_min?: string;
  expected_start_date_max?: string;
  status?: string;
  sku_id?: number;
}
