import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Authenticate } from '@kanzi-apes/kanzi-models';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';

/**
 * @author Hugo Andrés Escobar Ciceri
 * @version 5.0.0
 *
 * Form Component to Login Component
 */
@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  @Input()
  set pending(isPending: boolean) {}
  @Input() errorMessage: string = '';
  @Output() submitted = new EventEmitter<Authenticate>();

  login: Authenticate;
  loginFormGroup: UntypedFormGroup = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });

  constructor(private fb: UntypedFormBuilder) {
    this.login = {
      username: '',
      password: '',
    };
  }

  ngOnInit() {}

  get username() {
    return this.loginFormGroup.get('username');
  }

  get password() {
    return this.loginFormGroup.get('password');
  }

  /**
   *
   * @param  formControls
   */
  onSignIn() {
    if (this.loginFormGroup.valid) {
      this.submitted.emit(this.loginFormGroup.value);
    }
  }
}
