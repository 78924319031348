import { Component, OnInit, Input } from '@angular/core';

@Component({

  selector: 'kanzi-badge-status',
  templateUrl: './kanzi-badge-status.component.html',
  styleUrls: ['./kanzi-badge-status.component.scss'],
})
export class KanziBadgeStatusComponent implements OnInit {
  @Input() data: any;

  constructor() {}

  ngOnInit() {
  }
}
