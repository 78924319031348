export enum KanziStatus {
  ABORTED = 'ABORTED',
  ADJUSTED = 'ADJUSTED',
  CALCULATED = 'CALCULATED',
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED',
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  DISPATCHED = 'DISPATCHED',
  DRAFT = 'DRAFT',
  DISTRIBUTED = 'DISTRIBUTED',
  ENLISTED = 'ENLISTED',
  ERROR = 'error',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  FINISHED = 'FINISHED',
  INCOMPLETE = 'INCOMPLETE',
  INFO = 'info',
  INVALID = 'INVALID',
  IN_PROCESS = 'IN_PROCESS',
  IN_PROGRESS = 'IN_PROGRESS',
  BUDGET_PENDING = 'BUDGET_PENDING',
  ISSUED = 'ISSUED',
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  PACKED = 'PACKED',
  PRINTED = 'PRINTED',
  PROCESSING = 'PROCESSING',
  SALE = 'SALE',
  SHEDULED = 'SHEDULED',
  SUCCESS = 'success',
  VERIFIED = 'VERIFIED',
  WARN = 'warn',
  UNSOLVED = 'UNSOLVED',
  SOLVED = 'SOLVED',
  PRESENT = 'PRESENT',
  NOT_PRESENT = 'NOT_PRESENT',
  DEMORADO = 'Demorado',
  'A TIEMPO' = 'A tiempo',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  BY_ASSIGN = 'BY_ASSIGN',
  MAINTENANCE_PENDING = 'MAINTENANCE_PENDING',
  WARNING = 'warning',
  BLOCKED = 'BLOCKED',
}
