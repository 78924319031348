import { UserModel } from '../user';
export interface CustomerModel {
  id: number;
  created_by: UserModel;
  modified_by: UserModel;
  created: string;
  modified: string;
  external_id: string;
  name: string;
  display_name: string;
  identification: string;
  type_customer: string;
  type_identification: string;
  parent: number;
  created_timestamp: number;
  modified_timestamp: number;
  properties?: any;
}
export interface CustomerCreateModel {
  id?: number;
  external_id?: string;
  name: string;
  display_name: string;
  identification?: string;
  type_customer?: string;
  type_identification?: string;
  parent?: number | null;
  //location?:string;
}

export interface CustomerListModel {
  count: number;
  next: string;
  previous: string;
  results: CustomerModel[];
}

export interface CustomerFilterModel {
  page?: number;
  page_size?: number;
  external_id?: string;
  type_customer?: string;
  identification?: string;
  parent_id?: number;
  created_min?: string;
  created_max?: string;
  modified_min?: string;
  modified_max?: string;
  search?: string;
  ordering?: string;
}

export interface CustomerGroupFilterModel {
  page?: number;
  page_size?: number;
  external_id?: string;
  name?:string;
  display_name?:string;
  customer_id:number;
}

export interface CustomerGroupListModel {
  count:    number;
  next:     null;
  previous: null;
  results:  CustomerGroupModel[];
}

export interface CustomerGroupModel {
  id:           number;
  external_id:  null;
  display_name: string;
  customer:     Customer;
  created_by:   EdBy;
  modified_by:  EdBy;
  created:      string;
  modified:     string;
  name?:        string;
}

export interface EdBy {
  id:         number;
  username:   string;
  first_name: string;
  last_name:  string;
  email:      string;
}

export interface Customer {
  id:            number;
  name:          string;
  type_customer: string;
}
