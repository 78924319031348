import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromAuth from '@kanzi-apes/kanzi-auth';

@Injectable()
export class LocationsInterceptor implements HttpInterceptor {

  constructor( private storeAuth: Store<fromAuth.State>) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    console.log("🚀 ~ file: locations.interceptor.ts:18 ~ LocationsInterceptor ~ intercept ~ request:", request)
    return this.storeAuth.select(fromAuth.getUser).pipe(
      switchMap((user)=>{
        console.log('USER',user);
        
        // if(request.method==='GET'){
          
        // }

        return next.handle(request)
      })

    )
  }
}
