import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-empty-module-display',
  templateUrl: './empty-module-display.component.html',
  styleUrls: ['./empty-module-display.component.css'],
})
export class EmptyModuleDisplayComponent implements OnInit, OnChanges {
  @Input() section: string;
  content: any | null = null;
  contents: any[];
  constructor() {
    this.section = '';
    this.contents = [
      {
        section: 'contacts',
        title: 'Aún no tienes contactos',
        contentText: 'Agrega y administra tus contactos o clientes desde aquí',
        buttonText: 'Crear Contacto',
        link: '../create',
      },
      {
        section: 'products',
        title: 'Aún no tienes productos',
        contentText: 'Agrega y administra tus productos desde aquí',
        buttonText: 'Crear Producto',
        link: '../create',
      },
      {
        section: 'purchases',
        title: 'Aún no tienes pedidos',
        contentText: 'Agrega y administra tus pedidos desde aquí',
        buttonText: 'Crear Pedido',
        link: '../create',
      },
      {
        section: 'distributions',
        title: 'Aún no tienes distribuciones',
        contentText: 'Agrega y administra tus distribuciones desde aquí',
        buttonText: 'Crear Distribuciones',
        link: '../create',
      },
      {
        section: 'productions',
        title: 'Aún no tienes órdenes de producción',
        contentText: 'Agrega y administra tus órdenes desde aquí',
        buttonText: 'Crear Órden de Producción',
        link: '../create',
      },
      {
        section: 'audits',
        title: 'Aún no tienes auditorías',
        contentText: 'Agrega y administra tus auditorías desde aquí',
        buttonText: 'Crear Auditoría',
        link: '../create',
      },
      {
        section: 'transfers',
        title: 'Aún no tienes movimientos',
        contentText: 'Crea un movimiento desde la app móvil',
        buttonText: 'Movimientos',
        link: '../list',
      },
      {
        section: 'packages',
        title: 'Aún no tienes cajas',
        contentText: 'Crea tus cajas desde la aplicación móvil',
        buttonText: 'Listar pedidos',
        link: '../../purchases/list',
      },
      {
        section: 'prints',
        title: 'Aún no tienes órdenes de impresión',
        contentText: 'Crea tus órdenes desde una órden de producción',
        buttonText: 'Listar órdenes producción',
        link: '../list',
      },
      {
        section: 'purchaseOrders',
        title: 'Aún no tienes órdenes de compra',
        contentText: 'Agrega y administra tus órdenes desde aquí',
        buttonText: 'Crear Órden de Compra',
        link: '../create',
      },
      {
        section: 'workOrders',
        title: 'Aún no tienes órdenes de trabajo',
        contentText: 'Agrega y administra tus órdenes desde aquí',
        buttonText: 'Crear Órden de Trabajo',
        link: '../create',
      },
      {
        section: 'bookings',
        title: 'Aún no tienes reservas',
        contentText: 'Agrega y administra tus reservas desde aquí',
        buttonText: 'Crear Nueva Reserva',
        link: '../create',
      },
    ];
  }

  ngOnInit() {}

  ngOnChanges() {
    if (this.section) {
      for (let i = 0; i < this.contents.length; ++i) {
        // code...
        if (this.contents[i].section === this.section) {
          this.content = this.contents[i];
        }
      }
    }
  }
}
