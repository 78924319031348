import { ConfigTableModel, LinksTableOptions } from '@kanzi-apes/kanzi-models';
export class ConfigSimpleTable implements ConfigTableModel {
  simple: boolean;
  sortMode?: string;
  paginator?: boolean;
  rows?: number;
  options?: LinksTableOptions;
  downloadFile?: boolean;
  fileName?: string;
  search?: boolean;
  lazy?: boolean;
  resize?: boolean;
  isHidenCaption?: boolean;
  multiSelect?: boolean;
  onlySelect?: boolean;
  filters?: {
    date?: boolean;
    fields?: boolean;
  };

  /**
   * @param filename {String} File name to export table data
   * @param linksOptions {LinksTableOptions} Options to column links {view, edit, delete...}
   */
  constructor(
    filename: string,
    linksOptions: LinksTableOptions | null = null,
    tableOptions: { multiselect: boolean; onlySelect?: boolean } = {
      multiselect: false,
      onlySelect: false,
    }
  ) {
    this.simple = true;
    this.sortMode = 'single';
    this.paginator = true;
    this.rows = 50;
    this.options = linksOptions!;
    this.downloadFile = true;
    this.fileName = filename;
    this.search = true;
    this.lazy = false;
    this.resize = true;
    this.isHidenCaption = false;
    this.filters = {
      date: false,
      fields: true,
    };
    this.multiSelect = tableOptions.multiselect;
    this.onlySelect = tableOptions.onlySelect;
  }
}
