import { ConfigTableModel, LinksTableOptions } from '@kanzi-apes/kanzi-models';
export class ConfigLazyTable implements ConfigTableModel {
  simple: boolean;
  sortMode?: string;
  paginator?: boolean;
  rows?: number;
  options?: LinksTableOptions | null;
  downloadFile?: boolean;
  fileName?: string;
  search?: boolean;
  lazy?: boolean;
  resize?: boolean;
  filters?: {
    date?: boolean;
    fields?: boolean;
  };
  multiSelect?: boolean;
  onlySelect?: boolean;
  clear_filters?: boolean;

  /**
   * @param filename {String} File name to export table data
   * @param linksOptions {LinksTableOptions} Options to column links {view, edit, delete...}
   * @param filterOptions {date: boolean, fields: boolena} Options to filter: Date, Column Fields
   */
  constructor(
    filename: string,
    linksOptions: LinksTableOptions | null,
    filterOptions = {
      date: true,
      fields: true,
    },
    tableOptions: { multiselect: boolean; onlySelect?: boolean } = {
      multiselect: false,
      onlySelect: false,
    },
    clearButton = {
      clear_filter: false,
    }
  ) {
    this.simple = false;
    this.sortMode = 'single';
    this.paginator = true;
    this.rows = 50;
    this.options = linksOptions;
    this.downloadFile = false;
    this.fileName = filename;
    this.search = true;
    this.lazy = true;
    this.resize = true;
    this.filters = filterOptions;
    this.multiSelect = tableOptions.multiselect;
    this.onlySelect = tableOptions.onlySelect;
    this.clear_filters = clearButton.clear_filter;
  }
}
