import { UserModel } from '../user';
import { LocationModel } from '../location';
import { FiltersModel } from '../filters';

export interface HardwareModel {
  mac: string;
  id: number;
  created_by: UserModel;
  modified_by: UserModel;
  readers: any[];
  location: LocationModel;
  created: string;
  modified: string;
  name: string;
  description: string;
  action: string;
  profile: string;
  batch_max_size: number;
  time_remove_old_tags: number;
  waiting_window: number;
  time_zone_hours: number;
  backup_send_hour: number;
  save_readings_local: boolean;
  hardware_properties: any;
  gui_properties: any;
  epc_hex_read_length: number;
  epc_hex_write_length: number;
  user: number;
  created_timestamp: number;
  modified_timestamp: number;
}

export interface NodeActivityModel {
  epc: string;
  antenna: number;
  action: string;
  created: string;
  item: any;
  node: HardwareModel;
  created_timestamp: number;
}

export interface HardwareFilterModel extends FiltersModel {
  page: number;
  mac?: string;
  created_min?: string;
  created_max?: string;
  created?: string;
  search?: string;
}
