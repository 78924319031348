import {
  ProductionOrderModel,
  ProductionOrderListModel,
} from '../../interfaces';
export class ProductionOrderList implements ProductionOrderListModel {
  count: number;
  next: string;
  previous: string;
  results: ProductionOrderModel[];

  constructor() {
    this.count = 0;
    this.next = '';
    this.previous = '';
    this.results = [];
  }
}
