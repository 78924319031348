import { Component, Injectable, Input, OnChanges } from '@angular/core';
import { KanziRestClientService } from '@kanzi-apes/kanzi-rest-api';
import { Observable } from 'rxjs';
import { FiltersModel, DataListModel } from '@kanzi-apes/kanzi-models';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';

@Component({
  selector: 'kanzi-count-widget',
  templateUrl: './kanzi-count-widget.component.html',
  styleUrls: ['./kanzi-count-widget.component.scss'],
})
export class KanziCountWidgetComponent implements OnChanges {
  @Input() description: string;
  @Input() filter: FiltersModel | null;
  @Input() path: string;
  @Input() urlRedirect: string;
  @Input() type: string;

  totalCount = 0;
  boxClass = '';

  constructor(private kanziCountService: KanziCountWidgetService) {
    this.description = '';
    this.filter = null;
    this.path = '';
    this.urlRedirect = '';
    this.type = '';
  }

  ngOnChanges() {
    if (this.type) {
      switch (this.type) {
        case 'warning': {
          this.boxClass = 'bg-yellow';
          break;
        }

        case 'primary': {
          this.boxClass = 'bg-aqua';
          break;
        }

        case 'success': {
          this.boxClass = 'bg-green';
          break;
        }

        case 'danger': {
          this.boxClass = 'bg-red';
          break;
        }

        default:
          this.boxClass = 'bg-aqua';
      }
    }
    if (this.filter) {
      this.filter = {
        ...this.filter,
        page: 1,
        page_size: 1,
      };
      this.kanziCountService
        .getDataTotals(this.path, this.filter)
        .subscribe((response) => {
          this.totalCount = response.count;
        });
    }
  }
}

@Injectable({ providedIn: 'root' })
export class KanziCountWidgetService {
  private kongUrl = this.envService.environment?.kongApi;
  constructor(
    private restClient: KanziRestClientService,
    private envService: EnvironmentService
  ) {}

  getDataTotals(path: string, filter: FiltersModel): Observable<DataListModel> {
    return this.restClient.get(
      this.kongUrl ? this.kongUrl.concat(path) : '',
      filter,
      `Error al obtener los totales`
    );
  }
}
