import { QuestionBase } from '../../classes';

export interface ConfigTableModel {
  key?: string;
  sortMode?: any;
  paginator?: boolean;
  rows?: number;
  options?: LinksTableOptions | null;
  downloadFile?: boolean;
  fileName?: string;
  search?: boolean;
  lazy?: boolean;
  simple: boolean;
  resize?: boolean;
  isHidenCaption?:boolean;
  displayFilters?:boolean;
  filters?: {
    date?: boolean;
    fields?: boolean;
  };
  multiSelect?: boolean;
  onlySelect?: boolean;
  expandRow?: boolean;
  expandOptions?: {
    form?: boolean;
    formQuestions?: QuestionBase<any>[];
    data?: boolean;
    dataModel?: any;
  };
  validation_user?: boolean;
  clear_filters?: boolean;
  virtualScroll?: boolean;
  virtualScrollItemSize?: number;
  scrollHeight?: string;
}

export interface LinksTableOptions {
  colHide?: boolean;
  view?: boolean;
  edit?: boolean;
  delete?: boolean;
  unsubscribe?:boolean;
  external?: boolean;
  externalUrlName?: string;
  customButton?:boolean;
  customButtonOptions?:{
    tooltipLabel?:string;
    icon?:string;
  }
}
