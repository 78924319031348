<div class="layout-topbar">
  <div class="topbar-left">
    <a
      #menubutton
      tabindex="0"
      class="menu-button"
      (click)="onMenuButtonClick()"
    >
      <i class="pi pi-chevron-left"></i>
    </a>
    <img
      class="horizontal-logo"
      [routerLink]="['/']"
      [src]="'assets/images/logos/logo-apes-' + logo + '.png'"
      alt="Kanzi-Logo"
      joyrideStep="logoStep"
      title="Logo de Kanzi"
      text="También sirve para navegar al módulo inicial por defecto"
    />
    <span class="topbar-separator"></span>
    <kanzi-breadcrumb></kanzi-breadcrumb>
    <img
      class="mobile-logo"
      [src]="'assets/images/logos/logo-apes-' + logo + '.png'"
      alt="Kanzi-Logo"
    />
  </div>
  <div class="layout-topbar-menu-section">
    <kanzi-kanzi-main-sidebar
      [user]="user"
      [logoPath]="logoPath"
      [status]="status"
      [modulesApp]="environment"
      [menuItems]="menuItems"
    ></kanzi-kanzi-main-sidebar>
  </div>
  <div class="layout-mask modal-in"></div>
  <div class="topbar-right">
    <ul class="topbar-menu">
      <!-- <li *ngIf="envService.environment?.selectedLocation">
        <p-dropdown
          [options]="user?.configs?.audit_locations ?? []"
          [(ngModel)]="selectedLocation"
          optionLabel="name"
        ></p-dropdown>
      </li> -->
      <li>
        <app-customer-selector
          *ngIf="isParent && customers"
          [customerSelected]="customerSelected"
          [customers]="customers"
          (selectCustomer)="selectCustomer($event)"
        ></app-customer-selector>
      </li>
      <li
        class="profile-item static sm:relative"
        joyrideStep="profileStep"
        title="Usuario"
        text="Información del usuario y opción para salir de Kanzi."
      >
        <a
          tabindex="0"
          pStyleClass="@next"
          enterClass="hidden"
          enterActiveClass="scalein"
          leaveToClass="hidden"
          leaveActiveClass="fadeout"
          [hideOnOutsideClick]="true"
        >
          <img
            [src]="imageUserPath"
            alt="Kanzi-User-Avatar"
            class="profile-image"
          />
          <span class="profile-name"
            >{{ user?.first_name }} {{ user?.last_name }}</span
          >
        </a>
        <ul
          class="list-none p-3 m-0 border-round shadow-2 absolute surface-overlay hidden origin-top w-full sm:w-12rem mt-2 right-0 z-5 top-auto"
        >
          <li>
            <a
              pRipple
              class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
            >
              <i class="pi pi-user mr-3"></i>
              <span>{{ 'Perfil' | translate }}</span>
            </a>
          </li>
          <li>
            <a
              pRipple
              class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
              (click)="logout.emit()"
            >
              <i class="pi pi-power-off mr-3"></i>
              <span class="font-semibold">{{ 'Salir' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li
        class="lang-selector"
        joyrideStep="langStep"
        title="Idioma"
        text="Opción para cambiar el idioma de la aplicación."
      >
        <a
          tabindex="0"
          pStyleClass="@next"
          enterClass="hidden"
          enterActiveClass="scalein"
          leaveToClass="hidden"
          leaveActiveClass="fadeout"
          [hideOnOutsideClick]="true"
        >
          <span>{{ translateService.currentLang }}</span>
        </a>
        <ul
          class="list-none p-3 m-0 border-round shadow-2 absolute surface-overlay hidden origin-top w-full sm:w-12rem mt-2 right-0 z-5 top-auto"
        >
          <li *ngFor="let lang of languajes">
            <a
              pRipple
              class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
              (click)="changeLanguaje(lang)"
            >
              <span>{{ lang }}</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
