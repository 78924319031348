import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
// import { reducers } from './reducers';
// import { StoreModule } from '@ngrx/store';
// import { EffectsModule } from '@ngrx/effects';
// import { TranslateModule } from '@ngx-translate/core';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { CustomerEffects } from './effects';
// import { KanziAlertsModule } from '@kanzi-apes/kanzi-alerts';
// import { KanziUiModule } from '@kanzi-apes/kanzi-ui';
// import { PrimeUiModule } from '@kanzi-apes/kanzi-prime-ui';
// import { CustomerModuleRoutingModule } from './location-routing.module';
// import {
//   CreateCustomerComponent,
//   DetailCustomerComponent,
//   ListComponent,
//   EditCustomerComponent,
// } from './pages';
// import {
//   CustomerDetailHeaderComponent,
//   FormCustomerComponent,
// } from './components';

@NgModule({
  imports: [
    CommonModule,

    RouterModule.forChild([
      /* {path: '', pathMatch: 'full', component: InsertYourComponentHere} */
    ]),
    
  ],
})
export class KanziLocationsModule {}
