import { Directive, Input, input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[kTemplate]',
  standalone: false,
  host: {},
})
export class KanziTemplate {
  type = input<string | undefined>();
  @Input('kTemplate') name: string | undefined;

  constructor(public template: TemplateRef<any>) {}

  getType(): string {
    return this.name!;
  }
}
