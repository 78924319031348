import { UserModel } from '../user';
import { DataListModel, DataModel } from '../data';
import { FiltersModel } from '../filters';
import { LocationModel } from '../location';
import { SkuModel } from '../products';
import { ProductionOrderModel } from '../production-order';
import { PurchaseOrderModel } from '../purchase-order';

export interface PrintOrderLineFilterModel extends FiltersModel {
  print_order_id?: number;
}

export interface PrintOrderLineModel extends DataModel {
  id?: number;
  sku: SkuModel;
  initial_serial: number;
  amount: number;
  user_memory: string;
  print_order: number;
}

export interface PrintOrderLineListModel extends DataListModel {
  results: PrintOrderLineModel[];
}

export interface PrintOrderFilterModel extends FiltersModel {
  status?: string;
  location_id?: number;
  production_order__external_id?: string;
}

export interface TemplateOrderModel {
  id: number;
  name: string;
  description: string;
  url: string;
  dimensions: number[];
}

export interface PrintOrderModel extends DataModel {
  id?: number;
  status: string;
  prefix: string;
  external_id: number;
  serial_numbers_generated: boolean;
  location: LocationModel;
  location_display_name?: string;
  template: TemplateOrderModel;
  total_tags: number;
  production_order?: ProductionOrderModel;
  purchase_order?: PurchaseOrderModel;
  production_order_external_id?: string;
  purchase_order_external_id?: string;
  batch_number?: number;
  total_tags_used?: number;
  total_tags_void?: number;
  total_tags_programed?: number;
  created_by_name?: string;
}

export interface PrintOrderListModel extends DataListModel {
  results: PrintOrderModel[];
}

export interface PrintTemplateModel {
  id: number;
  created_by: UserModel;
  modified_by: UserModel;
  tag_reference: any;
  created: string;
  modified: string;
  name: string;
  description: string;
  url_template: string;
  print_offset: number[];
  created_timestamp: number;
  modified_timestamp: number;
}

export interface PrintTemplateListModel {
  count: number;
  next: string;
  previous: string;
  results: PrintTemplateModel[];
}

export interface PrintTemplateFilterModel extends FiltersModel {
  tag_reference?: string;
}
