import { CustomerModel } from '../customer';
import { DataListModel, DataModel } from '../data';
import { FiltersModel } from '../filters';
import { LocationModel } from '../location';
import { SkuModel } from '../products';

export interface StockSaleModel extends DataModel {
  id?: number;
  source: LocationModel;
  source_name?: string;
  type: string;
  status: string;
  prefix?: string;
  external_id?: string;
  total_amount: number;
}

export interface StockSaleListModel extends DataListModel {
  results: StockSaleModel[];
}

export interface StockSaleLineModel extends DataModel {
  sku?: SkuModel;
  sku_display_name?: string;
  sku_id?: string | number;
  sku_external_id?: string;
}

export interface StockSaleLineListModel extends DataListModel {
  results: StockSaleLineModel[];
}

export interface StockSaleFilterModel extends FiltersModel {
  type?: string;
}

export interface StockSaleLineFilterModel extends FiltersModel {
  move_id?: number;
  sku_id?: number;
  status?: string;
}

export interface StockSaleSummaryListModel extends DataListModel {
  results: StockSaleSummaryModel[];
}

export interface StockSaleSummaryModel extends DataModel {
  sub_location: LocationModel;
  customer: CustomerModel;
  type: string;
  total_amount: number;
  lines_count: number;
  percentage: number;
  properties?: any;
  media_percentage?: number;
}

export interface StockSaleSummaryFilterModel extends FiltersModel {
  type?: string;
  parent_id?: number;
  is_active?:boolean;
  sub_location__type_id?: number;
  reporter_source?: string;
  sub_location_id?: number;
  amount_type?: string;
}

export interface StockSaleSummaryLineListModel extends DataListModel {
  results: StockSaleSummaryLineModel[];
}

export interface StockSaleSummaryLineModel extends DataModel {
  sku: SkuModel;
  sku_display_name?: string;
  sku_external_id?: string;
  sku_code?: string;
  sku_product_code?: string;
  sku_group_name?: string;
  sku_category_name?: string;
  sku_gender_name?: string;
  sub_location_id: number;
  customer_id: number;
  type: string;
  total_amount: number;
  percentage: number;
  lines_count: number;
  sub_location_display_name?: string;
  sub_location?: LocationModel;
}

export interface StockSaleSummaryLineFilterModel extends FiltersModel {
  type?: string;
  parent_id?: number;
  sub_location__type_id?: number;
  reporter_source?: string;
  sub_location_id?: number;
}
