<div class="layout-footer">
  <div class="footer-logo-container">
    <img
      id="footer-logo"
      [src]="'assets/images/logos/logo-apes-' + logo + '.png'"
      alt="kanzi-layout"
    />
    <span
      class="footer-app-name"
      joyrideStep="versionStep"
      title="Kanzi Versión"
      text="Versión de la aplicación"
      >Kanzi {{ versionName }}</span
    >
  </div>
  <span class="footer-copyright">&#169; Technoapes - {{ year }}.</span>
</div>
