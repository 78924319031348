import { UserModel } from '../user';
import { StoreOrderModel, LineSkuModel } from '../store-order';
import { SkuModel } from '../products';
import { LocationModel } from '../location';
import { FiltersModel } from '../filters';
import { DataModel, DataListModel } from '../data';

export interface DistributionFilterModel extends FiltersModel {
  page?: number;
  page_size?: number;
  external_id?: string;
  status?: string;
  search?: string;
  store_order_external_id?: string;
  store_order_id?: number;
  location_source__names?:string;
  location_destination__names?:string;
}

export interface DistributionLinesFilterModel extends FiltersModel {
  id?: number;
  page?: number;
  search?: string;
  distribution_id?: number;
  sku_id?: string;
  page_size?: number;
}

export interface DistributionOrderLineFormCreateModel {
  sku_id: string | number;
  amount?: number;
  quantity?:number;
}

export interface DistributionOrderLineCreateModel {
  sku: SkuModel | null;
  sku_id: number | null;
  sku_external_id: string | null;
  sku_display_name: string | null;
  amount: number;
  in_work_order_amount: number;
  in_distribution_amount: number;
  packed_amount: number;
  balance: number;
  total_amount: number;
}

export interface DistributionOrderCreateModel {
  customer_id?: number;
  store_order_id: number;
  source_id: number;
  destination_id: number;
  external_id?: string;
  lines: DistributionOrderLineFormCreateModel[];
  properties?: any;
  finished?: boolean;
  finished_date?: Date;
  verified_file?: string;
  closed_by?: number;
  item_type_id?:number;
}

export interface DistributionModel extends DataModel {
  id: number;
  user_id: number;
  user: UserModel;
  external_id: string;
  finished_date: Date;
  finished: boolean;
  lines: DistributionLineModel[] | LineSkuModel[];
  destination_id: number;
  source_id: number;
  source_name?: string;
  source: LocationModel;
  destination: LocationModel;
  destination_name?: string;
  store_order_id: number;
  store_order_external_id?: string;
  store_order: StoreOrderModel;
  total_amount: number;
  packed_amount: number;
  total_quantities?:number;
  in_work_order_quantities?:number;
  packed_quantities?:number;
  in_work_order_amount: number;
  status: string;
  modified_by_name?: string;
}

export interface DistributionLineModel {
  display_name: string;
  sku: SkuModel;
  sku_id?: number | string;
  sku_display_name?: string;
  sku_external_id?: string;
  amount: number;
  in_work_order_amount: number;
  in_work_order_quantities:number;
  in_distribution_amount: number;
  packed_amount: number;
  packed_quantities:number;
  quantity:number;
  balance: number;
  total_amount: number;
  distribution_id: number;
}

export interface DistributionLineListModel extends DataListModel {
  results: DistributionLineModel[];
}

export interface DistributionListModel extends DataListModel {
  results: DistributionModel[];
}
