import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { KanziRestClientService } from '@kanzi-apes/kanzi-rest-api';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';
import { ServicesListModel } from './services.interfaces';

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  private urlServices = '';

  constructor(
    private apiClientService: KanziRestClientService,
    private envService: EnvironmentService
  ) {
    if (this.envService.environment) {
      this.urlServices = `${this.envService.environment.kongApi}${this.envService.environment.apiModules.invoicing}services/`;
    }
  }

  getServices(): Observable<ServicesListModel> {
    return this.apiClientService.get(
      this.urlServices,
      null,
      'Error al cargar el listado de servicios'
    );
  }
}
