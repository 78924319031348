import { Pipe, PipeTransform } from '@angular/core';
import { severityStatusName } from '@kanzi-apes/kanzi-utils';

@Pipe({
  name: 'kanziStatusSeverity',
  standalone: true,
})
export class KanziStatusSeverityPipe implements PipeTransform {
  transform(
    value: any,
    ...args: any[]
  ):
    | 'success'
    | 'secondary'
    | 'info'
    | 'warning'
    | 'danger'
    | 'contrast'
    | undefined {
    return typeof value == 'string'
      ? severityStatusName[value] || value
      : value;
  }
}
