export interface FilterTableModel {
  page?: number;
  page_size?: number;
  external_id?: string;
  search?: string;
  created_min?: string;
  created_max?: string;
  modified_min?: string;
  modified_max?: string;
  sort_field?: string | null;
  sort_order?: number | string | null;
  customer_id?: number;
}

export interface FilterTreeTableModel extends FilterTableModel {
  parent_id?: any;
}
