import { CustomerModel } from '../customer';
import { UserModel } from '../user';
export interface DataModel {
  id?: number;
  created_by?: UserModel;
  modified_by?: UserModel;
  created?: Date;
  modified?: Date;
  created_timestamp?: number;
  modified_timestamp?: number;
}

export interface CreatedModel {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
}

export interface AttributesModel {
  field: string;
  subfield: string | null;
  label: string;
}
