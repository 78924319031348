import { SkuModel } from '../products';
import { UserModel } from '../user';

export interface MoveLineModel {
  display_name: string;
  sku: string;
  ean: string;
  sourceInventory: number;
  destinyInventory: number;
  quantity: number;
  transfer_order: number;
}

export interface MoveSkuLineModel {
  sku: SkuModel;
  total_expected_items: number;
  total_received_items: number;
  move: any;
  id: number;
  amount: number;
  created: string;
  modified: string;
  status: string;
  created_by: UserModel;
  modified_by: UserModel;
  modified_timestamp?:number;
}
