import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'kanzi-sku-line-from-text',
  templateUrl: './kanzi-sku-line-from-text.component.html',
  styleUrls: ['./kanzi-sku-line-from-text.component.scss'],
})
export class KanziSkuLineFromTextComponent implements OnInit {
  @Input()
  get display(): boolean {
    return this._display;
  }
  set display(val: boolean) {
    this._display = val;
    this.displayChange.emit(val);
  }
  @Output() displayChange = new EventEmitter<boolean>();
  @Output() skuListData = new EventEmitter<{ sku: string; amount: number }[]>();

  _display: boolean = false;
  skuLineTextForm = this.fb.group({
    skuListText: ['', Validators.required],
  });
  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {}
  saveLinesText() {
    if (this.skuLineTextForm.valid) {
      let skuList: { sku: string; amount: number }[];
      const skuRowList: string[] | null = this.skuLineTextForm
        .get('skuListText')
        ?.value.split('\n');
      if (skuRowList && skuRowList.length > 0) {
        skuList = skuRowList.map((row) => {
          const rowElement = row.split(',');
          const sku = rowElement[0].replace(/\s/g, '');
          const skuAmount = +rowElement[1].replace(/\s/g, '');
          return {
            sku: sku,
            amount: skuAmount,
          };
        });
        this.skuLineTextForm.reset();
        this.skuListData.emit(skuList);
      }
    }
  }
}
