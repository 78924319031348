export class ViewFunctions {
  constructor() {}

  changeFavicon(type: string) {
    const path_active = 'favicon.ico';
    const path_error = 'favicon_error.ico';
    let favicon = document.querySelector('link[rel="icon"]');

    if (!favicon) {
      favicon = document.createElement('link');
      favicon.setAttribute('rel', 'icon');
      const head = document.querySelector('head');
      head?.appendChild(favicon);
    }

    if (type === 'active') {
      favicon.setAttribute('href', path_active);
    } else if (type === 'error') {
      favicon.setAttribute('href', path_error);
    }
  }
}
