import { Directive, ElementRef, OnInit, Input, OnChanges } from '@angular/core';
import { LoginService } from '@kanzi-apes/kanzi-auth';

/**
 * @author Hugo Andrés Escobar Ciceri
 * @version 2.1.0
 *
 * Directive to show or hide a menu main option.
 */
@Directive({
  selector: '[appMenuHideAuth]',
})
export class MenuHideAuthDirective implements OnInit, OnChanges {
  @Input('appMenuHideAuth') permission: string;

  /**
   *
   * @param el {ElementRef}
   * @param loginService {LoginService} Service to get the user permissions.
   */
  constructor(private el: ElementRef, private loginService: LoginService) {
    this.permission = '';
  }

  ngOnInit() {}

  ngOnChanges() {
    if (!this.loginService.hasPermission(this.permission)) {
      this.el.nativeElement.style.display = 'none';
    }
  }
}
