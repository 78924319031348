import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { KanziStatus } from '@kanzi-apes/kanzi-models';
/**
 * @author Hugo Andrés Escobar Ciceri
 * @version 2.1.0
 *
 * Service to alert voice.
 */
@Injectable({
  providedIn: 'root',
})
export class AlertVoiceService {
  private subject = new Subject();
  private keepAfterNavigationChange = false;

  /**
   *
   * @param router {Router}
   */
  constructor(private router: Router) {
    // clear alert message on route change
    router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next(null);
        }
      }
    });
  }

  /**
   *
   * @param message {string} Message to show on the alert
   * @param keepAfterNavigationChange {boolean} only keep for a single location change.
   *
   * Function to show alert when is a success message
   */
  message(
    message: { msg: string; title: string; type: string },
    keepAfterNavigationChange = false
  ) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({
      type: message.type,
      text: message.msg,
      title: message.title,
    });
  }

  /**
   *
   * @param message {string} Message to show on the alert
   * @param keepAfterNavigationChange {boolean} only keep for a single location change.
   *
   * Function to show alert when is a success message
   */
  success(
    message: { msg: string; title: string },
    keepAfterNavigationChange = false
  ) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({
      type: KanziStatus.SUCCESS,
      text: message.msg,
      title: message.title,
    });
  }

  /**
   *
   * @param message {string} Message to show on the alert
   * @param keepAfterNavigationChange {boolean} only keep for a single location change.
   *
   * Function to show alert when is a error message
   */
  error(
    message: { msg: string; title: string },
    keepAfterNavigationChange = false
  ) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({
      type: KanziStatus.ERROR,
      text: message.msg,
      title: message.title,
    });
  }

  /**
   * @returns Observable <any>
   *
   * Function to read the message from Subject and return an Observable.
   */
  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}
